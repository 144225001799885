<footer class="w-full bg-FAFBFC xs:bg-fff dark:bg-070707" *ngIf="showFooter">
  <div
    class="container d-flex flex-wrap justify-content-between align-items-baseline mb-lt-20px mb-70px px-3 px-lt-24px"
  >
    <nz-divider
      class="border-0000001a dark:border-ffffff1a w-full mt-md-40px mt-30px mb-md-20px mb-20px"
    ></nz-divider>

    <div class="d-flex w-full justify-content-center">
      <ng-container>
        <div
          class="p-0 d-flex flex-column text-left text-base text-000000cc dark:text-ffffffcc"
        >
          <div *ngIf="false" class="d-flex justify-content-center my-2">
            <span class="pt-1 px-2">We are listed in top platforms</span>
          </div>
          <div
            class="p-0 d-flex flex-column text-left text-base font-bold mb-5"
          >
            <div class="d-flex justify-content-center">
              <!--              <div class="d-flex justify-content-center">-->
              <!--                <img-->
              <!--                  src="assets/media/icons/crypto.svg"-->
              <!--                  class="h-unset h-5 w-5"-->
              <!--                />-->
              <!--                <span class="pl-1 pr-3">Crypto</span>-->
              <!--              </div>-->
              <div class="d-flex justify-content-center">
                <img
                  src="assets/media/icons/Defillama.svg"
                  class="h-unset h-5 w-5"
                />
                <span class="pl-1 pr-3">DefiLlama</span>
              </div>
              <div class="d-flex justify-content-center">
                <img
                  src="assets/media/icons/CoinGecko.svg"
                  class="h-unset h-5 w-5"
                />
                <span class="pl-1 pr-3">CoinGecko</span>
              </div>
              <div class="d-flex justify-content-center">
                <img
                  src="assets/media/icons/CoinMarketCap.svg"
                  class="h-unset h-5 w-5"
                />
                <span class="pl-1 pr-3">CoinMarketCap</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      class="pr-3 d-none d-lg-flex flex-column text-center"
      id="crowdSwapLogo"
    >
      <img
        [src]="
          'assets/media/logo/' +
          (isDarkMode ? 'CrowdSwapLogo-W.svg' : 'CrowdSwapLogo-C.svg')
        "
        class="w-full"
        alt="logo"
      />

      <div class="text-000000cc dark:text-ffffffcc mt-10px font-13">
        Copyright 2024 CrowdSwap.
        <br />
        All rights reserved
      </div>

      <div class="mt-12px">
        <a
          href="https://crowdswap.org/wp-content/uploads/2022/08/CrowdSwapWhitepaper.pdf"
          target="_blank"
          class="p-2 font-14 font-openSans-bold text-0066FF dark:text-3385FF bg-0066ff1a dark:bg-0066ff33 border-radius-4px"
        >
          WHITEPAPER
        </a>
      </div>
    </div>

    <div class="p-0 d-flex flex-column text-left">
      <a
        href="https://crowdswap.org/terms-of-service/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Terms of Service
      </a>

      <a
        href="https://crowdswap.org/privacy-policy-2/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Privacy Policy
      </a>

      <a
        href="https://crowdswap.org/contact1/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Contact Us
      </a>
    </div>

    <div class="p-0 ant-col-24-xs d-flex flex-column text-left">
      <a
        href="https://crowdswap.org/faq1/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        FAQ
      </a>

      <a
        href="https://crowdswap.org/blog/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Blog
      </a>

      <a
        href="https://docs.crowdswap.org/"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer d-none d-lg-block"
      >
        Help Center
      </a>

      <a
        href="https://crowdswap.org/wp-content/uploads/2022/08/CrowdSwapWhitepaper.pdf"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer d-lg-none d-block"
      >
        Whitepaper
      </a>
    </div>

    <div class="p-0 ant-col-24-xs d-flex flex-column text-left">
      <a
        href="https://crowdswap.org/crowdswap-for-projects/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        CrowdSwap for Projects
      </a>

      <a
        href="https://crowdswap.org/loyalty-program/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Loyalty Program
      </a>
    </div>

    <div class="p-0 d-none d-lg-flex flex-column text-left">
      <a
        href="https://crowdswap.org/whats-a-dex/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        What is a dex?
      </a>

      <a
        href="https://crowdswap.org/what-is-staking-crypto/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        What is staking crypto?
      </a>

      <a
        href="https://crowdswap.org/what-is-yield-farming-how-does-yield-farming-work/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        What is yield farming?
      </a>

      <a
        href="https://crowdswap.org/staking-vs-yield-farming/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        Yield farming vs staking
      </a>

      <a
        href="https://crowdswap.org/what-is-defi/"
        target="_blank"
        class="text-000 dark:text-fff font-openSans-bold mt-10px font-14 cursor-pointer"
      >
        What is DeFi?
      </a>
    </div>

    <div class="p-0 ant-col-24 d-flex flex-column text-center mt-30px mb-3">
      <div class="text-000 dark:text-fff font-24 d-none d-lg-block">
        Our Community​
      </div>

      <div
        class="text-000000cc dark:text-ffffffcc mt-10px font-14 d-none d-lg-block"
      >
        Become part of the community that enjoys benefits of the most
        sophisticated swapping app in the crypto industry.
      </div>

      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <a
          href="https://twitter.com/CrowdSwap_App"
          target="_blank"
          class="d-flex align-items-center p-1 mr-3 mt-3 cursor-pointer"
        >
          <div class="twitter-icon mr-2">
            <img
              [src]="
                'assets/media/icons/' +
                (isDarkMode ? 'Twitter-D.svg' : 'Twitter-W.svg')
              "
              alt="Twitter"
              width="20"
            />
          </div>

          <span class="text-000 dark:text-fff font-openSans-bold">Twitter</span>
        </a>

        <a
          href="https://www.linkedin.com/company/crowdswap-crypto"
          target="_blank"
          class="d-flex align-items-center p-1 mr-3 mt-3 cursor-pointer"
        >
          <div class="linkedin-icon mr-2">
            <img
              [src]="
                'assets/media/icons/' +
                (isDarkMode ? 'Linkedin-D.svg' : 'Linkedin-W.svg')
              "
              alt="Linkedin"
              width="20"
            />
          </div>

          <span class="text-0e77a8cc dark:text-fff font-openSans-bold">Linkedin</span>
        </a>

        <a
          href="https://t.me/crowdswap_group"
          target="_blank"
          class="d-flex align-items-center p-1 mr-3 mt-3 cursor-pointer"
        >
          <div class="telegram-icon mr-2">
            <img
              [src]="
                'assets/media/icons/' +
                (isDarkMode ? 'Telegram-D.svg' : 'Telegram-W.svg')
              "
              alt="Telegram"
              width="20"
            />
          </div>

          <span class="text-0088CCcc dark:text-fff font-openSans-bold">Telegram</span>
        </a>

        <a
          href="https://discord.com/invite/qEndDPvjNK"
          target="_blank"
          class="d-flex align-items-center p-1 mt-3 cursor-pointer"
        >
          <div class="discord-icon mr-2">
            <img
              [src]="
                'assets/media/icons/' +
                (isDarkMode ? 'Discord-D.svg' : 'Discord-W.svg')
              "
              alt="Discord"
              width="20"
            />
          </div>

          <span class="text-7289DAcc dark:text-fff font-openSans-bold">Discord</span>
        </a>
      </div>

      <div
        class="text-000000d9 dark:text-ffffffcc mt-25px mt-md-40px font-10 font-md-12"
      >
        Designed and developed by Smart Chains. Operated by Smart Defi
      </div>
    </div>
  </div>
</footer>

<div
  class="footer bg-fff dark:bg-070707 z-40 d-lt-none position-fixed w-full left-0 bottom-0 text-center"
>
  <app-navigation class="d-lt-none" [isMobile]="true"></app-navigation>
</div>
