export const TOKENS_DATA = [
  {
    id: '4605f24a-c51b-46cf-bae5-88ddcc4d7c3d',
    address: '0x111111111117dC0aa78b770fA6A738034120C302',
    chainId: 1,
    name: '1inch',
    symbol: '1INCH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1bc5864e-df39-4f84-b1ae-98cd081534e0',
    address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    chainId: 1,
    name: 'Aave',
    symbol: 'AAVE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3e57c16c-6bd4-4fa0-be90-1eca2971655c',
    address: '0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF',
    chainId: 1,
    name: 'Alchemix',
    symbol: 'ALCX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f7181f40-a6f8-441f-8c3c-b28e2ea7565e',
    address: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    chainId: 1,
    name: 'My Neighbor Alice',
    symbol: 'ALICE',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9bef1b25-5cfe-4ab8-8da4-8354b05182ec',
    address: '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
    chainId: 1,
    name: 'Ampleforth',
    symbol: 'AMPL',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'bbb68ec9-57d9-4c3d-bcfe-2374cd8197e1',
    address: '0xa117000000f279D81A1D3cc75430fAA017FA5A2e',
    chainId: 1,
    name: 'Aragon',
    symbol: 'ANT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2df35b4c-3389-4cd1-ba98-6d0867202251',
    address: '0x0b38210ea11411557c13457D4dA7dC6ea731B88a',
    chainId: 1,
    name: 'API3',
    symbol: 'API3',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c5c83b9c-533e-4061-93bf-5dd8cdfa2604',
    address: '0x1F3f9D3068568F8040775be2e8C03C103C61f3aF',
    chainId: 1,
    name: 'Archer DAO Governan',
    symbol: 'ARCH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7e4e0290-1468-42f7-958e-b0b4177393bd',
    address: '0xc12d099be31567add4e4e4d0D45691C3F58f5663',
    chainId: 1,
    name: 'Auctus',
    symbol: 'AUC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8e1f5c88-9ff2-478a-a970-7adcf19fb636',
    address: '0xba100000625a3754423978a60c9317c58a424e3D',
    chainId: 1,
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '40880d29-f725-4bbb-aab5-d0ce1ceabd3d',
    address: '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
    chainId: 1,
    name: 'Band Protocol',
    symbol: 'BAND',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f5a83c3d-2282-4404-92ba-de7adef76618',
    address: '0x24A6A37576377F63f194Caa5F518a60f45b42921',
    chainId: 1,
    name: 'Float Protocol',
    symbol: 'BANK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '33173260-db2e-4dba-ad60-c1dbb14ad92e',
    address: '0x44564d0bd94343f72E3C8a0D22308B7Fa71DB0Bb',
    chainId: 1,
    name: 'BasketDAO',
    symbol: 'BASK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9c7b1796-a1a8-43dd-baee-3d93a75aa294',
    address: '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
    chainId: 1,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '873aab4e-a161-40db-bf9c-9cf84b32d610',
    address: '0x72e203a17adD19A3099137c9d7015fD3e2b7DBa9',
    chainId: 1,
    name: 'BlockchainPoland',
    symbol: 'BCP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd03cd0da-3f38-4631-94e2-3c696b841412',
    address: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
    chainId: 1,
    name: 'Bancor Network Toke',
    symbol: 'BNT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '03e37555-d87c-4f41-b301-ce58aefa2a53',
    address: '0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
    chainId: 1,
    name: 'BoringDAO',
    symbol: 'BOR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5b2a10e2-a3e8-418e-a83a-d0cc43d445a2',
    address: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
    chainId: 1,
    name: 'Compound',
    symbol: 'COMP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8154f8ec-4868-47b0-95a3-602d76e7d6bd',
    address: '0xcAaa93712BDAc37f736C323C93D4D5fDEFCc31CC',
    chainId: 1,
    name: 'CryptalDash',
    symbol: 'CRD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '53db9c94-4ade-43f0-b30e-76d35915bf5c',
    address: '0xeDCC68Cc8b6Ec3eDE0979f8A52835b238A272027',
    chainId: 1,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-07-23T16:49:12.872Z'
  },
  {
    id: '823aca04-8d9c-43dd-969c-08c1047fcb4e',
    address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    chainId: 1,
    name: 'Curve DAO Token',
    symbol: 'CRV',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '06d5d309-a158-425c-9388-319b5c3ce1f5',
    address: '0x41e5560054824eA6B0732E656E3Ad64E20e94E45',
    chainId: 1,
    name: 'Civic',
    symbol: 'CVC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a0249aa1-8de9-4359-b41a-2d5a05da1984',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    chainId: 1,
    name: 'Dai',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '628f2a34-5e6f-434d-bba3-36302e0e3edd',
    address: '0x20c36f062a31865bED8a5B1e512D9a1A20AA333A',
    chainId: 1,
    name: 'DefiDollar DAO',
    symbol: 'DFD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e1a3431d-53d7-409e-a4bb-1ace7ab845ca',
    address: '0x0AbdAce70D3790235af448C88547603b945604ea',
    chainId: 1,
    name: 'district0x',
    symbol: 'DNT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '89aa3723-f36b-45cb-bd77-a7fc72752419',
    address: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
    chainId: 1,
    name: 'DODO bird',
    symbol: 'DODO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'acbcbd14-7c6f-4d8d-889d-448c10c74f98',
    address: '0xbCa3C97837A39099eC3082DF97e28CE91BE14472',
    chainId: 1,
    name: 'DUST Token',
    symbol: 'DUST',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b8f421f9-7802-456a-a818-e5771ffd08cc',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 1,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fce312aa-9c1e-48dd-bafc-c2a73339f734',
    address: '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
    chainId: 1,
    name: 'Fei Protocol',
    symbol: 'FEI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8a103226-8e30-4439-8cf2-c78c86a53ba3',
    address: '0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E',
    chainId: 1,
    name: 'FLOKI',
    symbol: 'FLOKI',
    decimals: 9,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '22bc6d7b-6dfc-4d56-8271-c18ef8c22ee7',
    address: '0xd1d2Eb1B1e90B638588728b4130137D262C87cae',
    chainId: 1,
    name: 'Gala',
    symbol: 'GALA',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: true,
    timestamp: '2024-04-25T08:04:58.044Z'
  },
  {
    id: '43cca047-179d-4fa7-a1c1-cd7544830ec1',
    address: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
    chainId: 1,
    name: 'Gnosis',
    symbol: 'GNO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8b9c5d2e-ad7a-4c3f-81e7-821f1d1d21fc',
    address: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
    chainId: 1,
    name: 'The Graph',
    symbol: 'GRT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '874c9df5-edee-4af1-a315-da6318abfeee',
    address: '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
    chainId: 1,
    name: 'Illuvium',
    symbol: 'ILV',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f611824f-d4d5-4fea-9ce3-a688a56985b2',
    address: '0x04C17b9D3b29A78F7Bd062a57CF44FC633e71f85',
    chainId: 1,
    name: 'IMPT',
    symbol: 'IMPT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '681c72b2-04d5-4adf-bfb6-ab39fe2f7e4a',
    address: '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
    chainId: 1,
    name: 'Injective',
    symbol: 'INJ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-07-20T12:10:51.810Z'
  },
  {
    id: 'b416badb-6e3f-45f5-a276-ff5f4413da50',
    address: '0xD9Ec3ff1f8be459Bb9369b4E79e9Ebcf7141C093',
    chainId: 1,
    name: 'KardiaChain Token',
    symbol: 'KAI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '58543356-9675-4b50-8e5c-b706fddb36df',
    address: '0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202',
    chainId: 1,
    name: 'Kyber Network Cryst',
    symbol: 'KNC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7a86b1f5-99ad-4f17-a088-cbaeae6a7483',
    address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    chainId: 1,
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b02dcb74-d40a-4db6-ab98-d83f9081d0b5',
    address: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
    chainId: 1,
    name: 'Liquidus (LIQ) ',
    symbol: 'LIQ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ba73cc29-3d86-4850-9a10-c66dc546244a',
    address: '0x42476F744292107e34519F9c357927074Ea3F75D',
    chainId: 1,
    name: 'Loom Network NEW ',
    symbol: 'LOOM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ceec0e13-d76a-464d-a413-04c09fc6738f',
    address: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
    chainId: 1,
    name: 'Loopring',
    symbol: 'LRC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd7944375-4e50-4e09-85b8-62dd4cc60c30',
    address: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    chainId: 1,
    name: 'Decentraland',
    symbol: 'MANA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '40a395bb-14d1-458e-b99b-a26c4e9bb445',
    address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    chainId: 1,
    name: 'Polygon',
    symbol: 'MATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '038bb234-3fdf-43cd-a3c3-15085a363463',
    address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
    chainId: 1,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '00203c93-a0b3-40f5-95bd-4a79f0e7e712',
    address: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
    chainId: 1,
    name: 'Enzyme',
    symbol: 'MLN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '718dc498-6952-42e0-bff2-0e77ed890669',
    address: '0x3FA729B4548beCBAd4EaB6EF18413470e6D5324C',
    chainId: 1,
    name: 'Mover',
    symbol: 'MOVE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a433c7e7-91c1-46d5-9452-c55d69ed6f1d',
    address: '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
    chainId: 1,
    name: 'Numeraire',
    symbol: 'NMR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0572f01b-c557-47f5-a9a8-d9fa7399b40b',
    address: '0x4fE83213D56308330EC302a8BD641f1d0113A4Cc',
    chainId: 1,
    name: 'NuCypher',
    symbol: 'NU',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fae6527f-9295-4f88-82f6-8b08c99d23ae',
    address: '0x383518188C0C6d7730D91b2c03a03C837814a899',
    chainId: 1,
    name: 'Olympus',
    symbol: 'OHM',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd2c56663-9cc5-4bce-9ad6-0f01dc19bd98',
    address: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
    chainId: 1,
    name: 'OMG Network',
    symbol: 'OMG',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8146f476-582b-4605-b7e7-6438d7853485',
    address: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
    chainId: 1,
    name: 'Orchid Protocol',
    symbol: 'OXT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '75771dc7-2d03-4a5d-b728-0d32055ee610',
    address: '0x808507121B80c02388fAd14726482e061B8da827',
    chainId: 1,
    name: 'Pendle',
    symbol: 'PENDLE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: true,
    timestamp: '2024-04-17T09:03:03.326Z'
  },
  {
    id: 'a3298de7-f512-47da-8af4-a107ecff4415',
    address: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
    chainId: 1,
    name: 'Pepe',
    symbol: 'PEPE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '102325b7-6fd5-4903-953a-30f3359ded1c',
    address: '0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa',
    chainId: 1,
    name: 'Polkastarter',
    symbol: 'POLS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ed898748-4b2e-4177-a1e8-c6ccef3c1bd7',
    address: '0x6399C842dD2bE3dE30BF99Bc7D1bBF6Fa3650E70',
    chainId: 1,
    name: 'Premia',
    symbol: 'PREMIA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '03043a05-33f7-4923-978b-84c33f78e6dd',
    address: '0x362bc847A3a9637d3af6624EeC853618a43ed7D2',
    chainId: 1,
    name: 'PARSIQ',
    symbol: 'PRQ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '61150b27-116a-494b-9f5b-7c53590eea52',
    address: '0x7a5d3A9Dcd33cb8D527f7b5F96EB4Fef43d55636',
    chainId: 1,
    name: 'RadioShack Token',
    symbol: 'RADIO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '87101466-5e99-4deb-9269-76bfa7bcba5d',
    address: '0x408e41876cCCDC0F92210600ef50372656052a38',
    chainId: 1,
    name: 'REN',
    symbol: 'REN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1dfd2533-9f7c-49d6-9bf5-86503bb4b707',
    address: '0x221657776846890989a759BA2973e427DfF5C9bB',
    chainId: 1,
    name: 'Augur',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a5d535d7-8d59-4434-b69f-4fc01406af0d',
    address: '0xD291E7a03283640FDc51b121aC401383A46cC623',
    chainId: 1,
    name: 'Rari Governance Tok',
    symbol: 'RGT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '14ce2f37-4675-4584-ac73-3d582ed44329',
    address: '0x2aECCB42482cc64E087b6D2e5Da39f5A7A7001f8',
    chainId: 1,
    name: 'Ruler Protocol',
    symbol: 'RULER',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '904be01e-be1e-473d-bea3-3e9e18c8a395',
    address: '0x3155BA85D5F96b2d030a4966AF206230e46849cb',
    chainId: 1,
    name: 'THORChain ERC20 ',
    symbol: 'RUNE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '53e72043-b9d9-4a0c-8646-50419f2ce3fb',
    address: '0xb753428af26E81097e7fD17f40c88aaA3E04902c',
    chainId: 1,
    name: 'saffron finance',
    symbol: 'SFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1d7ee947-bd78-425d-83fa-eff24b6c1d96',
    address: '0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9',
    chainId: 1,
    name: 'Shack Token',
    symbol: 'SHACK',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'faae5049-bbbb-487a-8f55-195f57bd0119',
    address: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    chainId: 1,
    name: 'Synthetix Network T',
    symbol: 'SNX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '02fb7448-2a9f-45b3-a86f-342ba0fa8f45',
    address: '0x476c5E26a75bd202a9683ffD34359C0CC15be0fF',
    chainId: 1,
    name: 'Serum',
    symbol: 'SRM',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '794dfa6d-a749-4f2b-9988-a35d8509e4b1',
    address: '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
    chainId: 1,
    name: 'Storj',
    symbol: 'STORJ',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '75a58e47-f630-4c94-ac74-de114712d050',
    address: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
    chainId: 1,
    name: 'sUSD',
    symbol: 'SUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '436d7d60-5175-468c-8c83-18686f4892b8',
    address: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
    chainId: 1,
    name: 'Sushi',
    symbol: 'SUSHI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fa99ec74-ec97-4951-af86-7fc491e6f7af',
    address: '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa',
    chainId: 1,
    name: 'tBTC',
    symbol: 'TBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2e184a96-f92d-4912-87f7-e74f2a7688d8',
    address: '0x9d3D07439069C9bbC8d626397cf98CB343aC0a72',
    chainId: 1,
    name: 'Tmn Global',
    symbol: 'TMNG',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '72a0d15f-153b-4f11-843c-d763895c26a7',
    address: '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
    chainId: 1,
    name: 'TrueFi',
    symbol: 'TRU',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a63506c6-a3d1-4add-bf3f-3dc1220b928f',
    address: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    chainId: 1,
    name: 'UMA',
    symbol: 'UMA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'abe22a94-e32c-4bc9-8679-c0a58c3bf8e7',
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    chainId: 1,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '58ec3959-eee7-4c1b-ae61-c77a790a38ff',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    chainId: 1,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '34845615-d9a7-42ee-b6be-0b66d423ab07',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    chainId: 1,
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f665e0c6-58f5-49bb-bc01-abde7836493d',
    address: '0xa47c8bf37f92aBed4A126BDA807A7b7498661acD',
    chainId: 1,
    name: 'TerraUSD',
    symbol: 'UST',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b382fe86-2b2b-44dc-bc6b-ad9834c641a9',
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    chainId: 1,
    name: 'Wrapped Bitcoin',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3f3e1db1-afb8-4307-9bb1-be03f30ecd1e',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    chainId: 1,
    name: 'WETH',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '436b5f4c-c6cb-46fb-8796-c48e5518559e',
    address: '0x5026F006B85729a8b14553FAE6af249aD16c9aaB',
    chainId: 1,
    name: 'Wojak Coin',
    symbol: 'WOJAK',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ea841442-df62-454d-8b78-2d3ac1a4b082',
    address: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
    chainId: 1,
    name: 'yearn finance',
    symbol: 'YFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4fd450e8-a425-487b-b7c4-e676f8f9502e',
    address: '0xDcB01cc464238396E213a6fDd933E36796eAfF9f',
    chainId: 1,
    name: 'Yield',
    symbol: 'YLD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '38cfed6f-96fd-4481-9b83-2b906f4c1b08',
    address: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
    chainId: 1,
    name: '0x',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7a37dbc3-746c-402f-8361-77ca57381e2d',
    address: '0x443Fd8D5766169416aE42B8E050fE9422f628419',
    chainId: 3,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '57bbe5af-3b86-42c7-930a-7a4219472e19',
    address: '0xF35cCfbcE1228014F66809EDaFCDB836BFE388f5',
    chainId: 3,
    name: 'Bancor Network Toke',
    symbol: 'BNT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9816bf67-1608-44ef-a7b4-853c25e79f78',
    address: '0x1Fe16De955718CFAb7A44605458AB023838C2793',
    chainId: 3,
    name: 'Compound',
    symbol: 'COMP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '69f3eba1-3cb0-47c0-8b21-43b159c24b55',
    address: '0xA11E698601227fC8746742D4F884C6566FaA0336',
    chainId: 3,
    name: 'Crowd Token',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '168574ea-9e7b-47d0-8466-23db9e475144',
    address: '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
    chainId: 3,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd7480f44-122e-4778-a921-ddc79303aaa2',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 3,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b9ea3017-d6c9-4cff-8ea7-caf02b0bd173',
    address: '0x6FD34013CDD2905d8d27b0aDaD5b97B2345cF2B8',
    chainId: 3,
    name: 'Reputation',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '61ed9fcb-9b38-4aff-8a68-faba1277654e',
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    chainId: 3,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '12c1003e-ce88-4334-86c1-8892c8432835',
    address: '0x0D9C8723B343A8368BebE0B5E89273fF8D712e3C',
    chainId: 3,
    name: 'USDCoin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6ef49b02-495e-417e-bbb7-d64a1136acdc',
    address: '0x110a13FC3efE6A245B50102D2d79B3E76125Ae83',
    chainId: 3,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '053936dd-ac75-4e6d-810f-486edf0b9ea4',
    address: '0xBde8bB00A7eF67007A96945B3a3621177B615C44',
    chainId: 3,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1e12ea5e-dd3a-4c77-8d83-acd38d584ddd',
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    chainId: 3,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a2b66c1f-1b70-45eb-b9b4-9b92fb6117f1',
    address: '0xE4C6182EA459E63B8F1be7c428381994CcC2D49c',
    chainId: 3,
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e8462ebd-bc0e-4c17-b2c0-b847fd7493f9',
    address: '0xbF7A7169562078c96f0eC1A8aFD6aE50f12e5A99',
    chainId: 4,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '73940412-ab20-4d59-86c9-e1c014848bb7',
    address: '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
    chainId: 4,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '545e1574-d059-4134-b621-f3f66a682ecf',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 4,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6f1d3315-015e-4485-b214-1b8ac83d8fca',
    address: '0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85',
    chainId: 4,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e9bf05e9-6ed9-47b3-806e-c76734a614fb',
    address: '0x6e894660985207feb7cf89Faf048998c71E8EE89',
    chainId: 4,
    name: 'Reputation',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '24a50bcc-c8ec-4220-b7c5-6c977cf5b71c',
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    chainId: 4,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a27b5fc3-248e-4542-84ca-255cea17a36e',
    address: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
    chainId: 4,
    name: 'USDCoin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cd94aa04-d3a9-4116-b539-7d2e61a6f814',
    address: '0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02',
    chainId: 4,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd9a413b6-edf3-487d-9ca9-c9741ad335f7',
    address: '0x577D296678535e4903D59A4C929B718e1D575e0A',
    chainId: 4,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '27dcd742-fc07-434b-a8ca-7300fb547dc7',
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    chainId: 4,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '82684a11-3df3-479e-8c6f-73fcf054b159',
    address: '0xddea378A6dDC8AfeC82C36E9b0078826bf9e68B6',
    chainId: 4,
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5727c988-2055-489c-bbb1-f17793db9cb4',
    address: '0x70cBa46d2e933030E2f274AE58c951C800548AeF',
    chainId: 5,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '64536ce8-1c4f-438f-a19d-7ff815ad4678',
    address: '0xe16C7165C8FeA64069802aE4c4c9C320783f2b6e',
    chainId: 5,
    name: 'Compound',
    symbol: 'COMP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'aa9d83c2-8830-4160-a592-c8a09b8ceb2a',
    address: '0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60',
    chainId: 5,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '071f2ffe-2811-4b0e-9f09-f0092059624c',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 5,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cc44cd35-8061-4280-a44b-f3821dffcd08',
    address: '0x183Faf58c4461972765f3F90c6272A4ecE66Bd96',
    chainId: 5,
    name: 'Reputation',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '377c30a1-1f51-47c0-b33e-bc51797d5d01',
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    chainId: 5,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cb372dd1-4737-40ab-b471-f52c153ee047',
    address: '0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C',
    chainId: 5,
    name: 'USDCoin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '40b94277-f5a7-4464-9872-d46610c64d74',
    address: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
    chainId: 5,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd42c60de-c926-45c5-b85c-86ecf6455f01',
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    chainId: 5,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cbc9ef51-3abb-4179-a00a-d21a99a471c6',
    address: '0xe4E81Fa6B16327D4B78CFEB83AAdE04bA7075165',
    chainId: 5,
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6aae2573-351e-4531-a713-065f12ed67fb',
    address: '0xadDb6A0412DE1BA0F936DCaeb8Aaa24578dcF3B2',
    chainId: 10,
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.758Z'
  },
  {
    id: 'b8a94ecf-056b-41e1-b13f-5645fd9f991f',
    address: '0x9b88D293b7a791E40d36A39765FFd5A1B9b5c349',
    chainId: 10,
    name: 'Celo native asset',
    symbol: 'CELO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.759Z'
  },
  {
    id: '913ad042-a688-4d08-9a79-ac9385a586fd',
    address: '0xBeDf619c69f5C1655E58463B85A4EE67629dE409',
    chainId: 10,
    name: 'Crowd Token',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2024-01-31T13:28:34.957Z'
  },
  {
    id: '1ae06b72-c3e4-4052-a995-340b4ed0c85c',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    chainId: 10,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.656Z'
  },
  {
    id: 'b8b5d610-ae96-4836-9a4f-74bc6fe4ff41',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 10,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-24T13:20:37.133Z'
  },
  {
    id: '197c61ed-aee5-4fa4-b21d-1dd6149b0ad1',
    address: '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
    chainId: 10,
    name: 'Frax',
    symbol: 'FRAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.755Z'
  },
  {
    id: 'dad1d489-29fb-425f-8f6c-0ef553e94c30',
    address: '0x6806411765Af15Bddd26f8f544A34cC40cb9838B',
    chainId: 10,
    name: 'Frax Ether',
    symbol: 'frxETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.756Z'
  },
  {
    id: '4f4913d1-b8c3-4426-abdd-7e81dce6cc67',
    address: '0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be',
    chainId: 10,
    name: 'Frax Share',
    symbol: 'FXS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.757Z'
  },
  {
    id: '2ac65218-e914-43bc-8f4b-5f25be7665e6',
    address: '0xFdb794692724153d1488CcdBE0C56c252596735F',
    chainId: 10,
    name: 'Lido DAO Token',
    symbol: 'LDO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.662Z'
  },
  {
    id: 'd9f89481-4caf-4c5e-892e-fe312c249308',
    address: '0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
    chainId: 10,
    name: 'ChainLink Token',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.661Z'
  },
  {
    id: 'a7b84c9e-7ab3-403e-afda-ec9bed040af1',
    address: '0x4200000000000000000000000000000000000042',
    chainId: 10,
    name: 'Optimism',
    symbol: 'OP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.663Z'
  },
  {
    id: 'e5be22f7-93ae-499a-ae54-c6d16cfad7c0',
    address: '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
    chainId: 10,
    name: 'Synth sETH',
    symbol: 'sETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.664Z'
  },
  {
    id: 'b1396274-fcea-4ddc-b983-59af79e52080',
    address: '0xc5Db22719A06418028A40A9B5E9A7c02959D0d08',
    chainId: 10,
    name: 'Synth sLINK',
    symbol: 'sLINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.666Z'
  },
  {
    id: '7b17f142-33d1-421a-b45c-b6c85393d822',
    address: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
    chainId: 10,
    name: 'Synthetix Network Token',
    symbol: 'SNX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.754Z'
  },
  {
    id: 'e66cd0b1-9fd2-4c9d-a5be-7b618737a3d9',
    address: '0x73cb180bf0521828d8849bc8CF2B920918e23032',
    chainId: 10,
    name: 'USD+',
    symbol: 'USD+',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.665Z'
  },
  {
    id: '1e9452c4-e3c3-40fa-9b21-d5ba600a9181',
    address: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
    chainId: 10,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-24T13:20:39.061Z'
  },
  {
    id: '4da4c880-a7d6-4ca7-9138-a2f858c608b3',
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    chainId: 10,
    name: 'USD Coin',
    symbol: 'USDC.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.662Z'
  },
  {
    id: 'f7da81f5-067f-4690-b74f-e6de65c4d34a',
    address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    chainId: 10,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-24T13:20:37.134Z'
  },
  {
    id: 'addf5da8-1290-4d4e-87ea-c168a5282a21',
    address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    chainId: 10,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.658Z'
  },
  {
    id: '34882309-3531-4c51-8316-f501dc7f1646',
    address: '0x4200000000000000000000000000000000000006',
    chainId: 10,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-24T13:20:37.050Z'
  },
  {
    id: '042475d5-53c0-43a3-b2c2-50fbbd380524',
    address: '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
    chainId: 10,
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-27T12:20:25.667Z'
  },
  {
    id: 'c7193c3e-6b00-43f6-a941-fda3a9f7b4ef',
    address: '0x440CD83C160De5C96Ddb20246815eA44C7aBBCa8',
    chainId: 30,
    name: 'BitPRO',
    symbol: 'BITP',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '3d947a67-e63f-4982-8764-d8f1f72dc54a',
    address: '0xe700691dA7b9851F2F35f8b8182c69c53CcaD9Db',
    chainId: 30,
    name: 'Dollar on Chain',
    symbol: 'DOC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'a4978320-06e4-40c7-8c4b-3474f8aed850',
    address: '0x1D931Bf8656d795E50eF6D639562C5bD8Ac2B78f',
    chainId: 30,
    name: 'ETHs',
    symbol: 'ETHs',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '892fe02b-b827-42f7-aaba-3f7e72d2051d',
    address: '0x9AC7fE28967B30E3A4e6e03286d715b42B453D10',
    chainId: 30,
    name: 'MOC',
    symbol: 'MOC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'b9512d96-134a-453f-be6f-678dde958783',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 30,
    name: 'Smart Bitcoin',
    symbol: 'RBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '80a95584-0f3f-45fc-8199-6d8be1512720',
    address: '0x6B1A73d547F4009a26B8485B63d7015d248Ad406',
    chainId: 30,
    name: 'rDAI',
    symbol: 'rDAI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'ddbac183-e500-4c39-9d91-45cd3e455cec',
    address: '0x2AcC95758f8b5F583470ba265EB685a8F45fC9D5',
    chainId: 30,
    name: 'RIF',
    symbol: 'RIF',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'fc2d5044-ba0d-429b-80c3-685f3f153352',
    address: '0xf4d27c56595Ed59B66cC7F03CFF5193e4bd74a61',
    chainId: 30,
    name: 'RIFPro',
    symbol: 'RIFP',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'b1d07a1d-1a67-4e54-b293-a07fd05a46aa',
    address: '0x1BDA44fda023f2aF8280A16FD1b01d1a493BA6C4',
    chainId: 30,
    name: 'rUSDC',
    symbol: 'rUSDC',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '4d63b609-00e3-4747-99b7-e023458227cf',
    address: '0xef213441A85dF4d7ACbDaE0Cf78004e1E486bB96',
    chainId: 30,
    name: 'rUSDT',
    symbol: 'rUSDT',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'c3605582-b8e9-458b-9a91-87fdf02a102e',
    address: '0xEFc78fc7d48b64958315949279Ba181c2114ABBd',
    chainId: 30,
    name: 'Sovryn Token',
    symbol: 'SOV',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'ba8aade7-1545-4cd2-92be-412429bb3cce',
    address: '0x3A15461d8aE0F0Fb5Fa2629e9DA7D66A794a6e37',
    chainId: 30,
    name: 'RIF US Dollar',
    symbol: 'USDRIF',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '1e44b440-da27-45ab-9b1e-5a0a1adf4162',
    address: '0x542fDA317318eBF1d3DEAf76E0b632741A7e677d',
    chainId: 30,
    name: 'Wrapped WBTC',
    symbol: 'WRBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: 'b9ee47b1-3e73-4aa2-aa1f-163efea1a3f8',
    address: '0xb5999795BE0EbB5bAb23144AA5FD6A02D080299F',
    chainId: 30,
    name: 'XUSD',
    symbol: 'XUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-07-30T12:08:53.035Z'
  },
  {
    id: '0a971790-1c40-4cb8-9b4d-29947b3e93e1',
    address: '0x482dC9bB08111CB875109B075A40881E48aE02Cd',
    chainId: 42,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'de2bd9c5-0083-42b9-b8a9-804a85935f07',
    address: '0x61460874a7196d6a22D1eE4922473664b3E95270',
    chainId: 42,
    name: 'Compound',
    symbol: 'COMP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5904643e-cfa1-41dd-b0e2-1ed373d58348',
    address: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
    chainId: 42,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e5fc2f8e-730b-4a29-a747-3c46b4a3fc34',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 42,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ec79c8bb-99b9-4406-9c56-17a279b542c2',
    address: '0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD',
    chainId: 42,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ece0787a-b3d8-4361-8490-95be693d1af8',
    address: '0x50DD65531676F718B018De3dc48F92B53D756996',
    chainId: 42,
    name: 'Reputation',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3f8434f4-ddd2-4b70-83ba-0e242b6bdcf7',
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    chainId: 42,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6cf965e5-5c3c-4110-862b-2f902e4811a2',
    address: '0xb7a4F3E9097C08dA09517b5aB877F7a917224ede',
    chainId: 42,
    name: 'USDCoin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '829fe173-59bd-432f-a04a-d73d44836382',
    address: '0x07de306FF27a2B630B1141956844eB1552B956B5',
    chainId: 42,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '15907ee9-3932-4052-ad5c-18c98b8f1875',
    address: '0xd3A691C852CDB01E281545A27064741F0B7f6825',
    chainId: 42,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'af843d50-68e1-4a99-8aeb-9ad7dfb027d5',
    address: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    chainId: 42,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c5db909b-2886-49e3-af5d-3b203ff48229',
    address: '0x162c44e53097e7B5aaE939b297ffFD6Bf90D1EE3',
    chainId: 42,
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3af9439a-26ce-4179-b8bc-2501475074eb',
    address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    chainId: 56,
    name: 'Cardano Token',
    symbol: 'ADA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cf567a18-6ef1-493c-9446-76c867eb6f9d',
    address: '0x7e2a35c746f2f7c240b664f1da4dd100141ae71f',
    chainId: 56,
    name: 'aiRight',
    symbol: 'AIRI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-17T16:05:48.392Z'
  },
  {
    id: '6982315c-511f-4078-b78b-9c4f67d4aca7',
    address: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    chainId: 56,
    name: 'AlphaToken',
    symbol: 'ALPHA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '76640cf9-c97e-4182-8608-ebf0b7e8fa0a',
    address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
    chainId: 56,
    name: 'Ankr',
    symbol: 'ANKR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a9046b61-d688-4921-830c-8ac26aefe6f9',
    address: '0x80D5f92C2c8C682070C95495313dDB680B267320',
    chainId: 56,
    name: 'AS Roma',
    symbol: 'ASR',
    decimals: 2,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e54cc4e4-a75b-41d4-89dc-272dedb440ac',
    address: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
    chainId: 56,
    name: 'Atletico de Madrid',
    symbol: 'ATM',
    decimals: 2,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '04adaddf-ef3d-42d8-9c1f-27acec897469',
    address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    chainId: 56,
    name: 'Cosmos Token',
    symbol: 'ATOM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c10d6bfa-b27a-4848-b75a-5532924e0422',
    address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    chainId: 56,
    name: 'Binance-Peg Avalanche Token',
    symbol: 'AVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-02-29T09:26:07.702Z'
  },
  {
    id: '504a023c-db58-4c24-aa04-d067f8695d18',
    address: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    chainId: 56,
    name: 'Binance-Peg Axie Infinity Shard Token',
    symbol: 'AXS',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '29a69de6-0c80-4747-b592-f86a5bd69ddc',
    address: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
    chainId: 56,
    name: 'BabySwap Token',
    symbol: 'BABY',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a654179f-a269-493a-85e6-cee2e98c289c',
    address: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    chainId: 56,
    name: 'Bakery Token',
    symbol: 'BAKE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f982ec0b-752a-4a44-99d3-048a03d51b7a',
    address: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
    chainId: 56,
    name: 'AllianceBlock',
    symbol: 'bALBT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9221f73d-ab04-4551-887f-5c23466f343e',
    address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    chainId: 56,
    name: 'ApeSwapFinance BANANA',
    symbol: 'BANANA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '15aff98d-db32-4e93-aa6c-fe34deb9b970',
    address: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
    chainId: 56,
    name: 'BAND Protocol Token',
    symbol: 'BAND',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a4db89a8-6e2b-464f-8d54-7eb3db594c90',
    address: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    chainId: 56,
    name: 'Bitcoin Cash Token',
    symbol: 'BCH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '534ddc1b-7f12-4100-968c-b8f327ee77c9',
    address: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    chainId: 56,
    name: 'Beefy.finance',
    symbol: 'BIFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '44d4ca9f-6096-4570-bbb6-9799949bd436',
    address: '0xc864019047B864B6ab609a968ae2725DFaee808A',
    chainId: 56,
    name: 'BIT TOKEN',
    symbol: 'BIT',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-06-02T11:24:55.783Z'
  },
  {
    id: '0a188591-f26e-4999-8a66-fe7ee58e9ea9',
    address: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
    chainId: 56,
    name: 'BLINk',
    symbol: 'BLK',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '59d32630-1d32-4422-917f-80b95c0d4577',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 56,
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0e5c1781-6cec-4735-8a9e-8f287507996f',
    address: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
    chainId: 56,
    name: 'ROOBEE',
    symbol: 'bROOBEE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd96041eb-8d9c-4a1f-ac09-c73b19e00397',
    address: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
    chainId: 56,
    name: 'BSCEX',
    symbol: 'BSCX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '09778407-0601-4f5e-a554-557835233828',
    address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
    chainId: 56,
    name: 'Biswap',
    symbol: 'BSW',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd31ee69b-2034-49ff-b288-27971247bf8c',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    chainId: 56,
    name: 'BTCB Token',
    symbol: 'BTCB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4ec16edc-e7b7-4a34-9105-931ca02e1242',
    address: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
    chainId: 56,
    name: 'Standard BTC Hashrate Token',
    symbol: 'BTCST',
    decimals: 17,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f5cbf73c-05fc-43c4-9b54-3cb2d4f03012',
    address: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    chainId: 56,
    name: 'Burger Swap',
    symbol: 'BURGER',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1e14668d-3240-4a53-a907-488cf6295a60',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    name: 'BUSD Token',
    symbol: 'BUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0b24b791-de51-461a-93b5-7e81f795f91d',
    address: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
    chainId: 56,
    name: 'Coin98',
    symbol: 'C98',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4737d444-2ff8-4c1b-bdae-33e50f06a724',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    chainId: 56,
    name: 'PancakeSwap Token',
    symbol: 'CAKE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ff08bedd-b275-4c70-a4a8-d5f755605ca3',
    address: '0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56',
    chainId: 56,
    name: 'CanYaCoin',
    symbol: 'CAN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd63cfec0-0312-4a32-9017-87ac157e7a3a',
    address: '0x9840652DC04fb9db2C43853633f0F62BE6f00f98',
    chainId: 56,
    name: 'ChainGPT',
    symbol: 'CGPT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '63a3834b-c623-4081-b90b-ec215b82afea',
    address: '0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888',
    chainId: 56,
    name: 'Cream',
    symbol: 'CREAM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f039f280-513d-4136-b8c7-7f690aa68681',
    address: '0xA5d4B64a639d93b660cdA04D331374dA1108F8f5',
    chainId: 56,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'da508b8c-8c8c-4a3e-a90c-fd569545669a',
    address: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
    chainId: 56,
    name: 'CertiK Token',
    symbol: 'CTK',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd62c691b-529e-4d4b-9074-a7074d2607c3',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    chainId: 56,
    name: 'Dai Token',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0c7c5a0d-2b63-4659-bb73-4c1b90ae4bae',
    address: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
    chainId: 56,
    name: 'deusdc Token',
    symbol: 'DEUSDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c634fdbb-a367-48c3-91cf-757321e9494d',
    address: '0x748AD98b14C814B28812eB42ad219C8672909879',
    chainId: 56,
    name: 'Dice.finance Token',
    symbol: 'DICE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '775efa49-6139-4f0a-8baa-384f39baa994',
    address: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
    chainId: 56,
    name: 'Ditto',
    symbol: 'DITTO',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b6f1eda2-7337-4ca8-b84a-3aba0c9521bb',
    address: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
    chainId: 56,
    name: 'DODO bird',
    symbol: 'DODO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cc4c7eae-7f58-4d78-a9a8-faec61da8637',
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    chainId: 56,
    name: 'Dogecoin',
    symbol: 'DOGE',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: true,
    timestamp: '2024-04-17T09:03:10.662Z'
  },
  {
    id: '64ba2f1f-4a4d-488c-a057-be37c64accb6',
    address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    chainId: 56,
    name: 'Polkadot Token',
    symbol: 'DOT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '35cd510f-4db6-48fb-995b-20504008a890',
    address: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    chainId: 56,
    name: 'EOS Token',
    symbol: 'EOS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '320155ec-07ea-48e9-afd0-2e0d8faf67fb',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    chainId: 56,
    name: 'Ethereum Token',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '586cff5a-454b-4a86-887a-1385ec99a33e',
    address: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
    chainId: 56,
    name: 'FETCH',
    symbol: 'FET',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-02-29T09:26:07.704Z'
  },
  {
    id: 'e62d69fc-bc22-48cc-91c4-1771ebcac8d8',
    address: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    chainId: 56,
    name: 'Filecoin',
    symbol: 'FIL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e97106ec-361d-40b1-8483-dfd9c50c8656',
    address: '0x393B312C01048b3ed2720bF1B090084C09e408A1',
    chainId: 56,
    name: 'fry.world',
    symbol: 'FRIES',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7f29c237-a1db-4714-8ea1-0e6ba5a22e09',
    address: '0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc',
    chainId: 56,
    name: 'Ferrum Network Token',
    symbol: 'FRM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd8db7d3b-1d7a-4736-98e8-d20d80159255',
    address: '0x8523518001ad5d24b2A04e8729743C0643A316c0',
    chainId: 56,
    name: 'FRMx Token',
    symbol: 'FRMX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ff9b31e6-9222-4fb6-beee-a943e8839845',
    address: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
    chainId: 56,
    name: 'Frontier Token',
    symbol: 'FRONT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c7717787-15ee-494c-a6f7-550cac0d6d8c',
    address: '0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
    chainId: 56,
    name: 'Fuel',
    symbol: 'FUEL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '53a03ea3-1d67-4a51-9e7e-bad2d38bad77',
    address: '0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
    chainId: 56,
    name: 'Galxe',
    symbol: 'GAL',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c48d74f9-e974-4847-9816-5170bc1e2aa2',
    address: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
    chainId: 56,
    name: 'HARD',
    symbol: 'HARD',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5d7c3e00-e7a0-445a-969c-af55a886c770',
    address: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    chainId: 56,
    name: 'Helmet.insure',
    symbol: 'Helmet',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cfabc16b-aea3-4f10-8180-c03df02a52b9',
    address: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
    chainId: 56,
    name: 'Highstreet Token',
    symbol: 'HIGH',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a18bc8ad-8043-438d-b5b9-39afca359aac',
    address: '0xf16e81dce15B08F326220742020379B855B87DF9',
    chainId: 56,
    name: 'IceToken',
    symbol: 'ICE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'abe2df3b-8747-446d-bfd6-7322a84ef039',
    address: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    chainId: 56,
    name: 'Injective Protocol',
    symbol: 'INJ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '441b4442-ff97-4cd0-8b28-1a590bbfcafa',
    address: '0x903beFFC8eCC50841373D0ECc2CA53Fa4B04C31F',
    chainId: 56,
    name: 'Ivy Live',
    symbol: 'IVY',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-03-01T12:21:54.491Z'
  },
  {
    id: '3418a0a5-50f1-49e6-b359-f839b12b6faf',
    address: '0x3c037C4c2296f280bB318D725D0b454B76c199b9',
    chainId: 56,
    name: 'JNTR/b',
    symbol: 'JNTR/b',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '83e548c5-c991-4dcf-be54-87519dc4d0c3',
    address: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
    chainId: 56,
    name: 'Juventus',
    symbol: 'JUV',
    decimals: 2,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '603becb6-47fa-4b5f-ae71-54bdd6ab3d8f',
    address: '0x5F88AB06e8dfe89DF127B2430Bba4Af600866035',
    chainId: 56,
    name: 'KAVA',
    symbol: 'KAVA',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a7859d35-e19a-46ad-9bab-0b9ef17eb3f2',
    address: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
    chainId: 56,
    name: 'Qian Governance Token',
    symbol: 'KUN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '912c55d3-7ec7-428b-82c1-1e2f98e7b8d2',
    address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    chainId: 56,
    name: 'ChainLink Token',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f29c1c64-16c0-448e-806d-637eec6e611a',
    address: '0xc7981767f644C7F8e483DAbDc413e8a371b83079',
    chainId: 56,
    name: 'Liquidus (LIQ) ',
    symbol: 'LIQ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '40f13923-71cd-4883-999a-8a5efeec1bca',
    address: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    chainId: 56,
    name: 'Litecoin Token',
    symbol: 'LTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '96d00ecc-9ac7-481f-9571-4eac69d082c7',
    address: '0x3947B992DC0147D2D89dF0392213781b04B25075',
    chainId: 56,
    name: 'Mirror AMZN Token',
    symbol: 'mAMZN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5bc69491-bc72-4bbd-8c65-b85618f68beb',
    address: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
    chainId: 56,
    name: 'Math',
    symbol: 'MATH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '60ee9cab-29e0-4c96-a0d4-a5ddf1595480',
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    chainId: 56,
    name: 'Polygon Matic on BSC',
    symbol: 'MATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-02-29T09:26:07.694Z'
  },
  {
    id: '1abed441-7745-4d40-9fd0-b86b4d7fdf5f',
    address: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
    chainId: 56,
    name: 'Mirror GOOGL Token',
    symbol: 'mGOOGL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '102b2a06-40cf-461a-852e-4fdbb2604a19',
    address: '0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa',
    chainId: 56,
    name: 'Magpie Token',
    symbol: 'MGP',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '48ec99af-8eb2-4082-9b1b-a725067923d0',
    address: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
    chainId: 56,
    name: 'Mirror NFLX Token',
    symbol: 'mNFLX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a730313e-2f8d-435d-b80c-ebdb3eae21ab',
    address: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
    chainId: 56,
    name: 'Mirror TSLA Token',
    symbol: 'mTSLA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2bcc7420-31e2-42a1-bdc8-0dccfbafe8ba',
    address: '0xA1303E6199b319a891b79685F0537D289af1FC83',
    chainId: 56,
    name: 'NAR Token',
    symbol: 'NAR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'feeb9588-ee04-48f6-8633-2629313dd88c',
    address: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
    chainId: 56,
    name: 'Nuls',
    symbol: 'NULS',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '54e14eb3-20d0-401c-9a51-6833d1c19ecd',
    address: '0xf0E406c49C63AbF358030A299C0E00118C4C6BA5',
    chainId: 56,
    name: 'NerveNetwork',
    symbol: 'NVT',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0ab883ba-7c0a-45c4-b3a9-57741c5a300b',
    address: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
    chainId: 56,
    name: 'Nyanswop Token',
    symbol: 'NYA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '98c5da8c-a76a-42f5-b22f-c1ccec29dd70',
    address: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
    chainId: 56,
    name: 'OG',
    symbol: 'OG',
    decimals: 2,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5dd122dd-0abf-4500-b702-56517526ff94',
    address: '0x7E6a1299Ae38b796404eE0d771B9eBc5Fa535e7D',
    chainId: 56,
    name: 'OMNIA',
    symbol: 'OMNIA',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '34c70c39-4cfd-449f-ac61-187d816b5c1c',
    address: '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
    chainId: 56,
    name: 'Ontology Token',
    symbol: 'ONT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c339a48c-6d5e-4307-a9f1-f814a80f1302',
    address: '0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0',
    chainId: 56,
    name: 'Oraichain',
    symbol: 'ORAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-17T16:05:48.392Z'
  },
  {
    id: 'b78ac5b1-3d0c-4aca-a21a-b2d09520bfc3',
    address: '0xA2315cC5A1e4aE3D0a491ED4Fe45EBF8356fEaC7',
    chainId: 56,
    name: 'pBNB',
    symbol: 'pBNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2f7f3800-bd1f-43ac-8928-ff7fb6e8fd4d',
    address: '0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5',
    chainId: 56,
    name: 'Prometeus',
    symbol: 'PROM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '92e71ad6-2555-4c20-a156-a36e6fe50ac7',
    address: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
    chainId: 56,
    name: 'Paris Saint-Germain',
    symbol: 'PSG',
    decimals: 2,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4dbc4f67-2754-441c-985e-4e843629fc5f',
    address: '0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E',
    chainId: 56,
    name: 'QUSD Stablecoin',
    symbol: 'QUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '000be43e-26d9-4659-b5ba-c2548f4b578c',
    address: '0x12BB890508c125661E03b09EC06E404bc9289040',
    chainId: 56,
    name: 'Radio Caca V2',
    symbol: 'RACA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f9f60a22-a5a3-4087-86e9-56d08dc09cf1',
    address: '0x30807D3b851A31d62415B8bb7Af7dCa59390434a',
    chainId: 56,
    name: 'RadioShack Token',
    symbol: 'RADIO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0486f64e-06f2-4ae3-a36c-c9b9cd8a29f8',
    address: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
    chainId: 56,
    name: 'Reef',
    symbol: 'REEF',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5d8eea97-a4a1-41f9-9e33-f4be4cdea2b7',
    address: '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
    chainId: 56,
    name: 'FC Santos Fan Token',
    symbol: 'SANTOS',
    decimals: 8,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '74c0b289-9f99-499a-ab39-b17135a6895d',
    address: '0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240',
    chainId: 56,
    name: 'SingularityDAO',
    symbol: 'SDAO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-17T16:05:48.392Z'
  },
  {
    id: '8be2a956-671f-444a-b016-65dbb2e84124',
    address: '0x477bC8d23c634C154061869478bce96BE6045D12',
    chainId: 56,
    name: 'SeedifyFund',
    symbol: 'SFUND',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '84dc7632-067e-45a3-bc83-27be06365cca',
    address: '0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9',
    chainId: 56,
    name: 'Shack Token',
    symbol: 'SHACK',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '358c3f09-2b94-47d7-a092-fde257e01465',
    address: '0xE4Ae305ebE1AbE663f261Bc00534067C80ad677C',
    chainId: 56,
    name: 'SPARTAN PROTOCOL TOKEN',
    symbol: 'SPARTA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cc1534eb-4951-4555-8ad2-e6878230a093',
    address: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    chainId: 56,
    name: 'StableXSwap',
    symbol: 'STAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c00e51c0-4de1-401d-b5a4-10db0d8be745',
    address: '0x90DF11a8ccE420675e73922419e3f4f3Fe13CCCb',
    chainId: 56,
    name: 'Streamity',
    symbol: 'STM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f5824f81-508d-4524-9c6d-267df8a13dcc',
    address: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    chainId: 56,
    name: 'SushiToken',
    symbol: 'SUSHI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3f3c0877-0f89-41ae-8898-cd39555cdd4a',
    address: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    chainId: 56,
    name: 'Swipe',
    symbol: 'SXP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b0cf3d8c-df28-41f1-8f94-cd5f8d5e2d33',
    address: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
    chainId: 56,
    name: 'Tenet',
    symbol: 'TEN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd4bd489d-cb88-4bb7-be5b-a87dfd46d660',
    address: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    chainId: 56,
    name: 'Trust Wallet',
    symbol: 'TWT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fd73ff50-9dad-4e75-8f71-fb1201cee4a7',
    address: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
    chainId: 56,
    name: 'Unifi Token',
    symbol: 'UNFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '76298ac5-bd36-460a-bcc3-2f8e15d39052',
    address: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    chainId: 56,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'dd167d58-efdb-4d7b-b84c-36e7243abe59',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainId: 56,
    name: 'Binance-Peg USD Coin',
    symbol: 'USDC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '584c9286-b259-46a0-98e0-a06fe01111f8',
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e56ccd22-15cb-4bff-aed9-3b8fbd92ce2a',
    address: '0x1203355742e76875154C0D13eB81DCD7711dC7d9',
    chainId: 56,
    name: 'USDX',
    symbol: 'USDX',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c64e4115-e14c-420d-8ae0-d2c02f638f95',
    address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    chainId: 56,
    name: 'UST Token',
    symbol: 'UST',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '56c4f243-b88f-45a1-a594-624cf026a8af',
    address: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
    chainId: 56,
    name: 'VAI Stablecoin',
    symbol: 'VAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a10de610-c955-491f-8fe2-ad4ccbaed26e',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: 56,
    name: 'WBNB Token',
    symbol: 'WBNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '64c2c720-cb81-49d1-a34d-b4e1ba687655',
    address: '0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD',
    chainId: 56,
    name: 'Wombex Token',
    symbol: 'WMX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd9f05609-c1af-4b06-9550-1f6bdc83f5e3',
    address: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
    chainId: 56,
    name: 'Wombat Token',
    symbol: 'WOM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '71d8bd84-74dd-423c-afb9-4b966c76e898',
    address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    chainId: 56,
    name: 'Wootrade Network',
    symbol: 'WOO',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a256e0ff-c1fa-4a3c-8761-2b8e311d0c82',
    address: '0x837A130aED114300Bab4f9f1F4f500682f7efd48',
    chainId: 56,
    name: 'WeSendit',
    symbol: 'WSI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0febca0a-32a3-4de5-b735-d416b0d85c79',
    address: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
    chainId: 56,
    name: 'Soteria',
    symbol: 'wSOTE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e105e961-1265-4560-8d8c-9e1b65ffafb9',
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    chainId: 56,
    name: 'XRP Token',
    symbol: 'XRP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3697d2fc-72e6-4b1c-a2d4-5197f3c1554c',
    address: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
    chainId: 56,
    name: 'Tezos Token',
    symbol: 'XTZ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6f84b92e-cb1b-4c76-8cd8-34fdbea53ad7',
    address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    chainId: 56,
    name: 'Venus',
    symbol: 'XVS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1fb47af4-dbd8-4d77-a8ff-f96217fb3f73',
    address: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
    chainId: 56,
    name: 'yearn.finance',
    symbol: 'YFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4d1d224f-833c-43b6-880e-2261e42c0415',
    address: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
    chainId: 56,
    name: 'YFII.finance Token',
    symbol: 'YFII',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '25fa66aa-2b9b-4d35-9562-192aeb854793',
    address: '0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
    chainId: 56,
    name: 'Zcash Token',
    symbol: 'ZEC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'bda45d0c-adc1-4361-9849-b06dabe486f5',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 97,
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '115ad134-6c4f-4391-87d7-554e0abfae9c',
    address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
    chainId: 97,
    name: 'BTCB Token',
    symbol: 'BTCB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4174d5f6-bee8-412b-b628-ed606e7058a9',
    address: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    chainId: 97,
    name: 'Binance USD',
    symbol: 'BUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a61bf3bd-b9f1-4fec-9b2c-97b00e2775dd',
    address: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
    chainId: 97,
    name: 'Dai Token',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ad371087-7692-4bec-8274-daa5c5b8eb44',
    address: '0x67bB13debaE731Ad1506686023DB944080f793D4',
    chainId: 97,
    name: 'Dai Stablecoin (deBridge)',
    symbol: 'deDAI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c412a132-e0b3-4f84-8b7f-9e881b04029d',
    address: '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
    chainId: 97,
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'eea6d24e-80b0-4287-8a6f-9f2bede1321c',
    address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    chainId: 97,
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5a0caa03-4cd8-4039-a757-2b2c18fa4c74',
    address: '0xa83575490D7df4E2F47b7D38ef351a2722cA45b9',
    chainId: 97,
    name: 'XRP',
    symbol: 'XRP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '66f37ec5-a903-45c6-9537-28d28424c110',
    address: '0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f',
    chainId: 137,
    name: '1INCH-1Inch (PoS)',
    symbol: '1INCH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1df91ec1-6fe1-46ff-972b-0861a3820d29',
    address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    chainId: 137,
    name: 'AAVE-Aave (PoS)',
    symbol: 'AAVE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ce858631-4a25-440c-a94e-7d63ca285699',
    address: '0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89',
    chainId: 137,
    name: 'ALCX-Alchemix (PoS)',
    symbol: 'ALCX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a8dfefa8-fa18-4833-a000-32c88c53f6ac',
    address: '0x50858d870FAF55da2fD90FB6DF7c34b5648305C6',
    chainId: 137,
    name: 'ALICE-ALICE (PoS)',
    symbol: 'ALICE',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a9896e09-88c4-4252-9252-764a1a827757',
    address: '0x2b8504ab5eFc246d0eC5Ec7E74565683227497de',
    chainId: 137,
    name: 'ANT-Aragon Network Token (PoS)',
    symbol: 'ANT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b0bef754-bb14-40d6-9c0b-8410f4024b6e',
    address: '0x45C27821E80F8789b60Fd8B600C73815d34DDa6C',
    chainId: 137,
    name: 'API3-API3 (PoS)',
    symbol: 'API3',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6cc49f5a-62ef-406c-b87b-54b3110411ee',
    address: '0x15c310F7Af0F5379D84dfBA2fbF41b127023C784',
    chainId: 137,
    name: 'ARCH-Archer DAO Governance Token (PoS)',
    symbol: 'ARCH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '12392c80-b1ee-463f-be31-e1f1b37fb3e0',
    address: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    chainId: 137,
    name: 'BAL-Balancer (PoS)',
    symbol: 'BAL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2b9049cf-c3b0-4773-922e-e16b101efeb5',
    address: '0xA8b1E0764f85f53dfe21760e8AfE5446D82606ac',
    chainId: 137,
    name: 'BAND-BandToken (PoS)',
    symbol: 'BAND',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cada3a01-42e1-4a5f-8531-f08c078831c6',
    address: '0x3Cef98bb43d732E2F285eE605a8158cDE967D219',
    chainId: 137,
    name: 'BAT-Basic Attention Token (PoS)',
    symbol: 'BAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '08298ecc-aecd-4a53-af0d-ad95bc4da446',
    address: '0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F',
    chainId: 137,
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4ee86421-1f4c-44e8-abe7-d99b9b409221',
    address: '0xc26D47d5c33aC71AC5CF9F776D63Ba292a4F7842',
    chainId: 137,
    name: 'BNT-Bancor Network Token (PoS)',
    symbol: 'BNT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ea166dff-2fd0-43c6-862a-5406ab9b96a2',
    address: '0x7d8c139d5bfBafdfD1D2D0284e7fC862babE4137',
    chainId: 137,
    name: 'BOR-BoringDAO (PoS)',
    symbol: 'BOR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6196c9f2-6d42-43a9-909e-104036f83ec8',
    address: '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
    chainId: 137,
    name: 'COMP-Compound',
    symbol: 'COMP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7fad40a5-e78b-4c15-92da-36fc32bce280',
    address: '0x483dd3425278C1f79F377f1034d9d2CaE55648B6',
    chainId: 137,
    name: 'Crowd Token',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: true,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b8cf5f76-35c3-4e9c-b160-ddbe6119701d',
    address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    chainId: 137,
    name: 'CRV-CRV (PoS)',
    symbol: 'CRV',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b0b2a113-0204-42ef-98ca-4579e0c31287',
    address: '0x66Dc5A08091d1968e08C16aA5b27BAC8398b02Be',
    chainId: 137,
    name: 'CVC-Civic (PoS)',
    symbol: 'CVC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c0c00f8b-8c81-4d95-8253-78d84d41a8e1',
    address: '0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9',
    chainId: 137,
    name: 'CelsiusX Wrapped ETH',
    symbol: 'CXETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cd6dd1fc-2c84-4337-9cbd-4cedfa9f4959',
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    chainId: 137,
    name: 'DAI-Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '845fd138-0b03-4f81-b8e1-1206970082ce',
    address: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
    chainId: 137,
    name: 'deusdc Token',
    symbol: 'DEUSDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '29148177-68d2-47cc-8087-7ae801494de8',
    address: '0xe4Bf2864ebeC7B7fDf6Eeca9BaCAe7cDfDAffe78',
    chainId: 137,
    name: 'DODO bird (PoS)',
    symbol: 'DODO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0eeb5907-3fae-4122-bf50-7767ad654bfe',
    address: '0x556f501CF8a43216Df5bc9cC57Eb04D4FFAA9e6D',
    chainId: 137,
    name: 'DUST-Distant Universe Stardust Token',
    symbol: 'DUST',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd037420c-a66d-4e7b-9fe4-ef03ae5bee57',
    address: '0xdd9bA3B2571BEA0854beb0508CE10FeD0eCa7e3e',
    chainId: 137,
    name: 'EnviDa',
    symbol: 'EDAT',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f657870a-a402-495c-96af-8c7dbff4d512',
    address: '0xc7031408C7978da9aCA03308CD104cb54E7A2EB3',
    chainId: 137,
    name: 'FEI-Fei USD (PoS)',
    symbol: 'FEI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a3d360ee-933b-45be-baac-10ede4c756ab',
    address: '0x5FFD62D3C3eE2E81C00A7b9079FB248e7dF024A8',
    chainId: 137,
    name: 'GNO-Gnosis Token (PoS)',
    symbol: 'GNO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2c19fbfe-7953-4a9b-bdca-a01424fb0b56',
    address: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
    chainId: 137,
    name: 'GRT-Graph Token (PoS)',
    symbol: 'GRT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '186df39d-2ec9-4a30-83de-5ae46b197f6c',
    address: '0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
    chainId: 137,
    name: 'KNC-Kyber Network Crystal v2 (PoS)',
    symbol: 'KNC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '288e79cb-dc3d-475e-84e9-f7a368fb7cd2',
    address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    chainId: 137,
    name: 'LINK-ChainLink Token',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '72dfbdce-f20a-44de-9910-7ad535d79b01',
    address: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
    chainId: 137,
    name: 'Liquidus (LIQ) ',
    symbol: 'LIQ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3eae220c-6f5e-497a-a20b-55e2bf78285a',
    address: '0x84e1670F61347CDaeD56dcc736FB990fBB47ddC1',
    chainId: 137,
    name: 'LRC-LoopringCoin V2 (PoS)',
    symbol: 'LRC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a755a8c1-5846-494a-b097-88aaf82da7a5',
    address: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    chainId: 137,
    name: 'MANA-Decentraland MANA',
    symbol: 'MANA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f71142ff-8db3-4f12-807a-28dbbe94d455',
    address: '0x0000000000000000000000000000000000001010',
    chainId: 137,
    name: 'Matic Token',
    symbol: 'MATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e71fe9da-b0ed-4d30-84a1-ccb3aeb6aeac',
    address: '0x200C234721b5e549c3693CCc93cF191f90dC2aF9',
    chainId: 137,
    name: 'BADMAD ROBOTS Metal',
    symbol: 'METAL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: true,
    timestamp: '2024-04-25T08:04:58.038Z'
  },
  {
    id: 'bbd3b2d1-03c2-4ecf-8107-1f7fe01f189d',
    address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    chainId: 137,
    name: 'miMATIC',
    symbol: 'MIMATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '704f4f10-dcf8-420b-8475-85b91d9a7388',
    address: '0x6f7C932e7684666C9fd1d44527765433e01fF61d',
    chainId: 137,
    name: 'MKR-MAKER (PoS)',
    symbol: 'MKR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '61f8cefd-20d7-4ae1-a14b-92908b8ebf2d',
    address: '0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC',
    chainId: 137,
    name: 'HH-Holyheld (PoS)',
    symbol: 'MOVE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e80e38ca-dbb8-418e-89ff-d1e374c9f1a5',
    address: '0x0Bf519071b02F22C17E7Ed5F4002ee1911f46729',
    chainId: 137,
    name: 'NMR-Numeraire (PoS)',
    symbol: 'NMR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c03033cc-806e-4b55-8a4b-273d95fe183b',
    address: '0xfA49101D56734aF877AA312a6a40f634d4E3729d',
    chainId: 137,
    name: 'OHM-Olympus (PoS)',
    symbol: 'OHM',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '798a8b3a-03cb-4b1f-a7a6-df67369d0b99',
    address: '0x62414D03084EeB269E18C970a21f45D2967F0170',
    chainId: 137,
    name: 'OMG-OMGToken (PoS)',
    symbol: 'OMG',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7218f613-aea1-4555-b8a7-773c51454413',
    address: '0x9880e3dDA13c8e7D4804691A45160102d31F6060',
    chainId: 137,
    name: 'OXT-Orchid (PoS)',
    symbol: 'OXT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7ad8118c-2319-4ddc-b8c4-8db2d6277654',
    address: '0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038',
    chainId: 137,
    name: 'Polaris',
    symbol: 'POLAR',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '05f70cd5-ba9b-4fb4-ad0c-98bd9b0bafc7',
    address: '0x8dc302e2141DA59c934d900886DbF1518Fd92cd4',
    chainId: 137,
    name: 'POLS-PolkastarterToken (PoS)',
    symbol: 'POLS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2fa94fd2-2144-4046-b844-db459dd01f02',
    address: '0x055dC5c3b7c386f2daefEc554bB42831fB4107Be',
    chainId: 137,
    name: 'PREMIA-Premia (PoS)',
    symbol: 'PREMIA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3648ac02-f6fa-46e8-8ad6-7a7d83bd7943',
    address: '0x9377Eeb7419486FD4D485671d50baa4BF77c2222',
    chainId: 137,
    name: 'PRQ-Parsiq (PoS)',
    symbol: 'PRQ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ea7d78f2-1fa4-411e-84eb-7a8dc26fbfda',
    address: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    chainId: 137,
    name: 'Quickswap',
    symbol: 'QUICK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd5046d7e-1818-4972-bb6a-c94d6e0856b8',
    address: '0x613a489785C95afEB3b404CC41565cCff107B6E0',
    chainId: 137,
    name: 'RadioShack Token',
    symbol: 'RADIO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '93604b1e-99c9-4c45-998b-cb2e42f80894',
    address: '0x19782D3Dc4701cEeeDcD90f0993f0A9126ed89d0',
    chainId: 137,
    name: 'REN-REN (PoS)',
    symbol: 'REN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c5700de2-5971-417b-b7ed-22b523b4f902',
    address: '0x6563c1244820CfBd6Ca8820FBdf0f2847363F733',
    chainId: 137,
    name: 'REPV2-Reputation (PoS)',
    symbol: 'REP',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e1ac4ae6-0fd3-4b8f-939f-eb893525dfea',
    address: '0x3b9dB434F08003A89554CDB43b3e0b1f8734BdE7',
    chainId: 137,
    name: 'RGT-Rari Governance Token (PoS)',
    symbol: 'RGT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '11cce77f-339c-4880-8ea3-abdda6a0e6c0',
    address: '0x722Eb54CD20D13938dF8E792A5849a2391C8463F',
    chainId: 137,
    name: 'RUNE-THORChain ETH.RUNE (PoS)',
    symbol: 'RUNE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '95f2d20c-1e47-4309-b29f-f04b91a27638',
    address: '0x35b937583F04A24963eb685F728a542240f28Dd8',
    chainId: 137,
    name: 'SFI-Spice (PoS)',
    symbol: 'SFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd02e0bb7-8692-4455-afc6-7ee8905b9efa',
    address: '0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9',
    chainId: 137,
    name: 'Shack Token',
    symbol: 'SHACK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '704f6ef4-3390-4427-8f45-03cacf87fc63',
    address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
    chainId: 137,
    name: 'SNX-Synthetix Network Token (PoS)',
    symbol: 'SNX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd8b203fd-878d-432f-814c-c08e07f35d61',
    address: '0x6Bf2eb299E51Fc5DF30Dec81D9445dDe70e3F185',
    chainId: 137,
    name: 'SRM-Serum (PoS)',
    symbol: 'SRM',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '729ccdf4-fa77-41f5-ba35-ad0fa297f12e',
    address: '0xd72357dAcA2cF11A5F155b9FF7880E595A3F5792',
    chainId: 137,
    name: 'STORJ-StorjToken (PoS)',
    symbol: 'STORJ',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '32112561-1e3b-4196-94a0-2745dbd4b911',
    address: '0xF81b4Bec6Ca8f9fe7bE01CA734F55B2b6e03A7a0',
    chainId: 137,
    name: 'SUSD-Synth sUSD (PoS)',
    symbol: 'SUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cd929f78-1674-4cf3-953c-c23dd4e75804',
    address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    chainId: 137,
    name: 'SUSHI-SushiToken (PoS)',
    symbol: 'SUSHI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a7c4e37c-248c-41e5-887a-1cdd88fe3fbf',
    address: '0x50a4a434247089848991DD8f09b889D4e2870aB6',
    chainId: 137,
    name: 'TBTC-tBTC (PoS)',
    symbol: 'TBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0126f88d-d8b2-45f0-ba90-1943b2e3c352',
    address: '0xE57594F829b3d514EE12C41e81DF3861B19c26e3',
    chainId: 137,
    name: 'TMN Global Wrapper',
    symbol: 'TMNG',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-11T14:35:24.375Z'
  },
  {
    id: 'a50be0d0-c82d-4618-a6f7-2e190d7c09be',
    address: '0x5b77bCA482bd3E7958b1103d123888EfCCDaF803',
    chainId: 137,
    name: 'TRU-TrueFi (PoS)',
    symbol: 'TRU',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '27b55469-b1fc-43d0-9e02-0bc1a0e0f4f7',
    address: '0x3066818837c5e6eD6601bd5a91B0762877A6B731',
    chainId: 137,
    name: 'UMA-UMA Voting Token v1 (PoS)',
    symbol: 'UMA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7d00dd09-56a1-44fe-bd70-f9910e0db0c1',
    address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    chainId: 137,
    name: 'UNI-Uniswap (PoS)',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cabd4905-b328-4b42-958b-c439a741639d',
    address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
    chainId: 137,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-04-03T16:13:11.556Z'
  },
  {
    id: '3df26837-ce4b-4876-b357-1090e6fd0c61',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    chainId: 137,
    name: 'USD Coin (PoS)',
    symbol: 'USDC.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5da4e86e-2cf2-4099-8778-19613fa13a2e',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: 137,
    name: 'USDT-Tether USD (PoS)',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '00576aa7-63fe-4732-8425-481118feb67b',
    address: '0x692597b009d13C4049a947CAB2239b7d6517875F',
    chainId: 137,
    name: 'UST-Wrapped UST Token (PoS)',
    symbol: 'UST',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '91e42f4c-c7aa-4ddf-9951-dfafaf690b70',
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    chainId: 137,
    name: 'WBTC-Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '941cffaf-c2ae-4733-801d-9a9b7c0569a6',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    chainId: 137,
    name: 'WETH-Wrapped Ether (PoS)',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b8c45713-5832-4402-8fd0-b2abd01c8b3b',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: 137,
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c965e9b7-a59c-4c2c-9afd-5a87e4b3e467',
    address: '0xDA537104D6A5edd53c6fBba9A898708E465260b6',
    chainId: 137,
    name: 'YFI-yearn.finance',
    symbol: 'YFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '221c2c4e-63b2-4a8c-a2f8-3e60ef0ab45b',
    address: '0xB718412ef9C238C3A90079F21B57808ff8C18F1C',
    chainId: 137,
    name: 'YLD-Yield (PoS)',
    symbol: 'YLD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '694cc5ae-9253-419f-8d08-c82ccc553e85',
    address: '0x5559Edb74751A0edE9DeA4DC23aeE72cCA6bE3D5',
    chainId: 137,
    name: 'ZRX-0x Protocol Token (PoS)',
    symbol: 'ZRX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8958f253-4195-4dc2-9e27-31da6068fc0b',
    address: '0x000000000000000000000000000000000000800A',
    chainId: 280,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a23c7c3b-fb7b-4be8-83cb-ac09d0142df7',
    address: '0x6A5279E99CA7786fb13F827Fc1Fb4F61684933d6',
    chainId: 324,
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b4aebd48-d667-42d6-a850-48adfa88ebf4',
    address: '0x2039bb4116B4EFc145Ec4f0e2eA75012D6C0f181',
    chainId: 324,
    name: 'Binance USD',
    symbol: 'BUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e09a7e0b-dde6-4500-9886-6fc95b022672',
    address: '0x405a7426277C288E963a9B6Ab2c9E5E6C4b5f05C',
    chainId: 324,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '156defdd-fdf8-4cb0-8383-e84f2084a2cb',
    address: '0xb82c76Ef99eD076eD23640f044469942912AE361',
    chainId: 324,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '942c28db-e8d9-4241-a13a-8e60f172c810',
    address: '0x000000000000000000000000000000000000800A',
    chainId: 324,
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '439f6640-68c6-47be-bd0c-42279a00f8ae',
    address: '0xb4C1544cb4163f4C2ECa1aE9Ce999F63892d912A',
    chainId: 324,
    name: 'Frax',
    symbol: 'FRAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T15:03:14.663Z'
  },
  {
    id: '3317906b-d62c-4513-a67d-feb749625ffd',
    address: '0x503234F203fC7Eb888EEC8513210612a43Cf6115',
    chainId: 324,
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T15:03:14.667Z'
  },
  {
    id: '67809d52-8929-48b0-8e16-d18688e5aaa1',
    address: '0x28a487240e4D45CfF4A2980D334CC933B7483842',
    chainId: 324,
    name: 'Matic Token',
    symbol: 'MATIC',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '15a803c2-7ed6-47fd-9229-51858160b163',
    address: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
    chainId: 324,
    name: 'Mute.io',
    symbol: 'MUTE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T15:03:14.656Z'
  },
  {
    id: '04e168ff-a4cf-426c-afcf-13945cb82f38',
    address: '0x47260090cE5e83454d5f05A0AbbB2C953835f777',
    chainId: 324,
    name: 'SPACE',
    symbol: 'SPACE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ccba9246-982c-461f-a9c1-08d80d11a709',
    address: '0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557',
    chainId: 324,
    name: 'USD+',
    symbol: 'USD+',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c3cab54a-953a-4772-86c8-62af8f20c671',
    address: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
    chainId: 324,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '76faeb70-bf0f-4d07-b158-39dac6ebf1b1',
    address: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
    chainId: 324,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T15:03:14.669Z'
  },
  {
    id: '701887b1-5aed-4661-980c-65b31b0169a2',
    address: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
    chainId: 324,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '13bf3089-27bb-4407-866d-c3e9dfc76300',
    address: '0xfC7E56298657B002b3e656400E746b7212912757',
    chainId: 324,
    name: 'zkUSD Dollar',
    symbol: 'zkUSD',
    decimals: 6,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T15:03:14.665Z'
  },
  {
    id: 'b908d596-fddb-4250-b431-be5a6813e7c5',
    address: '0xb374c757d290cA02923626273a65390ef9bF6D3A',
    chainId: 1130,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2024-05-08T11:30:06.007Z'
  },
  {
    id: 'd37d80dd-f60f-4e4f-8f34-b6a30986fcaf',
    address: '0xff00000000000000000000000000000000000002',
    chainId: 1130,
    name: 'DMC Bitcoin',
    symbol: 'dBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: '99b0b9d9-b17d-4476-aa11-0c92ac020dea',
    address: '0xff00000000000000000000000000000000000001',
    chainId: 1130,
    name: 'DMC Ethereum',
    symbol: 'dETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: '16a753d6-a1c0-482d-a513-be104ca9c35d',
    address: '0xdF00000000000000000000000000000000000001',
    chainId: 1130,
    name: 'TransferDomain',
    symbol: 'DFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: '6d88a1d4-c3cc-4379-a1b7-5d3ad04965ac',
    address: '0xFf0000000000000000000000000000000000000F',
    chainId: 1130,
    name: 'DMC USD',
    symbol: 'dUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: 'fadbdfc8-8cd2-4716-aee7-f4a877df72ba',
    address: '0xFF0000000000000000000000000000000000000d',
    chainId: 1130,
    name: 'DMC USDC',
    symbol: 'dUSDC',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: '6c6db87b-a6f2-452a-bf4e-791a668b91cb',
    address: '0xFF00000000000000000000000000000000000003',
    chainId: 1130,
    name: 'DMC Tether USDt',
    symbol: 'dUSDT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:49:57.444Z'
  },
  {
    id: 'a38e80c1-23a9-4341-ae18-7b85b9f49963',
    address: '0x49febbF9626B2D39aBa11C01d83Ef59b3D56d2A4',
    chainId: 1130,
    name: 'Wrapped DFI',
    symbol: 'WDFI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-07T10:53:01.066Z'
  },
  {
    id: 'fcb9ccce-4427-4ebb-beaf-0055c39b49ad',
    address: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
    chainId: 8453,
    name: 'Axelar Wrapped USDC',
    symbol: 'axlUSDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.689Z'
  },
  {
    id: '0eb4637e-d886-4b12-83a6-cc1958167168',
    address: '0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad',
    chainId: 8453,
    name: 'Axelar Wrapped WBTC',
    symbol: 'axlWBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.862Z'
  },
  {
    id: '216d233b-1dcb-46a6-848c-d0eaf7a99a4c',
    address: '0x27D2DECb4bFC9C76F0309b8E88dec3a601Fe25a8',
    chainId: 8453,
    name: 'Bald',
    symbol: 'BALD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.866Z'
  },
  {
    id: '9ddb3cf8-c8aa-4437-bd9e-f9d4d24a4c36',
    address: '0xd07379a755A8f11B57610154861D694b2A0f615a',
    chainId: 8453,
    name: 'BASE',
    symbol: 'BASE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.683Z'
  },
  {
    id: '904e441a-1e48-4519-a147-c2ef904592e4',
    address: '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
    chainId: 8453,
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.508Z'
  },
  {
    id: 'd4758dd8-abaa-4444-8a67-7faa1b203d2a',
    address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
    chainId: 8453,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.138Z'
  },
  {
    id: '289b9c4f-d24d-442f-9287-5591561bb356',
    address: '0x65a2508C429a6078a7BC2f7dF81aB575BD9D9275',
    chainId: 8453,
    name: 'DAI+',
    symbol: 'DAI+',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.697Z'
  },
  {
    id: '9261e124-1a35-4c54-a34a-9c2d4cc53d3b',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 8453,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.149Z'
  },
  {
    id: '93019321-8f86-43e6-9632-d3f4a2af6ba0',
    address: '0x28fe69Ff6864C1C218878BDCA01482D36B9D57b1',
    chainId: 8453,
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.333Z'
  },
  {
    id: '8ffb17fd-437c-45e8-ad72-2ab4a2443c0a',
    address: '0xbf1aeA8670D2528E08334083616dD9C5F3B087aE',
    chainId: 8453,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.701Z'
  },
  {
    id: 'b34ab3ae-eb2a-439b-83f7-2b3bcf89adaa',
    address: '0x4A3A6Dd60A34bB2Aba60D73B4C88315E9CeB6A3D',
    chainId: 8453,
    name: 'Magic Internet Money',
    symbol: 'MIM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.345Z'
  },
  {
    id: '51beed2b-db35-47a2-a8a2-2c1384f2154d',
    address: '0xB6fe221Fe9EeF5aBa221c348bA20A1Bf5e73624c',
    chainId: 8453,
    name: 'Rocket Pool ETH',
    symbol: 'rETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.495Z'
  },
  {
    id: '92cca815-b673-4661-8edc-910c5e86f9f7',
    address: '0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b',
    chainId: 8453,
    name: 'Base tBTC v2',
    symbol: 'tBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:02.755Z'
  },
  {
    id: 'bddd5ae8-e627-4903-83b5-d45bc57a9a1a',
    address: '0xB79DD08EA68A908A97220C76d19A6aA9cBDE4376',
    chainId: 8453,
    name: 'USD+',
    symbol: 'USD+',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.867Z'
  },
  {
    id: 'da5b33a6-b8d6-4888-be37-a3c30192f1d7',
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    chainId: 8453,
    name: 'USD Base Coin',
    symbol: 'USDbC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.532Z'
  },
  {
    id: 'b434bb23-25ff-4cb9-83f0-a48f2750d48d',
    address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    chainId: 8453,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.314Z'
  },
  {
    id: 'ab099d9d-8706-41e4-a0e8-9ef8b79c92fd',
    address: '0x4200000000000000000000000000000000000006',
    chainId: 8453,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.515Z'
  },
  {
    id: '4bb9b449-d8ad-4e34-8a91-d24ac2206b0b',
    address: '0x155f0DD04424939368972f4e1838687d6a831151',
    chainId: 42161,
    name: 'ArbiDoge',
    symbol: 'ADoge',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c9dbcf7a-7fcb-41b0-9a7c-2ca797f6314f',
    address: '0xB2d948Be3a74ECCe80378D4093E6cD7f4dC1Cf9C',
    chainId: 42161,
    name: 'Alt Markets',
    symbol: 'AMX',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fcbfcab1-c154-4f90-b008-615284bcb411',
    address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    chainId: 42161,
    name: 'Arbitrum',
    symbol: 'ARB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'aa16c454-1f92-4ac7-9ff7-d00ce2a17e92',
    address: '0x7F465507f058e17Ad21623927a120ac05CA32741',
    chainId: 42161,
    name: 'Arcadeum',
    symbol: 'ARC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '34001269-3b89-46f0-8a66-428f3a717e73',
    address: '0x9e724698051DA34994F281bD81C3E7372d1960AE',
    chainId: 42161,
    name: 'Ascension Protocol',
    symbol: 'ASCEND',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '922d9249-9943-4155-b0ab-461f42b1c577',
    address: '0x986a3DB4317177b4F84b05127E9e39a7Abd7187A',
    chainId: 42161,
    name: 'CatKing',
    symbol: 'CKING',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2034247f-4518-4a70-a7af-f94871d5ae3d',
    address: '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d',
    chainId: 42161,
    name: 'CreDA Protocol Token',
    symbol: 'CREDA',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7c4901d1-8d40-4525-8fbc-c961641a818a',
    address: '0x250Caff618cf205997309940c14C52B5DcEb351E',
    chainId: 42161,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4617f0e7-9419-4f67-8e21-751359d52fd6',
    address: '0x8096aD3107715747361acefE685943bFB427C722',
    chainId: 42161,
    name: 'Crypto Volatility Token',
    symbol: 'CVOL ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '01b9e8d8-b264-4753-a8ab-7bab46738e0d',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    chainId: 42161,
    name: 'Dai',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '334908f6-8434-4005-aff2-499207d14a67',
    address: '0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55',
    chainId: 42161,
    name: 'Dopex Governance Token',
    symbol: 'DPX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5fbcd1c0-c1d2-4ea5-bb5d-c52face74b59',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 42161,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e4c8a4cc-f717-480b-9adf-ed026166a7d7',
    address: '0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA',
    chainId: 42161,
    name: 'FluidFi',
    symbol: 'FLUID ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'dff26dfa-7aea-4eca-92d9-2da3e4661470',
    address: '0x2a3B32ee6B34C27EbC03Fa33FD80c42eA5998434',
    chainId: 42161,
    name: 'FTDex',
    symbol: 'FTD',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'eb82f097-ef45-423f-a258-8014e34c59cd',
    address: '0x458a2df1A5C74C5dc9eD6E01Dd1178E6D353243B',
    chainId: 42161,
    name: 'Gemstone',
    symbol: 'GEM',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '09bf9893-d3f7-45e3-83b3-9d59648c4fbb',
    address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    chainId: 42161,
    name: 'GMX',
    symbol: 'GMX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e338edb2-3dee-4c51-84f1-092d6ab9bf3f',
    address: '0x18c11FD286C5EC11c3b683Caa813B77f5163A122',
    chainId: 42161,
    name: 'Gains Network',
    symbol: 'GNS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5939f0f7-d3bb-415c-b5a5-cff24ecc8e02',
    address: '0xD5A56b510247DB33695b0bEa29992AC6670081ea',
    chainId: 42161,
    name: 'Goons of Balatroon',
    symbol: 'GOB ',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f4155e26-2228-4346-9a38-5269c21a5f1e',
    address: '0xfD559867B6d3445F9589074C3AC46418fDFffDA4',
    chainId: 42161,
    name: 'Goblin',
    symbol: 'GOBLIN',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a8e62f66-ecc2-4b42-af7a-61fc37e91d74',
    address: '0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1',
    chainId: 42161,
    name: 'Governance OHM',
    symbol: 'gOHM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f51fcfa7-700d-4306-ac29-c12217222fde',
    address: '0xF16585365bfe050EFf7c926F22DF87f46da2CAFE',
    chainId: 42161,
    name: 'Gold',
    symbol: 'GOLD',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a0319df1-5e4a-4359-8c6c-c92192b2e89b',
    address: '0x07E49d5dE43DDA6162Fa28D24d5935C151875283',
    chainId: 42161,
    name: 'GOVI',
    symbol: 'GOVI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9f36276b-1201-4957-9aef-9b5a862c12ed',
    address: '0x02150e97271fDC0D6E3A16d9094A0948266F07dD',
    chainId: 42161,
    name: 'Hamachi',
    symbol: 'HAMI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '31cf894d-2100-4f9b-9861-eb7a0f424f19',
    address: '0xBcc9C1763d54427bDF5EfB6e9EB9494E5a1fbAbf',
    chainId: 42161,
    name: 'HonorWorld Token',
    symbol: 'HWT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f0bb8dd9-c1b3-4f57-905a-0fc45ba94d27',
    address: '0x10393c20975cF177a3513071bC110f7962CD67da',
    chainId: 42161,
    name: 'Jones DAO',
    symbol: 'JONES',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e00fe6bf-c9c4-4d55-9c65-709e82aa7427',
    address: '0xe4DDDfe67E7164b0FE14E218d80dC4C08eDC01cB',
    chainId: 42161,
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1aeba94a-1c2b-48fd-bef7-75009acc7bfa',
    address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
    chainId: 42161,
    name: 'ChainLink Token',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'eb7bd9f7-e421-4276-9732-73a069484c9b',
    address: '0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f',
    chainId: 42161,
    name: 'Liquid',
    symbol: 'LIQD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '56c0dde9-3ce3-46fb-9fc6-589558c870cc',
    address: '0xF19547f9ED24aA66b03c3a552D181Ae334FBb8DB',
    chainId: 42161,
    name: 'Lodestar',
    symbol: 'LODE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '31832022-64f8-48a0-a73f-5adc3d9bfc68',
    address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
    chainId: 42161,
    name: 'MAGIC',
    symbol: 'MAGIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'fc4194fb-71db-44f5-a31c-cbc3a6f49966',
    address: '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
    chainId: 42161,
    name: 'Magic Internet Money',
    symbol: 'MIM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4dc45c2c-2874-483b-a795-34c61266fd58',
    address: '0x27D8De4c30ffDE34e982482AE504fC7F23061f61',
    chainId: 42161,
    name: 'MyMetaTrader Token',
    symbol: 'MMT ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f0d8ae35-89ac-4106-9520-bc18a9570f7c',
    address: '0x1FAe2A29940015632f2a6CE006dFA7E3225515A7',
    chainId: 42161,
    name: 'Nitro Floki',
    symbol: 'NIFLOKI',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '059290ab-98bb-4b65-ba1e-08e8c9e5e591',
    address: '0x4DAD357726b41bb8932764340ee9108cC5AD33a0',
    chainId: 42161,
    name: 'NitroShiba',
    symbol: 'NISHIB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '26a3e42e-eb09-49f1-9276-fd9c5c48092e',
    address: '0xeE9857dE0e55d4A54D36a5A5a73A15e57435FdCA',
    chainId: 42161,
    name: 'AsgardX',
    symbol: 'ODIN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9672bcaf-d384-4247-bc00-17b76b1e5c68',
    address: '0xEFc43Cf79f406D62960e34d3a62c729a0EEbEC4b',
    chainId: 42161,
    name: 'PoSH4D',
    symbol: 'P4D',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '58b93d72-56a0-4cce-bfcf-e284a14fd067',
    address: '0x51318B7D00db7ACc4026C88c3952B66278B6A67F',
    chainId: 42161,
    name: 'Plutus',
    symbol: 'PLS',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '635528f2-d723-4795-982b-6400b998f713',
    address: '0x51fC0f6660482Ea73330E414eFd7808811a57Fa2',
    chainId: 42161,
    name: 'Premia',
    symbol: 'PREMIA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '706d72cc-32f9-44fa-bc16-8df1a84e88b1',
    address: '0x0C4681e6C0235179ec3D4F4fc4DF3d14FDD96017',
    chainId: 42161,
    name: 'Radiant',
    symbol: 'RDNT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a2d8a6c2-0243-4fa3-a60e-d13818c8b52a',
    address: '0x32Eb7902D4134bf98A28b963D26de779AF92A212',
    chainId: 42161,
    name: 'Dopex Rebate Token',
    symbol: 'RDPX ',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '7a7da11c-9e62-44ca-9d47-7017d0ed39b3',
    address: '0xc7831178793868a75122EAaFF634ECe07a2ecAAA',
    chainId: 42161,
    name: 'ArbiRoul Casino Chip',
    symbol: 'ROUL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd8a19b10-a878-4b14-861b-2150ceda8c6c',
    address: '0xaa54e84A3e6e5A80288d2C2f8e36eA5cA3A3Ca30',
    chainId: 42161,
    name: 'SHARBI',
    symbol: 'SHARBI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '80ddb000-6ff9-4d35-8bd9-e91b4a3ef7fb',
    address: '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
    chainId: 42161,
    name: 'Spell Token',
    symbol: 'SPELL',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8f2e587d-60e8-490e-ac81-00d20e01cf60',
    address: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
    chainId: 42161,
    name: 'SushiToken',
    symbol: 'SUSHI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '012cebb0-cc2d-468c-9d92-bc48edfa3585',
    address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
    chainId: 42161,
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '02f966c4-b407-486e-9934-84b666b454ca',
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    chainId: 42161,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-10-14T17:43:05.388Z'
  },
  {
    id: '8a9f8074-ba78-4240-a345-511e23a018b8',
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    chainId: 42161,
    name: 'Bridged USDC USDC.e',
    symbol: 'USDC.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ba15639a-9aea-4aa2-bd22-ded0b2237416',
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    chainId: 42161,
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd58b4c6f-d228-495e-9058-da5c90c49f9b',
    address: '0xa0eeBB0E5C3859a1c5412C2380c074f2f6725e2E',
    chainId: 42161,
    name: 'Vox Finance 2.0',
    symbol: 'VOX2.0',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '03eb846e-e3a2-42b8-9eff-12beb5de5f90',
    address: '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
    chainId: 42161,
    name: 'Wrapped Banano',
    symbol: 'wBAN',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd8f7d8b5-2337-4d24-bf13-247059b655cd',
    address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    chainId: 42161,
    name: 'Wrapped Bitcoin',
    symbol: 'WBTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0eb2b1cb-7ce5-4ea3-8219-5e1bd47026e3',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    chainId: 42161,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '68d8a052-6bd3-40cf-9ad5-091d70387e1a',
    address: '0x30dcBa0405004cF124045793E1933C798Af9E66a',
    chainId: 42161,
    name: 'Yieldification',
    symbol: 'YDF',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0f44753f-106b-4e3e-84a3-fe06a4b24890',
    address: '0x849BeBd17264E1c7108492458CA965C2D641AccE',
    chainId: 42161,
    name: 'ArbZilla',
    symbol: 'ZILLA',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '92f41f3b-4828-41a8-a5e3-1bbabd46d7e0',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 43113,
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd093175b-a76b-40ca-b51e-cda6ee1bc98f',
    address: '0x34B6C87bb59Eb37EFe35C8d594a234Cd8C654D50',
    chainId: 43113,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd80c40dd-8d6e-4bf6-b9c7-ed97fa1cc7e3',
    address: '0x93Aabe5509C226b28eB1372C8af867Ae49828378',
    chainId: 43113,
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b6bab03e-f5b4-4e0a-8fee-4d22e730649d',
    address: '0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3',
    chainId: 43113,
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'efe34f40-fedc-4ab2-a992-39255cba52a6',
    address: '0xd501281565bf7789224523144Fe5D98e8B28f267',
    chainId: 43114,
    name: '1INCH Token',
    symbol: '1INCH.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9123a30f-3523-4b90-b0b4-297270dcf18f',
    address: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    chainId: 43114,
    name: 'Aave Token',
    symbol: 'AAVE.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f1d89cbb-89d1-490f-aad7-01e8a227ab69',
    address: '0x2147EFFF675e4A4eE1C2f918d181cDBd7a8E208f',
    chainId: 43114,
    name: 'AlphaToken',
    symbol: 'ALPHA.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'e67b978d-da82-4dd3-8a95-c2356c37a3be',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 43114,
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ac67cbf3-c7f5-43ad-8b49-dd53960a961c',
    address: '0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    chainId: 43114,
    name: 'Axelar Wrapped UST',
    symbol: 'axlUST',
    decimals: 6,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'ae7d2f79-1aa7-4480-afd0-0659f70afdd2',
    address: '0x98443B96EA4b0858FDF3219Cd13e98C7A4690588',
    chainId: 43114,
    name: 'Basic Attention Token',
    symbol: 'BAT.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'bd87822f-711d-40cc-b55e-937e6bfedff1',
    address: '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
    chainId: 43114,
    name: 'Binance USD',
    symbol: 'BUSD.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cf47d0d4-6784-4f20-9344-06a145c4c04f',
    address: '0xc3048E19E76CB9a3Aa9d77D8C03c29Fc906e2437',
    chainId: 43114,
    name: 'Compound',
    symbol: 'COMP.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd6da73a3-5c2a-4fd4-9f3a-26921cfe5430',
    address: '0x420FcA0121DC28039145009570975747295f2329',
    chainId: 43114,
    name: 'Coq Inu',
    symbol: 'COQ',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-12-29T12:30:49.033Z'
  },
  {
    id: 'c1ebcbfd-651f-482e-9ece-3831021ced14',
    address: '0x5032fB113dBe54037825d7059740314edE131004',
    chainId: 43114,
    name: 'CrowdToken Wrapper',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-07-06T09:10:42.341Z'
  },
  {
    id: '0b4b5b07-3318-4222-aaaf-fa4148e30f85',
    address: '0x249848BeCA43aC405b8102Ec90Dd5F22CA513c06',
    chainId: 43114,
    name: 'Curve DAO Token',
    symbol: 'CRV.e',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'df8ecab3-a297-4c63-8bb8-d60afa589e87',
    address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    chainId: 43114,
    name: 'Dai Stablecoin',
    symbol: 'DAI.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c58e8147-a71a-476b-babe-ca83d722799f',
    address: '0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3',
    chainId: 43114,
    name: 'deBridge USD Coin',
    symbol: 'DEUSDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: true,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f9b73438-78fd-4b6b-90f7-4c395c73e4ab',
    address: '0xAEDB70D42161e6e135250150EA561dD77a694798',
    chainId: 43114,
    name: 'DODO bird',
    symbol: 'DODO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9a4bfa27-8b62-4e6c-9bdb-7d17ef09a780',
    address: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    chainId: 43114,
    name: 'ELK',
    symbol: 'ELK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd824b2be-34cd-43f3-89dd-f2ee4a546a4e',
    address: '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
    chainId: 43114,
    name: 'Frax',
    symbol: 'FRAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '8272949b-4794-404f-8063-cf825f6b3974',
    address: '0x8a0cAc13c7da965a312f08ea4229c37869e85cB9',
    chainId: 43114,
    name: 'Graph Token',
    symbol: 'GRT.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b990daac-2053-4e10-9070-58f08a44248d',
    address: '0x5947BB275c521040051D82396192181b413227A3',
    chainId: 43114,
    name: 'Chainlink Token',
    symbol: 'LINK.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3e0e472c-3afc-45e2-afad-1be921cca7fe',
    address: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084',
    chainId: 43114,
    name: 'Lydia',
    symbol: 'LYD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5d9efa86-5961-4c5c-a772-f7b3e187d8a5',
    address: '0x130966628846BFd36ff31a822705796e8cb8C18D',
    chainId: 43114,
    name: 'Magic Internet Money',
    symbol: 'MIM',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c7d704d3-87b7-4a2d-81da-5e38ccc36412',
    address: '0x88128fd4b259552A9A1D457f435a6527AAb72d42',
    chainId: 43114,
    name: 'Maker',
    symbol: 'MKR.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '955e2ef7-cb22-42d1-a1a3-57971575dffa',
    address: '0x60781C2586D68229fde47564546784ab3fACA982',
    chainId: 43114,
    name: 'Pangolin',
    symbol: 'PNG',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '90448033-fb1e-4bbd-9ff3-8de5df1e9880',
    address: '0x02Bfd11499847003De5f0F5AA081C43854d48815',
    chainId: 43114,
    name: 'RadioShack Token',
    symbol: 'RADIO',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '63de5810-d59c-48de-886c-0dde72b96d5b',
    address: '0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9',
    chainId: 43114,
    name: 'Shack Token',
    symbol: 'SHACK',
    decimals: 18,
    observable: false,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6ebdff13-19f4-4d73-93d0-575a196554c8',
    address: '0x02D980A0D7AF3fb7Cf7Df8cB35d9eDBCF355f665',
    chainId: 43114,
    name: 'SHIBA INU',
    symbol: 'SHIB.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'bcfb9498-44cb-49d0-a02a-651391ec3212',
    address: '0xBeC243C995409E6520D7C41E404da5dEba4b209B',
    chainId: 43114,
    name: 'Synthetix Network Token',
    symbol: 'SNX.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '17e46eb2-c69d-46f7-bccd-f4d475dd316a',
    address: '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
    chainId: 43114,
    name: 'StargateToken',
    symbol: 'STG',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '08164649-f596-418c-8a39-816a7615255e',
    address: '0xA2CDe628d7617956EAF4780e32F68DF19cc13D62',
    chainId: 43114,
    name: 'Subava Token',
    symbol: 'SUBAVA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2024-05-06T10:11:05.797Z'
  },
  {
    id: 'dee110ef-cdd1-44a1-967b-d74d8658260a',
    address: '0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
    chainId: 43114,
    name: 'SushiToken',
    symbol: 'SUSHI.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '9e04153c-d486-4f61-b859-b117a4a72a8a',
    address: '0xc7B5D72C836e718cDA8888eaf03707fAef675079',
    chainId: 43114,
    name: 'TrustSwap Token',
    symbol: 'SWAP.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4b468647-def4-4082-b7c5-c373842abf96',
    address: '0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
    chainId: 43114,
    name: 'Time',
    symbol: 'TIME',
    decimals: 9,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f9525b38-661b-428e-9d97-3df3abe0acfc',
    address: '0x3Bd2B1c7ED8D396dbb98DED3aEbb41350a5b2339',
    chainId: 43114,
    name: 'UMA Voting Token v1',
    symbol: 'UMA.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '94bcf729-945f-4249-92ab-a9e5f03b8b3b',
    address: '0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
    chainId: 43114,
    name: 'Uniswap',
    symbol: 'UNI.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '3369e239-8934-4c17-a2c2-1257328cd387',
    address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    chainId: 43114,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '79ea3ae9-0530-4713-b2c6-4323ba37cdc6',
    address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    chainId: 43114,
    name: 'USD Coin',
    symbol: 'USDC.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5dd7a57e-689b-4139-972d-5b6db8a8e6e3',
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    chainId: 43114,
    name: 'Tether USD',
    symbol: 'USDT.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '40b64294-ed4a-485f-b5eb-81d579256eac',
    address: '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    chainId: 43114,
    name: 'Wormhole UST',
    symbol: 'UST',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '2bab9182-ae22-4137-8358-c88e27655dd1',
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    chainId: 43114,
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '85682181-a037-4573-8d39-009174d57b2f',
    address: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    chainId: 43114,
    name: 'Wrapped BTC',
    symbol: 'WBTC.e',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '4e2cd036-3433-43b7-ae03-3765dbbb59e8',
    address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    chainId: 43114,
    name: 'Wrapped Ether',
    symbol: 'WETH.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '76ce319d-052e-4b87-9599-59a74dc51374',
    address: '0xaBC9547B534519fF73921b1FBA6E672b5f58D083',
    chainId: 43114,
    name: 'Wootrade Network',
    symbol: 'WOO.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'cca4ae65-ac13-4b8e-b6ea-a0a0c815d3cc',
    address: '0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc',
    chainId: 43114,
    name: 'yearn.finance',
    symbol: 'YFI.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '0f922c2f-8a53-4202-a5cd-a3d92b59ffe2',
    address: '0x596fA47043f99A4e0F122243B841E55375cdE0d2',
    chainId: 43114,
    name: 'ZRX',
    symbol: 'ZRX.e',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '67a9c1d0-ddf7-4b19-8801-2b4544fb8a69',
    address: '0x5471ea8f739dd37E9B81Be9c5c77754D8AA953E4',
    chainId: 59144,
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.066Z'
  },
  {
    id: 'd2522719-a678-4e06-be76-c9d45f75dae5',
    address: '0xf5C6825015280CdfD0b56903F9F8B5A2233476F5',
    chainId: 59144,
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.064Z'
  },
  {
    id: '7dd34321-718f-426b-9ab8-de351e94b5cf',
    address: '0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4',
    chainId: 59144,
    name: 'Wrapped BTC',
    symbol: 'BTC',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:20:59.961Z'
  },
  {
    id: '88ef3197-93b3-4171-81bf-c67fdeb5fa0d',
    address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
    chainId: 59144,
    name: 'Binance USD',
    symbol: 'BUSD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.126Z'
  },
  {
    id: '5b344b6f-2c3c-445e-b219-4d54d31ccbef',
    address: '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5',
    chainId: 59144,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:20:59.753Z'
  },
  {
    id: '8579a0eb-c03f-4f88-b802-3251a50f05c6',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 59144,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:20:59.953Z'
  },
  {
    id: '929a5ce3-5dd7-47fe-98e1-69cf1d1bf1d9',
    address: '0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8',
    chainId: 59144,
    name: 'Matic Token',
    symbol: 'MATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.130Z'
  },
  {
    id: 'fdcd7668-9253-49c0-9832-17e80db57b4f',
    address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
    chainId: 59144,
    name: 'USD Coin',
    symbol: 'USDC.e',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:20:59.957Z'
  },
  {
    id: '1e4a9705-dff9-4792-8270-de2f127629af',
    address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
    chainId: 59144,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:20:59.964Z'
  },
  {
    id: '3eb0b4a4-9e50-4ac2-bf63-b65147153f72',
    address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    chainId: 59144,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-11-02T11:21:00.063Z'
  },
  {
    id: '14948a2a-37c0-478a-a978-3c9b3f023440',
    address: '0x341d1f30e77D3FBfbD43D17183E2acb9dF25574E',
    chainId: 80001,
    name: 'AAVE',
    symbol: 'AAVE',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '36cee7d7-d393-4132-b205-a30a31eedaf7',
    address: '0x1c5C229d2Ae902a6263e10Ad7bfeAbB462267f72',
    chainId: 80001,
    name: 'Crowd Token',
    symbol: 'CROWD',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '24d16171-aa9e-4c1b-b954-aaa57f338493',
    address: '0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F',
    chainId: 80001,
    name: 'DAI',
    symbol: 'DAI',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '496096cf-32c7-46c2-9a6f-c5c0e7d9b5a8',
    address: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
    chainId: 80001,
    name: 'ChainLink Token',
    symbol: 'LINK',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '69c159f2-6324-465b-9163-543ba6fc20b5',
    address: '0x0000000000000000000000000000000000001010',
    chainId: 80001,
    name: 'Matic Token',
    symbol: 'MATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '45261a17-9723-4c02-b94d-7144bd92f957',
    address: '0x2058A9D7613eEE744279e3856Ef0eAda5FCbaA7e',
    chainId: 80001,
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '08191ca0-7527-4e8f-a02f-9db3135b9f2e',
    address: '0x83aD7BBC3961b2b2e80C7c1A5E803ef171B966B8',
    chainId: 80001,
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'b560b053-35d1-4cea-8980-8723ee898c1b',
    address: '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
    chainId: 80001,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'f8cda102-76cc-4f4d-ac90-7216b6e768c1',
    address: '0x5B67676a984807a212b1c59eBFc9B3568a474F0a',
    chainId: 80001,
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c913c3f9-641b-49f5-ad87-0205a1b374b2',
    address: '0x747F964F5703f612e7bf7F9531747998D2925299',
    chainId: 1501795822,
    name: 'BlockVerse',
    symbol: 'BLOCK',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'd696b37b-fb2f-4d4e-b4dd-d1f94a5cc6c2',
    address: '0x494C93338110085ddBCEAE21fca7A091B51Ce6b1',
    chainId: 1501795822,
    name: 'BrickVerse',
    symbol: 'BRICK',
    decimals: 8,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'c516eaff-58d6-45a8-8c15-92f3cef47ebf',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    chainId: 1501795822,
    name: 'OMNIA',
    symbol: 'OMNIA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '1dffd28c-d51d-4c44-bde1-567be80b7f78',
    address: '0xb2a982b04160AB56d4c2e4aB02554980329D2B71',
    chainId: 1501795822,
    name: 'Sphynx Omnia',
    symbol: 'SPHYNX',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '5ec2f4c8-24d2-4ae1-a2b3-2b418cf5b2c7',
    address: '0x5F8eBD49507c3D280FA60801927b6cD03497aA0a',
    chainId: 1501795822,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '6af3298e-a973-4eb8-a09e-d65a848c40d4',
    address: '0x49Bcd7Cefa4eab598de281BaDe235f21Ff03653e',
    chainId: 1501795822,
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: '651b35c7-42d3-4b81-995b-7788b8b578ca',
    address: '0x8957AddBd2c2B093a3CEfa6f543Ce292CC78D52E',
    chainId: 1501795822,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a7e31d0e-3dd9-4a06-8b20-5787c973d4ee',
    address: '0x2de61D2Ea54C82dD9DF7b448d59FE1610A2e6807',
    chainId: 1501795822,
    name: 'Wrapped ETH',
    symbol: 'WETH',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  },
  {
    id: 'a2e1cd6c-7a03-4b34-b262-f22f1f599015',
    address: '0x80B6f432D3ACD773803FFBE0150ca7412d67cA76',
    chainId: 1501795822,
    name: 'Wrapped OMNIA',
    symbol: 'WOMNIA',
    decimals: 18,
    observable: true,
    hasCustomPrice: false,
    trending: false,
    timestamp: '2023-06-30T07:35:51.810Z'
  }
];
