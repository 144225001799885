import { CrowdToken } from '@crowdswap/constant';
import { IFee } from './cross-chain-estimate-trade.model';

export interface IGetAddressReqMdl {
  email: string;
  passwordHash: string;
}

export interface IUserDataMdl {
  email: string;
  passwordHash: string;
  userAddress: string;
}

export interface IGetTransferReqMdl {
  token: string;
  destination: string;
  amount: string;
  chainId: number;
  email: string;
  passwordHash: string;
  gasToken?: string;
}

export interface IGetSwapReqMdl {
  tokenInAddress: string;
  tokenOutAddress: string;
  amountIn: string;
  chainId: number;
  email: string;
  passwordHash: string;
  gasToken?: string;
}

export interface IExecuteSwap {
  email: string;
  passwordHash: string;
  swapParams: SwapParams;
  chainId: number;
  gasToken?: string;
}

type SwapParams = {
  fromToken: CrowdToken;
  toToken: CrowdToken;
  amount: string;
  userAddress: string;
  receiverAddress: string;
};

export interface IExecuteReqMdl {
  email: string;
  passwordHash: string;
  transactions: any;
  chainId: number;
  gasLimit: string;
  gasPrice: string;
  gasToken?: string;
}

export enum SmartWalletConfirmation {
  Transfer = 'Transfer',
  Swap = 'Swap'
}

export interface ISmartWalletConfirmationRespMdl {
  type: SmartWalletConfirmation;
}

export interface ISmartWalletConfirmationDialogData {
  executeTx: IExecuteReqMdl;
  type: SmartWalletConfirmation;
  fees: IFee[];
  fromToken?: CrowdToken | undefined;
  toToken?: CrowdToken | undefined;
  amount: string | undefined;
  amountOut?: string;
  destination?: string;
}

export interface IGetTransferRespMdl {
  request: {
    transactions: [
      {
        to: string;
        data: string;
        value: string;
      }
    ];
    chainId: number;
    gasLimit: string;
    gasPrice: string;
  };
  fees: IFee[];
}
