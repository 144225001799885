import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  Dexchanges,
  NetworksById,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import { Builder } from 'builder-pattern';
import qs from 'qs';
import { throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
import { NotFoundException } from '../exception/not-found.exception';
import { ServerException } from '../exception/server.exception';
import { SearchParamModel } from '../model/search-param.model';
import { Web3Service } from './web3.service';
import { formatNumber, NumberType } from '@uniswap/conedison/format';
import { CrossAndSameChainEstimateTrade, IGetSwapReqMdl } from '../model';
import { EstimateTrade } from '../model/estimate-trade.model';
import { ConnectWalletService } from './connect-wallet.service';

const baseUrl = environment.SWAP_BASEURL || '';
const API_KEY = environment.API_KEY;

@Injectable()
export class CrowdSwapService {
  public static SEARCH_TIMEOUT = 1350000;
  public static SEARCH_NOTIFIER_TIME = 1000;
  public static CROSS_CHAIN_SLIPPAGE =
    commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE;
  public static MAX_ETF_INVEST_SLIPPAGE = 5;
  public static MIN_ETF_INVEST_SLIPPAGE = 0.5;
  public static SLIPPAGE = '0.5';
  public static OPPORTUNITY_SLIPPAGE = '3';
  public static ETF_WITHDRAW_SLIPPAGE = '1';
  public static DEADLINE = '30';
  public static INTERVAL_REFRESH_TIME = 30; // In seconds
  public static ASSET_INTERVAL_REFRESH_TIME = 600; // 10 minutes
  public static END_REFRESH_TIME = 120000; // 2 minutes in millisecond

  public static AGGREGATOR_FEE = '0.1';
  public static CURVE_FEE = '0.4';
  public static CROSS_CHAIN_ADAPTER_FEE = '0.1';

  constructor(
    private http: HttpClient,
    private w3Service: Web3Service,
    private priceService: PriceService,
    protected connectWalletService: ConnectWalletService
  ) {}

  public getSwapEstimate(
    swap: string,
    fromToken: CrowdToken,
    toToken: CrowdToken,
    amount: string,
    gasPrice: string,
    networkCoinInUSDT: string,
    slippage: string,
    deadline: string,
    reqTimeout: number
  ) {
    try {
      const url = `${baseUrl}/api/v1/swap/estimate/${swap || 'Crowdswap'}`;

      const data = {
        fromToken: fromToken,
        toToken: toToken,
        amount: amount,
        networkCoinPrice: networkCoinInUSDT,
        slippage: slippage,
        deadline: deadline,
        gasPrice: gasPrice
      };

      let params = new HttpParams({ fromString: qs.stringify(data) });

      return this.http
        .get(url, {
          headers: { 'x-api-key': API_KEY },
          params: params,
          observe: 'response'
        })
        .pipe(
          timeout(reqTimeout),
          catchError((err) => {
            console.log(err);
            return throwError(err);
          }),
          map((response: any) => {
            return {
              body: response.body,
              xCorrelationId: response.headers.get('x-correlation-id')
            };
          })
        );
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get swap estimate');
  }

  public getAllSwapEstimation(searchParam: SearchParamModel) {
    const amountIn = this.priceService.getAmountIn(
      '',
      searchParam.swapValue,
      searchParam.fromToken.decimals
    );

    const reqTimeout: number = CrowdSwapService.SEARCH_TIMEOUT;
    const url = `${baseUrl}/api/v1/swap/estimate-all`;

    const data = {
      fromToken: searchParam.fromToken,
      toToken: searchParam.toToken,
      amount: amountIn.toString(),
      networkCoinPrice: searchParam.networkCoinInUSDT,
      slippage: searchParam.slippage,
      deadline: searchParam.deadline,
      gasPrice: searchParam.gasPrice,
      userAddress: searchParam.userAddress
    };
    let params = new HttpParams({ fromString: qs.stringify(data) });

    return this.http
      .get(url, {
        headers: { 'x-api-key': API_KEY },
        params: params,
        observe: 'response'
      })
      .pipe(
        timeout(reqTimeout),
        catchError(this._handleApiCallError),
        map((response: any) => {
          const xCorrelationId = response.headers.get('x-correlation-id');

          const successfulEstimationsList =
            response.body.successfulEstimationsList;

          let estimateTradeList: CrossAndSameChainEstimateTrade[] =
            successfulEstimationsList.map((estimation) => {
              // Create a new instance of tokenIn/Out to prevent overriding price by other estimation items
              const tokenIn = <CrowdToken>{ ...searchParam.fromToken };
              const tokenOut = <CrowdToken>{ ...searchParam.toToken };
              if (estimation.fromTokenPrice) {
                tokenIn.price = estimation.fromTokenPrice;
              }
              if (estimation.toTokenPrice) {
                tokenOut.price = estimation.toTokenPrice;
              }

              return Builder<EstimateTrade>()
                .dexName(estimation.swapName)
                .amountIn(amountIn)
                .fromToken(tokenIn)
                .toToken(tokenOut)
                .toTokenPriceToDisplay(
                  formatNumber(
                    estimation.toTokenPrice,
                    NumberType.FiatTokenPrice
                  )
                )
                .correlationId(xCorrelationId)
                .dex(Dexchanges[estimation.swapName])
                .amountOut(estimation.amountOut)
                .minAmountOut(estimation.minAmountOut)
                .swapFee(estimation.swapFee)
                .swapTx(estimation.swapTx)
                .networkFee(estimation.networkFeeInUSDT)
                .crowdswapFee(estimation.crowdswapFeeInUSDT)
                .pricePerToken(estimation.pricePerToken)
                .cost(estimation.cost)
                .crowdswapFeePercentage(estimation.crowdswapFeePercentage)
                .currentDexFeePercentage(estimation.currentDexFeePercentage)
                .delegatedDex(estimation.delegatedDex)
                .routes(estimation.routes)
                .swapFeeInUSDT(estimation.swapFeeInUSDT)
                .swapFeeInUSDTToDisplay(
                  estimation.swapFeeInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.swapFeeInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .networkFeeInUSDT(estimation.networkFeeInUSDT)
                .networkFeeInUSDTToDisplay(
                  estimation.networkFeeInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.networkFeeInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .crowdswapFeeInUSDT(estimation.crowdswapFeeInUSDT)
                .crowdswapFeeInUSDTToDisplay(
                  estimation.crowdswapFeeInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        parseFloat(estimation.crowdswapFeeInUSDT),
                        NumberType.FiatTokenPrice
                      )
                )
                .totalFeeInUSDT(estimation.totalFeeInUSDT)
                .totalFeeInUSDTToDisplay(
                  estimation.totalFeeInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.totalFeeInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .totalPaidInUSDT(estimation.totalPaidInUSDT)
                .totalPaidInUSDTToDisplay(
                  estimation.totalPaidInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.totalPaidInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .totalIncomeInUSDT(estimation.totalIncomeInUSDT)
                .amountOutInUSDT(estimation.amountOutInUSDT)
                .amountOutInUSDTToDisplay(
                  estimation.amountOutInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.amountOutInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .amountInInUSDT(estimation.amountInInUSDT)
                .amountInInUSDTToDisplay(
                  estimation.amountInInUSDT.startsWith('N')
                    ? 'Unknown'
                    : formatNumber(
                        estimation.amountInInUSDT,
                        NumberType.FiatTokenPrice
                      )
                )
                .amountOutPerAmountInRatio(
                  Conversion.adjustFraction(
                    estimation.amountOutPerAmountInRatio,
                    6
                  )
                )
                .amountInPerAmountOutRatio(
                  Conversion.adjustFraction(
                    estimation.amountInPerAmountOutRatio,
                    6
                  )
                )
                .priceImpact(
                  estimation.priceImpact
                    ? Conversion.adjustFraction(estimation.priceImpact, 6)
                    : ''
                )
                .loading(false)
                .firstDex(estimation.firstDex)
                .secondDex(estimation.secondDex)
                .middleToken(
                  estimation.middleToken
                    ? TokensHolder.TokenListBySymbol[
                        NetworksById[searchParam.fromToken.chainId]
                      ][estimation.middleToken]
                    : undefined
                )
                .amountInFirstSwap(
                  estimation.amountInFirstSwap
                    ? estimation.amountInFirstSwap
                    : null
                )
                .amountInSecondSwap(
                  estimation.amountInSecondSwap
                    ? estimation.amountInSecondSwap
                    : null
                )
                .delegatedDex(estimation.delegatedDex)
                .approveAddress(estimation.approveAddress)
                .build();
            });

          return estimateTradeList;
        })
      );
  }

  estimationResult(estimation: any, searchParam, xCorrelationId) {
    // Create a new instance of tokenIn/Out to prevent overriding price by other estimation items
    const tokenIn = <CrowdToken>{ ...searchParam.fromToken };
    const tokenOut = <CrowdToken>{ ...searchParam.toToken };
    if (estimation.fromTokenPrice) {
      tokenIn.price = estimation.fromTokenPrice;
    }
    if (estimation.toTokenPrice) {
      tokenOut.price = estimation.toTokenPrice;
    }
    const amountIn = this.priceService.getAmountIn(
      '',
      searchParam.swapValue,
      searchParam.fromToken.decimals
    );

    const result = Builder<EstimateTrade>()
      .dexName(estimation.swapName)
      .amountIn(amountIn)
      .fromToken(tokenIn)
      .toToken(tokenOut)
      .toTokenPriceToDisplay(
        formatNumber(estimation.toTokenPrice, NumberType.FiatTokenPrice)
      )
      .correlationId(xCorrelationId)
      .dex(Dexchanges[estimation.swapName])
      .amountOut(estimation.amountOut)
      .minAmountOut(estimation.minAmountOut)
      .swapFee(estimation.swapFee)
      .swapTx(estimation.swapTx)
      .networkFee(estimation.networkFeeInUSDT)
      .crowdswapFee(estimation.crowdswapFeeInUSDT)
      .pricePerToken(estimation.pricePerToken)
      .cost(estimation.cost)
      .crowdswapFeePercentage(estimation.crowdswapFeePercentage)
      .currentDexFeePercentage(estimation.currentDexFeePercentage)
      .delegatedDex(estimation.delegatedDex)
      .routes(estimation.routes)
      .swapFeeInUSDT(estimation.swapFeeInUSDT)
      .swapFeeInUSDTToDisplay(
        estimation.swapFeeInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.swapFeeInUSDT, NumberType.FiatTokenPrice)
      )
      .networkFeeInUSDT(estimation.networkFeeInUSDT)
      .networkFeeInUSDTToDisplay(
        estimation.networkFeeInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.networkFeeInUSDT, NumberType.FiatTokenPrice)
      )
      .crowdswapFeeInUSDT(estimation.crowdswapFeeInUSDT)
      .crowdswapFeeInUSDTToDisplay(
        estimation.crowdswapFeeInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(
              parseFloat(estimation.crowdswapFeeInUSDT),
              NumberType.FiatTokenPrice
            )
      )
      .totalFeeInUSDT(estimation.totalFeeInUSDT)
      .totalFeeInUSDTToDisplay(
        estimation.totalFeeInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.totalFeeInUSDT, NumberType.FiatTokenPrice)
      )
      .totalPaidInUSDT(estimation.totalPaidInUSDT)
      .totalPaidInUSDTToDisplay(
        estimation.totalPaidInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.totalPaidInUSDT, NumberType.FiatTokenPrice)
      )
      .totalIncomeInUSDT(estimation.totalIncomeInUSDT)
      .amountOutInUSDT(estimation.amountOutInUSDT)
      .amountOutInUSDTToDisplay(
        estimation.amountOutInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.amountOutInUSDT, NumberType.FiatTokenPrice)
      )
      .amountInInUSDT(estimation.amountInInUSDT)
      .amountInInUSDTToDisplay(
        estimation.amountInInUSDT.startsWith('N')
          ? 'Unknown'
          : formatNumber(estimation.amountInInUSDT, NumberType.FiatTokenPrice)
      )
      .amountOutPerAmountInRatio(
        Conversion.adjustFraction(estimation.amountOutPerAmountInRatio, 6)
      )
      .amountInPerAmountOutRatio(
        Conversion.adjustFraction(estimation.amountInPerAmountOutRatio, 6)
      )
      .priceImpact(
        estimation.priceImpact
          ? Conversion.adjustFraction(estimation.priceImpact, 6)
          : ''
      )
      .loading(false)
      .firstDex(estimation.firstDex)
      .secondDex(estimation.secondDex)
      .middleToken(
        estimation.middleToken
          ? TokensHolder.TokenListBySymbol[
              NetworksById[searchParam.fromToken.chainId]
            ][estimation.middleToken]
          : undefined
      )
      .amountInFirstSwap(
        estimation.amountInFirstSwap ? estimation.amountInFirstSwap : null
      )
      .amountInSecondSwap(
        estimation.amountInSecondSwap ? estimation.amountInSecondSwap : null
      )
      .delegatedDex(estimation.delegatedDex)
      .approveAddress(estimation.approveAddress)
      .build();
    return result;
  }

  public async getSwapTransaction(
    dexName: string,
    fromToken: CrowdToken,
    toToken: CrowdToken,
    amount: string,
    userAddress: string,
    delegatedDex: string,
    slippage: string,
    deadline: string,
    receiverAddress: string,
    checkValidation: boolean
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/swap/${dexName}`;
      const data = {
        network: this.w3Service.getCurrentChainId().toString(),
        fromToken: fromToken,
        toToken: toToken,
        amount: amount,
        userAddress: userAddress,
        slippage: slippage,
        deadline: deadline,
        receiverAddress: receiverAddress,
        checkValidation: checkValidation
      };
      delegatedDex && (data['delegatedDex'] = delegatedDex);

      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http
        .get(url, { headers: { 'x-api-key': API_KEY }, params: params })
        .toPromise();
    } catch (err: any) {
      console.log(
        `Error in SwapService, getting swap transaction for userAddress = ${userAddress}, ChainId = ${this.w3Service.getCurrentChainId()}, fromToken = ${fromToken}, dstToken = ${toToken}, amount = ${amount}, error = ${
          err.message
        }`
      );
    }
  }

  public async getCrossDexSwapTransaction(
    dexName1: string,
    dexName2: string,
    fromToken: CrowdToken,
    middleToken: CrowdToken,
    toToken: CrowdToken,
    amountInFirstSwap: string,
    amountInSecondSwap: string,
    userAddress: string,
    delegatedDex: string,
    slippage: string,
    deadline: string,
    receiverAddress: string
  ) {
    try {
      const url = `${baseUrl}/api/v1/swap/cross-dex`;
      const data = {
        network: this.w3Service.getCurrentChainId().toString(),
        fromToken: fromToken,
        middleToken: middleToken,
        toToken: toToken,
        amount: amountInFirstSwap,
        amountInFirstSwap: amountInFirstSwap,
        amountInSecondSwap: amountInSecondSwap,
        userAddress: userAddress,
        slippage: slippage,
        deadline: deadline,
        firstSwapName: dexName1,
        secondSwapName: dexName2,
        receiverAddress: receiverAddress
      };
      delegatedDex && (data['delegatedDex'] = delegatedDex);

      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http
        .get(url, { headers: { 'x-api-key': API_KEY }, params: params })
        .toPromise();
    } catch (err: any) {
      console.log(
        `Error in SwapService, getting swap transaction for userAddress = ${userAddress}, ChainId = ${this.w3Service.getCurrentChainId()}, fromToken = ${fromToken}, dstToken = ${toToken}, amount = ${amountInFirstSwap}, error = ${
          err.message
        }`
      );
    }
  }

  private _handleApiCallError(error: HttpErrorResponse) {
    if (error.status === HttpStatusCode.NotFound) {
      return throwError(new NotFoundException(error.message));
    }
    if (500 <= error.status && error.status <= 599) {
      return throwError(new ServerException(error.message));
    }
    return throwError(error);
  }
}
