import { Web3Provider } from '@ethersproject/providers';
import { isAddress } from '@ethersproject/address';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { NetworksService } from './networks.service';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  Networks,
  NetworksByName,
  TokensHolder,
  ERC20,
  MULTICALL,
  GasPriceHolder
} from '@crowdswap/constant';
import { ETH } from '@crowdswap/sdk';
import { ERC20Service } from './erc20.service';
import { MultiCall } from '@indexed-finance/multicall';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { WalletNetworkChangRejectedException } from '../exception/wallet-network-chang-rejected.exception';
import { BigNumber, ethers } from 'ethers';
import { CurrentNetwork } from '../model';
import { match } from 'ts-pattern';
import {
  Web3Modal,
  createWeb3Modal,
  defaultWagmiConfig
} from '@web3modal/wagmi';
import {
  arbitrum,
  mainnet,
  polygon,
  avalanche,
  bsc,
  zkSync,
  optimism,
  lineaTestnet,
  base,
  defichainEvm,
  rootstock
} from 'viem/chains';
import {
  getChainId,
  getAccount,
  getBalance,
  Config,
  watchAccount,
  watchChainId,
  switchChain,
  sendTransaction as wagmiSendTransaction,
  signMessage as wagmiSignMessage,
  waitForTransactionReceipt,
  getFeeHistory,
  disconnect,
  getPublicClient,
  CreateConfigParameters,
  prepareTransactionRequest,
  reconnect,
  GetAccountReturnType
} from '@wagmi/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getNameById } from './wallets';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { uuidv4 } from 'authereum/dist/utils';
import * as Sentry from '@sentry/angular-ivy';
import { parseEther } from 'viem';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable()
export class Web3Service implements OnDestroy {
  private web3Modal: Web3Modal | undefined;
  private provider?: any;
  private address?: string;
  private _walletNetworkChangeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  private _currentNetworkChangeSubject: BehaviorSubject<CurrentNetwork> =
    new BehaviorSubject<CurrentNetwork>(new CurrentNetwork(-2));
  private _wrongNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _mismatchNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _walletConnectionChangeSubject = new Subject<boolean>();
  private _pendingChangeSubject = new Subject();
  private _accountChangeSubject = new Subject<string>();
  private _liquidityChangeSubject = new Subject();
  private _assetChangeSubject: Subject<boolean> = new Subject<boolean>();
  private providerRequest: any;
  private _isWalletConnected?: boolean;
  private walletType: string | undefined = '';
  private config: Config | undefined;
  private _networkSpec = {
    [Networks.MAINNET]: {
      title: 'Ethereum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon.png',
      scanUrl: 'etherscan.io',
      scanName: 'Etherscan'
    },
    [Networks.ROPSTEN]: {
      title: 'Ethereum (Ropsten)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'ropsten.etherscan.io',
      scanName: 'Ropstenscan'
    },
    [Networks.RINKEBY]: {
      title: 'Ethereum (Rinkeby)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'rinkeby.etherscan.io',
      scanName: 'Rinkebyscan'
    },
    [Networks.GOERLI]: {
      title: 'Ethereum (Goerli)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'goerli.etherscan.io',
      scanName: 'Goerliscan'
    },
    [Networks.KOVAN]: {
      title: 'Ethereum (Kovan)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'kovan.etherscan.io',
      scanName: 'Kovanscan'
    },
    [Networks.BSCMAIN]: {
      title: 'BSC',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/Binance-icon.png',
      scanUrl: 'bscscan.com',
      scanName: 'Bscscan'
    },
    [Networks.BSCTEST]: {
      title: 'BSC (Testnet)',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/Binance-icon0.png',
      scanUrl: 'testnet.bscscan.com',
      scanName: 'Bsctestscan'
    },
    [Networks.POLYGON_MAINNET]: {
      title: 'Polygon',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/Polygon-icon.png',
      scanUrl: 'polygonscan.com',
      scanName: 'Polygonscan'
    },
    [Networks.POLYGON_MUMBAI]: {
      title: 'Matic Mumbai',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/Polygon-icon0.png',
      scanUrl: 'mumbai.polygonscan.com',
      scanName: 'Mumbaiscan'
    },
    [Networks.AVALANCHE]: {
      title: 'Avalanche',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/Avalanche-icon.svg',
      scanUrl: 'snowtrace.io',
      scanName: 'Snowtrace'
    },
    [Networks.AVALANCHE_FUJI]: {
      title: 'Fuji',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/Avalanche-icon0.png',
      scanUrl: 'testnet.snowtrace.io',
      scanName: 'Snowtrace'
    },
    [Networks.APEX]: {
      title: 'APEX',
      coin: 'OMNIA',
      coinIcon: 'assets/media/icons/Apex-icon.png',
      scanUrl: 'scan.theapexchain.org',
      scanName: 'theapexchain'
    },
    [Networks.ARBITRUM]: {
      title: 'Arbitrum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Arbitrum-icon.svg',
      scanUrl: 'arbiscan.io',
      scanName: 'Arbiscan'
    },
    [Networks.ZKSYNC]: {
      title: 'zkSync',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/zksync.svg',
      scanUrl: 'explorer.zksync.io',
      scanName: 'zkScan'
    },
    [Networks.ZKSYNCTEST]: {
      title: 'ZkSync Test',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/zksync.svg',
      scanUrl: 'goerli.explorer.zksync.io',
      scanName: 'zkScan'
    },
    [Networks.OPTIMISM]: {
      title: 'Optimism',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/optimism.png',
      scanUrl: 'optimistic.etherscan.io',
      scanName: 'optiscan'
    },
    [Networks.LINEA]: {
      title: 'Linea',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/linea.png',
      scanUrl: 'lineascan.build',
      scanName: 'lineascan'
    },
    [Networks.BASE]: {
      title: 'Base',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/base.png',
      scanUrl: 'basescan.org',
      scanName: 'basescan'
    },
    [Networks.DEFI]: {
      title: 'DeFiMetaChain',
      coin: 'DFI',
      coinIcon: 'assets/media/icons/defi.png',
      scanUrl: 'mainnet-dmc.mydefichain.com',
      scanName: 'DefiScan'
    },
    [Networks.ROOTSTOCK]: {
      title: 'Rootstock',
      coin: 'RBTC',
      coinIcon: 'assets/media/icons/rootstock.png',
      scanUrl: 'rootstock.blockscout.com',
      scanName: 'RootstockScan'
    }
  };
  private unwatchAccount: (() => void) | undefined;
  private unwatchNetwork: (() => void) | undefined;
  public usingCrowdWallet?: boolean = false;

  constructor(
    private zone: NgZone,
    private networksService: NetworksService,
    private $gtmService: GoogleTagManagerService
  ) {
    this.initModal().then(() => {
      // if (!this.isConnected()) {
      //   this.openModal();
      // }
    });
  }

  async initModal(address: string = ''): Promise<void> {
    try {
      const darkMode = localStorage.getItem('darkMode');

      const chains = [
        mainnet,
        bsc,
        polygon,
        avalanche,
        arbitrum,
        zkSync,
        optimism,
        lineaTestnet,
        base,
        defichainEvm,
        rootstock
      ] as const;
      const projectId = environment.walletConnectProjectId;
      const metadata = {
        name: 'CrowdSwap.org',
        description:
          'Decentralized crypto exchange of future. With advanced features and seamless trading',
        url: 'https://app.crowdswap.org', // origin must match your domain & subdomain
        icons: ['https://app.crowdswap.org/assets/media/logo/title-logo.svg']
      };

      const wagmiOptions: Partial<CreateConfigParameters> = {};
      this.config = defaultWagmiConfig({
        chains,
        projectId,
        metadata,
        enableCoinbase: true,
        enableWalletConnect: true,
        ...wagmiOptions // Optional - Override createConfig parameters
      });

      await reconnect(this.config);

      this.web3Modal = createWeb3Modal({
        wagmiConfig: this.config,
        projectId,
        enableAnalytics: true, // Optional - to have Analytics on WalletConnect Cloud's dashboard
        enableOnramp: true, // Optional - enable coinbase fiat on ramp
        themeMode: darkMode === 'true' ? 'dark' : 'light'
      });
      // const { publicClient, webSocketPublicClient } = configureChains(chains, [
      //   w3mProvider({ projectId })
      // ]);
      // const wagmiConfig = createConfig({
      //   autoConnect: true,
      //   connectors: w3mConnectors({ projectId, chains }),
      //   publicClient,
      //   webSocketPublicClient
      // });
      // const ethereumClient = new EthereumClient(wagmiConfig, chains);
      // this.web3Modal = new Web3Modal({ projectId }, ethereumClient);
      // this.web3Modal.setTheme({
      //   themeMode: darkMode === 'true' ? 'dark' : 'light'
      // });

      let tag = {};
      if (!this.usingCrowdWallet && this.config) {
        const account = getAccount(this.config);
        let tag = {};
        if ((account && account.address) || address) {
          this.initProvider();
          tag = {
            event: 'wallet_logged_in',
            type_wallet: 'wallet_connect',
            userSessionId: 'Is not available ',
            item_id: address || account.address
          };
          Sentry.setUser({ username: address || account.address });
          this.storeWalletEvents(tag);
        }
      } else {
        if (address) {
          this.initProvider();
          tag = {
            event: 'wallet_logged_in',
            type_wallet: 'wallet_connect',
            userSessionId: 'Is not available ',
            item_id: address
          };
          Sentry.setUser({ username: address });
          this.storeWalletEvents(tag);
        }
      }
      if (!this.usingCrowdWallet && this.web3Modal) {
        this.web3Modal.subscribeEvents(async (event) => {
          console.log('web3Modal.subscribeEvents: ' + JSON.stringify(event));
          if (event.data?.event === 'SELECT_WALLET') {
            this.walletType =
              event.data.properties.name + '-' + event.data.properties.platform;
            tag = {
              event: 'wallet_selected_type',
              type_wallet: this.walletType
            };
            this.storeWalletEvents(tag);
          }
          if (event.data.event == 'MODAL_CLOSE') {
            if (event.data.properties.connected) {
              this.initProvider();
              tag = {
                event: 'login',
                item_id: this.getWalletAddress(),
                type_wallet: this.walletType
              };
              Sentry.setUser({ username: this.getWalletAddress() });
              this.storeWalletEvents(tag);
            }
          }
          if (event.data.event === 'CONNECT_SUCCESS') {
            this.initProvider();
            tag = {
              event: 'wallet_login',
              item_id: this.getWalletAddress(),
              type_wallet: this.walletType
            };
            Sentry.setUser({ username: this.getWalletAddress() });
            this.storeWalletEvents(tag);
          } else if (event.data.event === 'DISCONNECT_SUCCESS') {
            await this.zone.run(async () => {
              this.setWalletConnected(false);
              await this.clearCachedProvider();
            });
            Sentry.setUser({ username: undefined });
          }
        });
      }
    } catch (e) {
      console.error(e);
      if (!this.usingCrowdWallet) {
        await this.pushWalletConnectErrorToServer(e);
      }
    }
  }

  async openModal(): Promise<void> {
    try {
      this.usingCrowdWallet = false;
      await this.web3Modal?.open();
    } catch (e) {
      console.error(e);
      await this.pushWalletConnectErrorToServer(e);
    }
  }

  openCrowdWallet(address: string) {
    try {
      this.usingCrowdWallet = true;
      this.initProvider(address);
      Sentry.setUser({ username: address });
    } catch (e) {
      console.error(e);
    }
  }

  async pushWalletConnectErrorToServer(errorItem) {
    try {
      const logItem = {
        level: 'error',
        datetime: new Date(),
        value: errorItem?.stack ?? errorItem
      };
      await this.pushWalletEventsToServer(logItem);
    } catch (e) {
      console.error(e);
    }
  }

  async pushWalletEventsToServer(logItem) {
    try {
      await fetch(`${environment.AFFILIATE_BASEURL}/api/v1/log/_doc`, {
        method: 'POST',
        body: JSON.stringify({
          wallet: this.GetMaskedWalletAddress(),
          agent: window.navigator.userAgent,
          items: [logItem]
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  GetMaskedWalletAddress(): string | undefined {
    let wallet;
    if (this.isConnected()) {
      wallet = this.getWalletAddress();
      if (wallet) {
        wallet =
          wallet.substring(0, wallet.length / 3) +
          '********' +
          wallet.substring(wallet.length / 3 + 8, wallet.length);
      }
    }
    return wallet;
  }

  async clearCachedProvider() {
    this.setWalletConnected(false);
    this.provider = undefined;
    if (!this.usingCrowdWallet) {
      this.unwatchAccount && this.unwatchAccount();
      this.unwatchNetwork && this.unwatchNetwork();
      await disconnect(this.config!);
    }
  }

  isConnected() {
    return this._isWalletConnected;
  }

  getWalletChainId(): number {
    return this._walletNetworkChangeSubject.getValue();
  }

  getCurrentChainId(): number {
    return this._currentNetworkChangeSubject.getValue().chainId;
  }

  getCurrentMainChainId(): number {
    const chainId: number = this.getCurrentChainId();
    return this.getCurrentMainChain(chainId.toString());
  }

  getCurrentMainChain(chainId: string): number {
    if (chainId == '-2') {
      chainId = NetworksByName.POLYGON_MAINNET.toString();
    }

    if (chainId == '56' || chainId == '97') {
      return NetworksByName.BSCMAIN;
    } else if (chainId == '137' || chainId == '80001') {
      return NetworksByName.POLYGON_MAINNET;
    } else if (chainId == '43114' || chainId == '43113') {
      return NetworksByName.AVALANCHE;
    } else if (chainId == '42161') {
      return NetworksByName.ARBITRUM;
    } else if (chainId == '324') {
      return NetworksByName.ZKSYNC;
    } else if (chainId == '10') {
      return NetworksByName.OPTIMISM;
    } else if (chainId == '59144') {
      return NetworksByName.LINEA;
    } else if (chainId == '8453') {
      return NetworksByName.BASE;
    } else if (chainId == '1130') {
      return NetworksByName.DEFI;
    } else if (chainId == '30') {
      return NetworksByName.ROOTSTOCK;
    } else {
      return NetworksByName.MAINNET;
    }
  }

  getWalletAddress(): string | undefined {
    return this.address;
  }

  getProviderName() {
    return this.config?.connectors?.[0]?.name;
  }

  async getBalance(
    token?: CrowdToken,
    provider: Web3Provider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!this.address || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(this.address);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        this.address,
        token.address
      );
    }
  }

  async getBalanceByUserAddress(
    token: CrowdToken,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!userAddress || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(userAddress);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        userAddress,
        token.address
      );
    }
  }

  async getAllBalances(tokens: any): Promise<any> {
    let inUseProvider: Web3Provider | undefined = undefined;
    if (!this.address || !this.isConnected()) {
      return undefined;
    }
    if (!tokens || tokens.length === 0) {
      return undefined;
    }
    const chainId: number = (<CrowdToken>tokens[0]).chainId;
    inUseProvider = this.networksService
      .getNetworkProvider(chainId)
      .getProvider();

    if (!inUseProvider) {
      return undefined;
    }

    try {
      const walletAddress = this.getWalletAddress();
      if (!walletAddress) {
        return;
      }
      const chainId = tokens[0].chainId;
      const ethAddress = ETH[chainId].address.toLowerCase();
      let ethBalance;
      let tokenBalances = {};

      if (NetworksByName.ZKSYNC === chainId) {
        const noneEthTokens: any[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
          } else {
            noneEthTokens.push(token);
          }
        }

        const populatedTxList: [string, string | undefined][] = [];
        const networkService = this.networksService.getNetworkProvider(chainId);
        for (const token of noneEthTokens) {
          const erc20Contract = networkService.getContract(
            token.address,
            ERC20
          );
          const populatedTx = await erc20Contract.populateTransaction.balanceOf(
            walletAddress
          );
          populatedTxList.push([token.address, populatedTx.data]);
        }
        const multiCallContract = networkService.getContract(
          '0x47898B2C52C957663aE9AB46922dCec150a2272c',
          MULTICALL
        );
        const result = await multiCallContract.callStatic.aggregate(
          populatedTxList
        );
        const returnData = result.returnData;
        for (let i = 0; i < noneEthTokens.length; i++) {
          tokenBalances[noneEthTokens[i].address.toLowerCase()] = returnData[i];
        }
      } else {
        const addressList: string[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
            continue;
          }
          addressList.push(token.address.toLowerCase());
        }
        const multi = new MultiCall(inUseProvider);
        const [blockNumber, balances] = await multi.getBalances(
          addressList,
          this.getWalletAddress()!
        );
        tokenBalances = balances;
      }
      return { ...tokenBalances, [ethAddress]: ethBalance };
    } catch (e) {
      console.log(e);
    }
  }

  async getAllowance(
    dex: string,
    delegatedDex: string,
    tokenAddress: string,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }

    let spenderAddress = await this.fetchSpenderAddress(
      dex,
      delegatedDex,
      inUseProvider.network.chainId
    );

    return this.getAllowanceBySpender(
      spenderAddress,
      tokenAddress,
      userAddress,
      provider
    );
  }

  async getAllowanceBySpender(
    spenderAddress: string,
    tokenAddress: string,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    return new ERC20Service(inUseProvider).getAllowance(
      tokenAddress,
      spenderAddress,
      userAddress
    );
  }

  async getNFTAllowanceBySpender(
    spenderAddress: string,
    tokenAddress: string,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    try {
      return new ERC20Service(inUseProvider).getNFTAllowance(
        tokenAddress,
        spenderAddress,
        userAddress
      );
    } catch (e) {
      console.error(e);
    }
  }

  async getApprovalTransaction(
    dex: string,
    delegatedDex: string,
    tokenAddress: string,
    amount: string
  ): Promise<any> {
    if (!this.address || !this.web3Provider) {
      return undefined;
    }
    let spenderAddress = await this.fetchSpenderAddress(
      dex,
      delegatedDex,
      this.web3Provider.network.chainId
    );
    return this.getApprovalTransactionBySpender(
      spenderAddress,
      tokenAddress,
      amount
    );
  }

  async getApprovalTransactionBySpender(
    spenderAddress: string,
    tokenAddress: string,
    amount: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    return new ERC20Service(inUseProvider).getApproveTransaction(
      tokenAddress,
      spenderAddress,
      amount
    );
  }

  async getNFTApprovalTransactionBySpender(
    spenderAddress: string,
    tokenAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    try {
      return new ERC20Service(inUseProvider).getNFTApproveTransaction(
        tokenAddress,
        spenderAddress
      );
    } catch (e) {
      console.error(e);
    }
  }

  private async fetchSpenderAddress(
    dex: string,
    delegatedDex: string,
    chainId: number
  ): Promise<string> {
    let targetDex;
    if (Dexchanges[dex].isCrowdSwapContractEnabled) {
      targetDex = Dexchanges.CrowdswapAggregatorV3.name;
    } else {
      if (dex === Dexchanges.CrowdswapAggregatorV3.name) {
        targetDex = delegatedDex;
      } else {
        targetDex = dex;
      }
    }
    const spenderAddress =
      Dexchanges[targetDex].networks[chainId]?.contractAddress ??
      Dexchanges[targetDex].networks[chainId][0]?.contractAddress;
    if (!spenderAddress) {
      return Promise.reject(
        `Could not find address for spender ${targetDex} on chainId ${chainId}`
      );
    }
    return spenderAddress;
  }

  async getSymbol(address: string): Promise<string | undefined> {
    if (!this.provider) {
      return undefined;
    }
    return new ERC20Service(this.web3Provider).getSymbol(address);
  }

  async getGasPrice(
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const usedProvider = provider || this.web3Provider;
    const originalGasPrice =
      GasPriceHolder.Instance.getGasPrice(usedProvider.network.chainId) ??
      (await usedProvider.getGasPrice());
    let additionalPercentage = 0;
    switch (usedProvider.network.chainId) {
      case Networks.MAINNET:
        additionalPercentage = 18;
        break;
      case Networks.BSCMAIN:
        additionalPercentage = 0;
        break;
      case Networks.POLYGON_MAINNET:
        additionalPercentage = 40;
        break;
      case Networks.ARBITRUM:
        additionalPercentage = 0;
        break;
      case Networks.AVALANCHE:
        additionalPercentage = 5;
        break;
      case Networks.ZKSYNC:
        additionalPercentage = 10;
        break;
      case Networks.OPTIMISM:
        additionalPercentage = 10;
        break;
      case Networks.LINEA:
        additionalPercentage = 10;
        break;
      case Networks.BASE:
        additionalPercentage = 10;
        break;
      case Networks.DEFI:
        additionalPercentage = 10;
        break;
      case Networks.ROOTSTOCK:
        additionalPercentage = 10;
        break;
    }
    return originalGasPrice.mul(100 + additionalPercentage).div(100);
  }

  async getCCGasPrice(): Promise<any> {
    if (!this.provider) {
      return;
    }
    return this.provider.getGasPrice();
  }

  getNetworkName(chainId: number): string {
    return (<any>Networks)[chainId];
  }

  getScanUrl(chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();
    return `https://${this.networkSpec[chainId].scanUrl}/`;
  }

  getScanTransactionUrl(hash: string, chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();
    return `https://${this.networkSpec[chainId].scanUrl}/tx/${hash}`;
  }

  getScanAddressUrl(address: string, chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();

    if (!chainId) {
      return '';
    }

    return `https://${this.networkSpec[chainId].scanUrl}/address/${address}`;
  }

  getNetworkScanName() {
    const chainId = this.getCurrentChainId();
    return this.networkSpec[chainId].scanName;
  }

  async sendTransaction(data: any) {
    return this.provider?.getSigner().sendTransaction(data);
  }

  async signMessage(message: string) {
    return this.provider?.getSigner().signMessage(message);
  }

  async getNonce(): Promise<number | undefined> {
    return this.provider?.getSigner().getTransactionCount();
  }

  async waitForTransaction(
    hash: string,
    confirmation: number,
    provider: Web3Provider | undefined = undefined
  ) {
    return (provider || this.provider)?.waitForTransaction(hash, confirmation);
  }

  get walletNetworkChangeSubject() {
    return this._walletNetworkChangeSubject;
  }

  get wrongNetworkSubject(): Subject<boolean> {
    return this._wrongNetworkSubject;
  }

  get mismatchNetworkSubject(): Subject<boolean> {
    return this._mismatchNetworkSubject;
  }

  get currentNetworkChangeSubject(): BehaviorSubject<CurrentNetwork> {
    return this._currentNetworkChangeSubject;
  }

  get walletConnectionChangeSubject() {
    return this._walletConnectionChangeSubject;
  }

  get pendingChangeSubject() {
    return this._pendingChangeSubject;
  }

  get accountChangeSubject() {
    return this._accountChangeSubject;
  }

  get assetChangeSubject(): Subject<boolean> {
    return this._assetChangeSubject;
  }

  get networkSpec() {
    return this._networkSpec;
  }

  get web3Provider() {
    return this.getNetworkProvider(this.getWalletChainId());
  }

  async changeNetwork(chainId: number) {
    try {
      await switchChain(this.config!, { chainId });

      if (!this.providerRequest) {
        return;
      }
      await this.providerRequest({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3Service.toHex(chainId) }]
      });
    } catch (switchError: any) {
      const errorCode =
        switchError.code ||
        this.transformProviderErrorMessageToErrorCode(switchError.toString());
      switch (errorCode) {
        // This error code indicates that the chain has not been added to MetaMask.
        case 4902: {
          const network = await this.networksService.getNetworkRPCUrlById(
            chainId
          );
          try {
            await this.providerRequest({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3Service.toHex(network.chainId).toString(),
                  chainName: network.chainName,
                  rpcUrls: [network.rpcUrls],
                  blockExplorerUrls: [network.blockExplorerUrls],
                  iconUrls: [network.iconUrls],
                  nativeCurrency: {
                    name: network.nativeCurrencyName,
                    symbol: network.nativeCurrencySymbol,
                    decimals: parseInt(network.nativeCurrencyDecimals)
                  }
                }
              ]
            });
          } catch (switchError: any) {
            const errorCode =
              switchError.code ||
              this.transformProviderErrorMessageToErrorCode(
                switchError.toString()
              );
            switch (errorCode) {
              case 4001:
                // This error code indicates that the user rejected the network adding request
                throw new WalletNetworkChangRejectedException(
                  'User rejects the network changing request.'
                );
            }
          }
          break;
        }
        case 4001:
          // This error code indicates that the user rejected the network changing request
          throw new WalletNetworkChangRejectedException(
            'User rejects the network changing request.'
          );
      }
    }
  }

  async addTokenToWallet(destTokenOnNetwork: any) {
    try {
      await window.ethereum
        .request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: destTokenOnNetwork.address,
              symbol: destTokenOnNetwork.symbol,
              decimals: destTokenOnNetwork.decimals
            }
          }
        })
        .then((success) => {
          if (!success) {
            throw new Error('Something went wrong.');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  private static toHex(value: number) {
    const HexCharacters = '0123456789abcdef';
    let hex = '';
    while (value) {
      hex = HexCharacters[value & 0xf] + hex;
      value = Math.floor(value / 16);
    }
    if (hex.length) {
      return '0x' + hex;
    }
    return '0x00';
  }

  ngOnDestroy(): void {
    //this.clearCachedProvider().catch(console.log);
  }

  setWalletConnected(status: boolean) {
    this._isWalletConnected = status;
    if (!status) {
      this.address = undefined;
    }
    this._walletConnectionChangeSubject.next(status);
  }

  async addBalanceToTokens(tokens: CrowdToken[]): Promise<CrowdToken[]> {
    const balances = await this.getAllBalances(tokens);
    if (!balances) {
      return tokens.map((token) => {
        token.balance = '';
        token.balanceToDisplay = '';
        return token;
      });
    }

    const result = tokens
      .map((token) => {
        if (!balances[token.address.toLowerCase()]) {
          return token;
        }
        token.balance = Conversion.convertStringFromDecimal(
          balances[token.address.toLowerCase()].toString(),
          token.decimals
        );

        token.balanceToDisplay = formatNumber(
          parseFloat(
            Conversion.convertStringFromDecimal(
              balances[token.address.toLowerCase()].toString(),
              token.decimals
            )
          ),
          NumberType.TokenNonTx
        );

        return token;
      })
      .sort(
        (item1: CrowdToken, item2: CrowdToken) =>
          +item2.balance - +item1.balance
      );
    return result;
  }

  async changeToCorrectNetwork() {
    try {
      await this.changeNetwork(this.getCurrentChainId());
    } catch (e) {
      console.log(e);
    }
  }

  isAddress(address: string) {
    return isAddress(address);
  }

  get liquidityChangeSubject() {
    return this._liquidityChangeSubject;
  }

  getNetworkProvider(chainId: number) {
    return this.networksService.getNetworkProvider(chainId).getProvider();
  }

  private transformProviderErrorMessageToErrorCode(errorMessage: string) {
    return match(errorMessage)
      .when(
        (value: string) => {
          return value.includes('wallet_addEthereumChain');
        },
        () => 4902
      )
      .when(
        (value: string) => {
          return value.includes('User rejected the request');
        },
        () => 4001
      )
      .run();
  }

  private initProvider(userAddress: string = '') {
    const chainId = this.usingCrowdWallet
      ? this.getCurrentChainId()
      : getChainId(this.config!);
    if (!chainId) {
      return;
    }

    if (!this.usingCrowdWallet) {
      const account = getAccount(this.config!);
      this.address = account.address;
      this.setWalletConnected(true);
      this._walletNetworkChangeSubject.next(chainId);
      this._accountChangeSubject.next(this.address!);
      this.unwatchAccount = watchAccount(this.config!, {
        onChange: async (account, preAccount) => {
          await this.zone.run(async () => {
            this.address = account.address;
            this._accountChangeSubject.next(this.address!);
          });
        }
      });

      this.unwatchNetwork = watchChainId(this.config!, {
        onChange: async (chainId, preChainId) => {
          await this.zone.run(async () => {
            if (!chainId) {
              return;
            }
            this.provider && (this.provider.network.chainId = chainId);
            this._walletNetworkChangeSubject.next(chainId);
          });
        }
      });

      this.provider = {
        getBalance: async (address) => {
          const result = await getBalance(this.config!, {
            address: address
          });
          return BigNumber.from(result.value);
        },
        getTokenBalance: async (address, tokenAddress) => {
          const result = await getBalance(this.config!, {
            address,
            token: tokenAddress
          });
          return BigNumber.from(result.value);
        },
        getSigner: () => {
          return {
            signMessage: (message) =>
              wagmiSignMessage(this.config!, { message }),
            sendTransaction: (txData) => {
              txData.gas = txData.gasLimit ?? txData.gas;
              return wagmiSendTransaction(this.config!, txData);
            },
            getTransactionCount: async () => {
              if (!this.address) {
                return undefined;
              }
              return getPublicClient(this.config!)?.getTransactionCount({
                address: this.address as '0x${string}'
              });
            }
          };
        },
        waitForTransaction: async (hash, confirmations) => {
          const result = await waitForTransactionReceipt(this.config!, {
            hash,
            confirmations
          });
          return { ...result, status: result.status == 'success' ? 1 : 0 };
        },
        getGasPrice: async () => {
          const gases = (
            await getFeeHistory(this.config!, {
              blockCount: 5,
              rewardPercentiles: []
            })
          ).baseFeePerGas;
          return BigNumber.from(gases[0]);
        },
        network: { chainId: chainId }
      };
    } else {
      this.address = userAddress;
      this.setWalletConnected(true);
      this._walletNetworkChangeSubject.next(137);
      this._accountChangeSubject.next(userAddress);
      this.provider = this.getNetworkProvider(137);
      return;
    }
  }

  private async storeWalletEvents(tag: any) {
    try {
      tag.date_time = new Date();
      await this.$gtmService.pushTag(tag);
    } catch (e) {}
    const newTag = tag;
    newTag.isNewUser = this.isNewUser();
    const logItem = {
      level: 'info',
      datetime: new Date(),
      value: JSON.stringify(newTag)
    };
    await this.pushWalletEventsToServer(logItem);
  }

  private isNewUser(): Boolean {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      return false;
    }
    localStorage.setItem('sessionId', uuidv4());
    return true;
  }

  async getTransferTransaction(
    token: CrowdToken,
    receiverAddress: string,
    amount: string
  ): Promise<any> {
    if (!this.address) {
      return undefined;
    }
    if (TokensHolder.isBaseToken(token.chainId, token.address)) {
      return await prepareTransactionRequest(this.config!, {
        to: receiverAddress as '0x{string}',
        value: parseEther(amount)
      });
    }

    const inUseProvider = this.networksService
      .getNetworkProvider(token.chainId)
      .getProvider();

    const contract = new ethers.Contract(token.address, ERC20, inUseProvider);

    return await contract.populateTransaction.transfer(
      receiverAddress,
      Conversion.convertStringToDecimal(amount, token.decimals),
      { from: this.address }
    );
  }
}
