<div class="d-flex flex-column">
  <div class="d-md-flex justify-content-center">
    <div
      class="d-flex p-20px p-md-60px w-full justify-center rounded-md bg-F1F1F1 dark:bg-black"
    >
      <div>
        <img class="pb-20px" src="assets/media/logo/logo.svg" alt="CrowdLogo" />
        <div class="font-20 font-openSans-bold dark:text-fff pb-20px">
          Connect Wallet
        </div>
        <div class="font-14 pb-20px dark:text-fff">
          Connect your wallet to CrowdSwap for a comprehensive all-in-one tool
          that meets all your DeFi needs
        </div>
        <div class="flex justify-content-between pb-20px">
          <div class="dark:text-fff">Don't have any wallet?</div>
          <div
            (click)="openDrawer()"
            class="text-0066FF cursor-pointer font-bold"
          >
            Create Crowd Wallet
          </div>
        </div>
        <a
          href="https://docs.crowdswap.org/"
          target="_blank"
          class="text-0066FF cursor-pointer font-bold"
          >Help</a
        >
      </div>
    </div>
    <div
      class="d-flex p-20px font-openSans-bold p-md-60px w-full justify-center"
    >
      <div class="dark:text-fff">
        <div class="font-14 pb-20px">Available Wallets</div>

        <div
          class="d-md-flex font-openSans-bold justify-content-between pb-12px"
        >
          <div
            (click)="openDrawer()"
            class="flex items-center cursor-pointer mr-5 pb-12px pb-md-0 position-relative"
          >
            <img
              src="assets/media/wallets/crowdWallet.png"
              alt="CrowdLogo"
            /><span class="ml-1">CrowdWallet</span>
            <span class="new-label">NEW</span>
          </div>
          <div
            (click)="confirmDefiWallet()"
            class="flex items-center cursor-pointer"
          >
            <img
              src="assets/media/wallets/walletConnect.png"
              alt="WalletConnect"
            /><span class="ml-1">WalletConnect</span>
          </div>
        </div>
        <!--        <div *ngIf="walletInstalled" class=" ">-->
        <!--          <div class="font-14 pb-20px">Installed</div>-->

        <!--          <div-->
        <!--            (click)="connectInstalledWallet()"-->
        <!--            class="flex items-center cursor-pointer mr-3 pb-12px pb-md-0"-->
        <!--          >-->
        <!--            <img-->
        <!--              class="rounded-full"-->
        <!--              src="{{ walletInfo?.icon }}"-->
        <!--              alt="installedWallet"-->
        <!--            /><span class="ml-1">{{ walletInfo?.name }}</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

  <nz-drawer
    [nzVisible]="visible"
    [nzHeight]="height"
    [nzClosable]="false"
    nzPlacement="top"
    [nzWrapClassName]="isDarkMode ? 'dark' : ''"
  >
    <ng-container *nzDrawerContent>
      <!--      Header-->
      <div
        class="flex justify-content-center p-3 w-full dark:bg-darkBlack dark:text-fff position-relative"
      >
        <img src="assets/media/logo/logo.svg" />
        <!--        <div-->
        <!--          class="p-2 cursor-pointer position-absolute right-5"-->
        <!--          (click)="changeDarkModeStatus()"-->
        <!--        >-->
        <!--          <button-->
        <!--            id="darkMode"-->
        <!--            class="inline-flex justify-center rounded-md float-right"-->
        <!--            type="button"-->
        <!--          >-->
        <!--            <img [hidden]="isDarkMode" src="assets/media/icons/moon.png" />-->
        <!--            <img [hidden]="!isDarkMode" src="assets/media/icons/sun.png" />-->
        <!--          </button>-->
        <!--        </div>-->
      </div>

      <!--      Body-->
      <div class="flex justify-content-center dark:bg-darkBlack w-full h-full">
        <div class="drawer-body">
          <!--          Email-->
          <div *ngIf="drawerPage === DrawerPage.Email">
            <ng-container
              *ngTemplateOutlet="Back; context: { item: DrawerPage.Close }"
            ></ng-container>
            <p class="font-32 font-openSans-bold dark:text-fff">Crowd Wallet</p>
            <p class="font-14 dark:text-fff">
              Enter your email to login/register
            </p>
            <form #form="ngForm">
              <nz-form-item class="mb-0 mt-3 pb-20px pb-md-160px w-full">
                <nz-form-label
                  class="p-0 font-12 font-bold dark:text-fff text-000"
                  [nzSpan]="24"
                  >Enter Email
                </nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  nzErrorTip="Invalid email address"
                  [nzValidateStatus]="email"
                >
                  <input
                    class="font-bold font-14 h-42px blue-color w-full placeholder-gray-300 dark:placeholder-gray-700 bg-transparent border-1px border-0066FF focus:border-0066ff1a border-radius-6px px-2"
                    nz-input
                    [(ngModel)]="user.emailAddress"
                    name="email"
                    #email="ngModel"
                    placeholder="Enter Email"
                    required
                    email
                    appAutofocus
                  />
                </nz-form-control>
              </nz-form-item>
            </form>
            <div class="pb-20px">
              <button
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
                (click)="changeDrawerPage(DrawerPage.WelcomeBack)"
                [ngClass]="{
                  disable: !user.emailAddress || form.invalid
                }"
              >
                <span class="px-1">Get Started</span>
              </button>
            </div>
            <p class="font-10 dark:text-fff">
              By creating an account in CrowdSwap you will have a defi wallet
              address, and you will able to do any transaction like other
              block-chains platforms
            </p>
          </div>

          <!--          welcome back-->
          <div
            *ngIf="drawerPage === DrawerPage.WelcomeBack && user.isUserExist"
          >
            <ng-container
              *ngTemplateOutlet="Back; context: { item: DrawerPage.Email }"
            ></ng-container>
            <p class="font-32 font-openSans-bold dark:text-fff">Welcome Back</p>
            <p class="font-14 dark:text-fff">
              Enter your password to log in to your account
            </p>
            <p class="font-14 pb-20px font-openSans-bold dark:text-fff">
              {{ user.emailAddress }}
              <span
                class="text-0066FF cursor-pointer"
                (click)="changeDrawerPage(DrawerPage.Email)"
                >Change</span
              >
            </p>

            <nz-form-item class="w-100 mb-0 mt-60px">
              <nz-form-label
                [nzSpan]="24"
                nzFor="email"
                class="p-0 font-14 text-EAE9E3 font-all-round-gothic-medium dark:text-fff"
              >
                Enter your password
              </nz-form-label>

              <nz-form-control [nzSpan]="24" nzErrorTip="Invalid Password!">
                <nz-input-group
                  [nzSuffix]="passwordSuffix"
                  class="font-bold font-14 h-42px blue-color w-full placeholder-gray-300 dark:placeholder-gray-700 bg-transparent border-1px border-0066FF focus:border-0066ff1a border-radius-6px px-2"
                >
                  <input
                    [formControl]="password"
                    [type]="passwordVisible ? 'text' : 'password'"
                    nz-input
                    class="w-100 bg-transparent border-0 shadow-none no-outline text-left text-EBF7FA dark:text-fff font-19 font-mundial-light"
                    placeholder="Enter a password"
                    id="password"
                    name="password"
                    autofocus
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <div
              class="flex items-center pt-2 pb-20px pb-md-160px font-14 font-openSans-bold text-0066FF justify-content-between pb-20px space-x-1 mb-3"
            >
              <!--              <div-->
              <!--                class="cursor-pointer"-->
              <!--                (click)="changeDrawerPage(DrawerPage.LoginWithVerification)"-->
              <!--              >-->
              <!--                Forget Password?-->
              <!--              </div>-->
              <!--              <div-->
              <!--                class="cursor-pointer"-->
              <!--                (click)="changeDrawerPage(DrawerPage.LoginWithVerification)"-->
              <!--              >-->
              <!--                Login with Verification Code-->
              <!--              </div>-->
            </div>
            <div class="pb-20px">
              <button
                (click)="confirmSmartWallet()"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
                [ngClass]="{
                  disable: password.invalid
                }"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
          </div>

          <!--          LoginWithVerification-->
          <div
            *ngIf="drawerPage === DrawerPage.LoginWithVerification"
            class="flex-column justify-content-center"
          >
            <div class="flex justify-content-center w-full">
              <img src="assets/media/icons/shield.png" />
            </div>
            <div
              class="flex text-center justify-content-center w-full dark:text-fff"
            >
              <p class="font-14">
                A verification code sent to your email address enter the code
                bellow to log in.
              </p>
            </div>
            <div
              class="flex text-center justify-content-center w-full dark:text-fff"
            >
              <p class="font-14">
                {{ user.emailAddress }}
              </p>
            </div>
            <div
              class="flex text-center space-x-2 text-0066FF font-openSans-bold justify-content-center w-full"
            >
              <p
                (click)="changeDrawerPage(DrawerPage.Email)"
                class="font-14 cursor-pointer"
              >
                Change
              </p>
              <p
                (click)="resendVerificationCode()"
                class="font-14 cursor-pointer"
              >
                Resend
              </p>
            </div>
            <nz-form-item class="mb-0 mt-3 pb-20px w-full">
              <!-- verification-code -->
              <div
                class="verification-code text-0066FF flex align-items-center border-1px border-0066FF focus:border-0066ff1a"
              >
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 0)"
                  [(ngModel)]="code[0]"
                  #input0
                  appAutofocus
                />
                <div class="font-16 font-bold">-</div>
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 1)"
                  [(ngModel)]="code[1]"
                  #input1
                />
                <div class="font-16 font-bold">-</div>
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 2)"
                  [(ngModel)]="code[2]"
                  #input2
                />
                <div class="font-16 font-bold">-</div>
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 3)"
                  [(ngModel)]="code[3]"
                  #input3
                />
                <div class="font-16 font-bold">-</div>
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 4)"
                  [(ngModel)]="code[4]"
                  #input4
                />
                <div class="font-16 font-bold">-</div>
                <input
                  class="bg-transparent border-0 shadow-none outline-none"
                  type="text"
                  maxlength="1"
                  pattern="\d*"
                  required
                  (input)="onInput($event, 5)"
                  [(ngModel)]="code[5]"
                  #input5
                />
              </div>
            </nz-form-item>
            <div class="flex items-center pb-20px space-x-2 mb-3">
              <div><input [(ngModel)]="isReadAndAccept" type="checkbox" /></div>
              <div
                (click)="isReadAndAccept = !isReadAndAccept"
                class="text-sm cursor-pointer dark:text-fff"
              >
                I have read and accept the
                <a
                  class="text-0066FF"
                  href="https://crowdswap.org/privacy-policy-2/"
                  target="_blank"
                  >Privacy and Policy
                </a>
                of CrowdSwap.
              </div>
            </div>

            <div
              class="flex text-center pb-20px justify-content-center w-full dark:text-fff"
            >
              <p class="font-10">
                If you can’t find the email in your inbox check the spam please.
              </p>
            </div>
            <div
              *ngIf="false"
              class="flex text-center pb-20px pb-md-160px justify-content-between w-full"
            >
              <p class="font-14 font-openSans-regular">
                You have problem with verification?
              </p>
              <p
                (click)="changeDrawerPage(DrawerPage.WelcomeBack)"
                class="font-14 text-0066FF cursor-pointer font-openSans-bold"
              >
                Login with password
              </p>
            </div>

            <div class="pb-20px">
              <button
                (click)="changeDrawerPage(DrawerPage.VerifiedCode)"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
                [ngClass]="{ disable: isContinueDisabled }"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
            <p *ngIf="!user.isUserExist" class="font-10 dark:text-fff">
              By creating an account in CrowdSwap you will have a defi wallet
              address, and you will able to do any transaction like other
              block-chains platforms
            </p>
          </div>

          <!--          Verified Email-->
          <div
            *ngIf="drawerPage === DrawerPage.VerifiedCode"
            class="flex-column justify-content-center"
          >
            <div class="flex justify-content-center pb-20px pb-md-60px w-full">
              <svg
                width="118"
                height="94"
                viewBox="0 0 118 94"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3334 93.6663H105.667C112.083 93.6663 117.333 88.4163 117.333 81.9997V11.9997C117.333 5.58301 112.083 0.333008 105.667 0.333008H12.3334C5.91675 0.333008 0.666748 5.58301 0.666748 11.9997V81.9997C0.666748 88.4163 5.91675 93.6663 12.3334 93.6663ZM105.667 11.9997L59.0001 41.108L12.3334 11.9997H105.667ZM12.3334 23.6663L59.0001 52.833L105.667 23.6663V81.9997H12.3334V23.6663Z"
                  fill="#0B9ED9"
                />
              </svg>
            </div>
            <div
              class="flex text-center justify-content-center w-full dark:text-fff"
            >
              <p class="font-14">
                Congratulations! Your email address has been verified
              </p>
            </div>
            <div class="flex text-center justify-content-center w-full pb-20px">
              <p class="font-14">
                {{ user.emailAddress }}
              </p>
            </div>
            <div class="pb-20px">
              <button
                (click)="changeDrawerPage(DrawerPage.CreatePassword)"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
          </div>

          <!--          Create Password-->
          <div *ngIf="drawerPage === DrawerPage.CreatePassword">
            <ng-container
              *ngTemplateOutlet="Back; context: { item: DrawerPage.Email }"
            ></ng-container>
            <p class="font-32 font-openSans-bold dark:text-fff">
              Create Password
            </p>
            <p class="font-14 dark:text-fff">
              Create an strong password for your account. keep it in a safe
              place
            </p>
            <p class="font-14 pb-20px font-openSans-bold dark:text-fff">
              {{ user.emailAddress }}
            </p>
            <form
              nz-form
              [formGroup]="setPasswordFG"
              class="d-flex flex-column align-items-baseline pb-md-60px pb-20px h-100"
            >
              <nz-form-item class="w-full mb-3 mt-60px">
                <nz-form-label
                  [nzSpan]="24"
                  nzFor="email"
                  class="font-12 font-openSans-bold dark:text-fff"
                >
                  Password
                </nz-form-label>

                <nz-form-control [nzSpan]="24">
                  <nz-input-group
                    [nzSuffix]="passwordSuffix"
                    class="font-bold font-14 h-42px blue-color w-full placeholder-gray-300 dark:placeholder-gray-700 bg-transparent border-1px border-0066FF focus:border-0066ff1a border-radius-6px px-2"
                  >
                    <input
                      formControlName="setPassword"
                      [type]="passwordVisible ? 'text' : 'password'"
                      nz-input
                      class="w-full bg-transparent border-0 shadow-none no-outline text-left text-EBF7FA dark:text-fff font-19 font-mundial-light"
                      placeholder="Enter a password"
                      id="setPassword"
                      name="setPassword"
                      autofocus
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <ul class="password-criteria font-10 pb-20px">
                <li
                  class="flex items-center space-x-1"
                  [class.valid]="
                    setPassword?.value ?? '' | checkPattern : '.{8,}'
                  "
                >
                  <span
                    *ngIf="setPassword?.value ?? '' | checkPattern : '.{8,}'"
                  >
                    <ng-container
                      *ngTemplateOutlet="BlueCheckmark"
                    ></ng-container>
                  </span>
                  <span>At least 8 characters in length</span>
                </li>
                <li
                  class="flex items-center space-x-1"
                  [class.valid]="
                    setPassword?.value ?? '' | checkPattern : '(?=.*[a-z])'
                  "
                >
                  <span
                    *ngIf="
                      setPassword?.value ?? '' | checkPattern : '(?=.*[a-z])'
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="BlueCheckmark"
                    ></ng-container>
                  </span>
                  <span> Lowercase letter</span>
                </li>
                <li
                  class="flex items-center space-x-1"
                  [class.valid]="
                    setPassword?.value ?? '' | checkPattern : '(?=.*[A-Z])'
                  "
                >
                  <span
                    *ngIf="
                      setPassword?.value ?? '' | checkPattern : '(?=.*[A-Z])'
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="BlueCheckmark"
                    ></ng-container>
                  </span>
                  <span>Uppercase letter</span>
                </li>
                <li
                  class="flex items-center space-x-1"
                  [class.valid]="
                    setPassword?.value ?? '' | checkPattern : '[0-9]{1,}'
                  "
                >
                  <span
                    *ngIf="
                      setPassword?.value ?? '' | checkPattern : '[0-9]{1,}'
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="BlueCheckmark"
                    ></ng-container>
                  </span>
                  <span>Number</span>
                </li>
                <li
                  class="flex items-center space-x-1"
                  [class.valid]="
                    setPassword?.value ?? ''
                      | checkPattern : '(?=.*[!_@#$%^&*()-])'
                  "
                >
                  <span
                    *ngIf="
                      setPassword?.value ?? ''
                        | checkPattern : '(?=.*[!_@#$%^&*()-])'
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="BlueCheckmark"
                    ></ng-container>
                  </span>
                  <span>Special character</span>
                </li>
              </ul>
            </form>

            <div class="pb-20px">
              <button
                (click)="changeDrawerPage(DrawerPage.RepeatPassword)"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
                [ngClass]="{
                  disable: setPassword?.invalid ?? false
                }"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
          </div>

          <!--          Repeat Password-->
          <div *ngIf="drawerPage === DrawerPage.RepeatPassword">
            <ng-container
              *ngTemplateOutlet="
                Back;
                context: { item: DrawerPage.CreatePassword }
              "
            ></ng-container>
            <p class="font-32 font-openSans-bold dark:text-fff">
              Re-enter Password
            </p>
            <p class="font-14 dark:text-fff">
              Create an strong password for your account. keep it in a safe
              place
            </p>
            <p class="font-14 pb-20px font-openSans-bold dark:text-fff">
              {{ user.emailAddress }}
            </p>
            <div class="pb-md-60px pb-20px">
              <form
                nz-form
                [formGroup]="setPasswordFG"
                class="d-flex flex-column align-items-baseline h-100"
              >
                <nz-form-item class="w-100 mb-0 mt-60px">
                  <nz-form-label
                    [nzSpan]="24"
                    nzFor="reEnterPassword"
                    class="font-12 font-openSans-bold dark:text-fff"
                  >
                    Re-enter your password
                  </nz-form-label>

                  <nz-form-control
                    [nzSpan]="24"
                    [nzErrorTip]="reEnterPasswordError"
                  >
                    <nz-input-group
                      [nzSuffix]="passwordSuffix"
                      class="font-bold font-14 h-42px blue-color w-full placeholder-gray-300 dark:placeholder-gray-700 bg-transparent border-1px border-0066FF focus:border-0066ff1a border-radius-6px px-2"
                    >
                      <input
                        formControlName="reEnterPassword"
                        [type]="passwordVisible ? 'text' : 'password'"
                        nz-input
                        class="w-100 bg-transparent border-0 shadow-none no-outline text-left dark:text-fff text-EBF7FA font-19 font-mundial-light"
                        placeholder="Re-enter password"
                        id="reEnterPassword"
                        name="reEnterPassword"
                        autofocus
                      />
                    </nz-input-group>
                  </nz-form-control>

                  <ng-template #reEnterPasswordError let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Incorrect Password!
                    </ng-container>

                    <ng-container *ngIf="control.hasError('mismatch')">
                      Passwords do not match
                    </ng-container>
                  </ng-template>
                </nz-form-item>
              </form>
            </div>

            <div class="pb-20px">
              <button
                (click)="registerUser()"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
                [ngClass]="{
                  disable: setPasswordFG.invalid
                }"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
          </div>

          <!--          Successful-->
          <div
            *ngIf="drawerPage === DrawerPage.Successful"
            class="flex-column justify-content-center"
          >
            <div class="flex justify-content-center w-full">
              <svg
                width="140"
                height="140"
                viewBox="0 0 140 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="70"
                  cy="70"
                  r="52.5"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.8333 49.583L58.3333 67.083"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.8333 67.083L58.3333 49.583"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M81.6667 49.583L99.1667 67.083"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M81.6667 67.083L99.1667 49.583"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M55.4167 87.5C59.2569 91.4193 64.513 93.628 70.0001 93.628C75.4872 93.628 80.7433 91.4193 84.5834 87.5"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="flex-column text-center justify-content-center w-full dark:text-fff"
            >
              <p class="font-32">Thank you for joining</p>
              <p class="font-32">CrowdSwap</p>
            </div>
            <div
              class="flex text-center justify-content-center w-full dark:text-fff"
            >
              <p class="font-14 pb-20px pb-md-60px">
                Your account created successfully.
              </p>
            </div>

            <div class="pb-20px">
              <button
                (click)="changePage()"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
              >
                <span class="px-1">Continue</span>
              </button>
            </div>
          </div>

          <!--          Add Fund-->
          <div
            *ngIf="drawerPage === DrawerPage.AddFund"
            class="flex-column justify-content-center"
          >
            <div class="flex justify-content-center w-full">
              <svg
                width="140"
                height="140"
                viewBox="0 0 140 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M95.1666 46.6663C95.1666 48.8755 96.9575 50.6663 99.1666 50.6663C101.376 50.6663 103.167 48.8755 103.167 46.6663H95.1666ZM106.833 69.9997C106.833 72.2088 108.624 73.9997 110.833 73.9997C113.042 73.9997 114.833 72.2088 114.833 69.9997H106.833ZM114.833 93.333C114.833 91.1239 113.042 89.333 110.833 89.333C108.624 89.333 106.833 91.1239 106.833 93.333H114.833ZM27.3333 34.9997C27.3333 32.7905 25.5424 30.9997 23.3333 30.9997C21.1241 30.9997 19.3333 32.7905 19.3333 34.9997H27.3333ZM103.167 46.6663V29.1663H95.1666V46.6663H103.167ZM103.167 29.1663C103.167 23.7355 98.7641 19.333 93.3333 19.333V27.333C94.3458 27.333 95.1666 28.1538 95.1666 29.1663H103.167ZM93.3333 19.333H34.9999V27.333H93.3333V19.333ZM34.9999 19.333C26.3475 19.333 19.3333 26.3472 19.3333 34.9997H27.3333C27.3333 30.7655 30.7657 27.333 34.9999 27.333V19.333ZM19.3333 34.9997C19.3333 43.6521 26.3475 50.6663 34.9999 50.6663V42.6663C30.7657 42.6663 27.3333 39.2339 27.3333 34.9997H19.3333ZM34.9999 50.6663H105V42.6663H34.9999V50.6663ZM105 50.6663C106.012 50.6663 106.833 51.4872 106.833 52.4997H114.833C114.833 47.0689 110.431 42.6663 105 42.6663V50.6663ZM106.833 52.4997V69.9997H114.833V52.4997H106.833ZM106.833 93.333V110.833H114.833V93.333H106.833ZM106.833 110.833C106.833 111.846 106.012 112.666 105 112.666V120.666C110.431 120.666 114.833 116.264 114.833 110.833H106.833ZM105 112.666H34.9999V120.666H105V112.666ZM34.9999 112.666C30.7657 112.666 27.3333 109.234 27.3333 105H19.3333C19.3333 113.652 26.3475 120.666 34.9999 120.666V112.666ZM27.3333 105V34.9997H19.3333V105H27.3333Z"
                  fill="#0B9ED9"
                />
                <path
                  d="M116.667 70V93.3333H93.3334C86.8901 93.3333 81.6667 88.11 81.6667 81.6667C81.6667 75.2233 86.8901 70 93.3334 70H116.667"
                  stroke="#0B9ED9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="flex-column text-center justify-content-center w-full">
              <p class="font-32 dark:text-fff">Add funds</p>
            </div>
            <div
              class="flex text-center pb-20px justify-content-center w-full dark:text-fff"
            >
              <p class="font-14">
                You just made a new wallet address for yourself. you need assets
                for your transactions.
              </p>
            </div>

            <div class="pb-20px pb-md-60px">
              <button
                (click)="changeDrawerPage(DrawerPage.AddFund)"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold blue-button hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px me-2"
              >
                <div class="flex">
                  <span class="flex items-center px-1 dark:text-fff"
                    >Receive Asset
                  </span>
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_9410_66748)">
                      <path
                        d="M15.5006 6.75195V24.2537"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23.0005 16.7529L15.5005 24.2529"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.99976 16.7529L15.4998 24.2529"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_9410_66748"
                        x="0.499023"
                        y="0.5"
                        width="32.0029"
                        height="32.0029"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="1" dy="1" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_9410_66748"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_9410_66748"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </button>
            </div>
            <div class="pb-20px">
              <button
                (click)="changePage()"
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-2 d-flex align-items-center justify-content-center font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
              >
                <span class="px-1">Skip</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>

<ng-template #Back let-item="item">
  <div (click)="changeDrawerPage(item)" class="flex cursor-pointer pb-md-60px">
    <svg
      class="back-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="#0066FF"
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
      />
    </svg>
    <p class="text-0066FF font-14 pl-1 font-openSans-bold">Back</p>
  </div>
</ng-template>

<ng-template #BlueCheckmark>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 6L5 8.5L10 3.5"
      stroke="#0B9ED9"
      stroke-linecap="round"
      stroke-linejoin="round"
    /></svg
></ng-template>

<ng-template #passwordSuffix>
  <span
    class="text-EBF7FA pointer"
    nz-icon
    (click)="passwordVisible = !passwordVisible"
  >
    <ng-container *ngIf="passwordVisible; then eyeOff; else eye"></ng-container>
  </span>
</ng-template>

<ng-template #eye>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.0007"
      cy="10.0007"
      r="1.66667"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3327 9.99935C16.1102 13.8885 13.3327 15.8327 9.99935 15.8327C6.66602 15.8327 3.88852 13.8885 1.66602 9.99935C3.88852 6.11018 6.66602 4.16602 9.99935 4.16602C13.3327 4.16602 16.1102 6.11018 18.3327 9.99935"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #eyeOff>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.5L17.5 17.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.8207 8.82227C8.16947 9.47304 8.16909 10.5285 8.81987 11.1798C9.47064 11.831 10.5261 11.8314 11.1774 11.1806"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.66433 3.98956C7.39884 4.06551 7.24518 4.3423 7.32113 4.6078C7.39708 4.87329 7.67387 5.02695 7.93937 4.951L7.66433 3.98956ZM9.99935 4.16611L9.99758 4.66611H9.99935V4.16611ZM18.3327 9.99945L18.7668 10.2476C18.8546 10.0939 18.8547 9.90511 18.7668 9.75137L18.3327 9.99945ZM15.8797 12.5667C15.6922 12.7695 15.7047 13.0859 15.9075 13.2733C16.1103 13.4607 16.4266 13.4483 16.614 13.2455L15.8797 12.5667ZM14.7431 14.8715C14.972 14.7171 15.0325 14.4063 14.878 14.1774C14.7236 13.9484 14.4129 13.888 14.1839 14.0424L14.7431 14.8715ZM1.66602 9.99945L1.23193 9.75132C1.14405 9.90505 1.14404 10.0938 1.2319 10.2475L1.66602 9.99945ZM5.80669 5.9624C6.03522 5.80739 6.09482 5.49647 5.93981 5.26794C5.78479 5.03941 5.47387 4.97981 5.24534 5.13482L5.80669 5.9624ZM7.93937 4.951C8.60859 4.75955 9.30151 4.66364 9.99758 4.66611L10.0011 3.66612C9.21084 3.66332 8.42414 3.77221 7.66433 3.98956L7.93937 4.951ZM9.99935 4.66611C13.0927 4.66611 15.7303 6.45323 17.8986 10.2475L18.7668 9.75137C16.4901 5.76733 13.5727 3.66611 9.99935 3.66611V4.66611ZM17.8986 9.75131C17.2654 10.8589 16.592 11.796 15.8797 12.5667L16.614 13.2455C17.3867 12.4095 18.1032 11.4083 18.7668 10.2476L17.8986 9.75131ZM14.1839 14.0424C12.906 14.9043 11.5164 15.3328 9.99935 15.3328V16.3328C11.719 16.3328 13.3027 15.8429 14.7431 14.8715L14.1839 14.0424ZM9.99935 15.3328C6.90602 15.3328 4.26842 13.5457 2.10013 9.75137L1.2319 10.2475C3.50862 14.2316 6.42601 16.3328 9.99935 16.3328V15.3328ZM2.1001 10.2476C3.21494 8.29722 4.45444 6.87963 5.80669 5.9624L5.24534 5.13482C3.73259 6.16093 2.39876 7.71 1.23193 9.75132L2.1001 10.2476Z"
      fill="currentColor"
    />
  </svg>
</ng-template>

<ng-template #passwordSuffix>
  <span
    class="text-EBF7FA pointer"
    nz-icon
    (click)="passwordVisible = !passwordVisible"
  >
    <ng-container *ngIf="passwordVisible; then eyeOff; else eye"></ng-container>
  </span>
</ng-template>

<ng-template #eye>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.0007"
      cy="10.0007"
      r="1.66667"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3327 9.99935C16.1102 13.8885 13.3327 15.8327 9.99935 15.8327C6.66602 15.8327 3.88852 13.8885 1.66602 9.99935C3.88852 6.11018 6.66602 4.16602 9.99935 4.16602C13.3327 4.16602 16.1102 6.11018 18.3327 9.99935"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #eyeOff>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.5L17.5 17.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.8207 8.82227C8.16947 9.47304 8.16909 10.5285 8.81987 11.1798C9.47064 11.831 10.5261 11.8314 11.1774 11.1806"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.66433 3.98956C7.39884 4.06551 7.24518 4.3423 7.32113 4.6078C7.39708 4.87329 7.67387 5.02695 7.93937 4.951L7.66433 3.98956ZM9.99935 4.16611L9.99758 4.66611H9.99935V4.16611ZM18.3327 9.99945L18.7668 10.2476C18.8546 10.0939 18.8547 9.90511 18.7668 9.75137L18.3327 9.99945ZM15.8797 12.5667C15.6922 12.7695 15.7047 13.0859 15.9075 13.2733C16.1103 13.4607 16.4266 13.4483 16.614 13.2455L15.8797 12.5667ZM14.7431 14.8715C14.972 14.7171 15.0325 14.4063 14.878 14.1774C14.7236 13.9484 14.4129 13.888 14.1839 14.0424L14.7431 14.8715ZM1.66602 9.99945L1.23193 9.75132C1.14405 9.90505 1.14404 10.0938 1.2319 10.2475L1.66602 9.99945ZM5.80669 5.9624C6.03522 5.80739 6.09482 5.49647 5.93981 5.26794C5.78479 5.03941 5.47387 4.97981 5.24534 5.13482L5.80669 5.9624ZM7.93937 4.951C8.60859 4.75955 9.30151 4.66364 9.99758 4.66611L10.0011 3.66612C9.21084 3.66332 8.42414 3.77221 7.66433 3.98956L7.93937 4.951ZM9.99935 4.66611C13.0927 4.66611 15.7303 6.45323 17.8986 10.2475L18.7668 9.75137C16.4901 5.76733 13.5727 3.66611 9.99935 3.66611V4.66611ZM17.8986 9.75131C17.2654 10.8589 16.592 11.796 15.8797 12.5667L16.614 13.2455C17.3867 12.4095 18.1032 11.4083 18.7668 10.2476L17.8986 9.75131ZM14.1839 14.0424C12.906 14.9043 11.5164 15.3328 9.99935 15.3328V16.3328C11.719 16.3328 13.3027 15.8429 14.7431 14.8715L14.1839 14.0424ZM9.99935 15.3328C6.90602 15.3328 4.26842 13.5457 2.10013 9.75137L1.2319 10.2475C3.50862 14.2316 6.42601 16.3328 9.99935 16.3328V15.3328ZM2.1001 10.2476C3.21494 8.29722 4.45444 6.87963 5.80669 5.9624L5.24534 5.13482C3.73259 6.16093 2.39876 7.71 1.23193 9.75132L2.1001 10.2476Z"
      fill="currentColor"
    />
  </svg>
</ng-template>
