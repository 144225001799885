import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConnectWalletDialogComponent } from '../views/modal/dialogs/connect-wallet-dialog/connect-wallet-dialog.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IExecuteReqMdl,
  IExecuteSwap,
  IGetAddressReqMdl,
  IGetSwapReqMdl,
  IGetTransferReqMdl,
  ISmartWalletConfirmationDialogData,
  IUserDataMdl
} from '../model';
import { SmartWalletConfirmationDialogComponent } from '../views/modal/dialogs/smart-wallet-confirmation/smart-wallet-confirmation.component';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectWalletService {
  // connectWalletDialog!: import('ng-zorro-antd/modal').NzModalRef<
  //   ConnectWalletDialogComponent,
  //   any
  // >;
  public userData: IUserDataMdl = {
    email: '',
    passwordHash: '',
    userAddress: ''
  };

  constructor(
    private nzModalService: NzModalService,
    private http: HttpClient
  ) {}

  openConnectModal = (isDarkMode: boolean) => {
    return this.nzModalService.create<ConnectWalletDialogComponent, any>({
      nzTitle: undefined,
      nzFooter: undefined,
      nzContent: ConnectWalletDialogComponent,
      nzWrapClassName: `${
        isDarkMode ? 'dark' : 'light'
      } wallet-connect-dialog bg-blur-4px`,
      nzClassName: 'bg-gray-50 dark:bg-darkBlack p-0 border-radius-6px',
      nzClosable: false,
      nzCloseOnNavigation: false
    });
  };

  openConfirmModal = (
    isDarkMode: boolean,
    data: ISmartWalletConfirmationDialogData
  ) => {
    return this.nzModalService.create<
      SmartWalletConfirmationDialogComponent,
      any
    >({
      nzTitle: undefined,
      nzFooter: undefined,
      nzContent: SmartWalletConfirmationDialogComponent,
      nzWrapClassName: `${
        isDarkMode ? 'dark' : 'light'
      } smart-wallet-confirmation bg-blur-4px`,
      nzClassName: 'bg-gray-50 dark:bg-darkBlack p-0 border-radius-6px',
      nzClosable: false,
      nzCloseOnNavigation: false,
      nzData: data
    });
  };

  public async getAddress(data: IGetAddressReqMdl): Promise<any> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/get-address`;
      this.userData.email = data.email;
      this.userData.passwordHash = data.passwordHash;

      const response = (await this.http.post(url, data).toPromise()) as {
        address: string;
      };

      this.userData.userAddress = response.address;
      return response.address;
    } catch (err: any) {
      console.error(`Cannot fetch user address`);
    }
  }

  public async executeSwap(data: IExecuteSwap): Promise<any> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/execute-swap`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;
      data.swapParams.userAddress = this.userData.userAddress;
      data.swapParams.receiverAddress = this.userData.userAddress;

      let history = await this.http.post(url, data).toPromise();
      return history;
    } catch (err: any) {
      console.error(`Cannot swap the transaction`);
    }
  }

  public async executeCrosschain(data: IGetSwapReqMdl): Promise<any> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/execute-cross-chain`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let history = await this.http.post(url, data).toPromise();
      return history;
    } catch (err: any) {
      console.error(`Cannot swap the transaction`);
    }
  }

  public async executeTransfer(
    data: IGetTransferReqMdl
  ): Promise<string | undefined> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/execute-transfer`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let transfer = await this.http.post<any>(url, data).toPromise();

      // if (!transfer?.request) {
      //   throwError(transfer);
      // }

      return transfer.hash;
    } catch (err: any) {
      console.error(`Cannot execute transfer transaction`);
      return undefined;
    }
  }

  public async execute(data: IExecuteReqMdl): Promise<string | undefined> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/execute`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let execute = await this.http
        .post<string | undefined>(url, data)
        .toPromise();

      return execute;
    } catch (err: any) {
      console.error(`The transaction cannot be executed.`);
      if (err.error.text) {
        return err.error.text;
      }
    }
  }

  public async isUserExistOrSendCode(emailAddress: string): Promise<boolean> {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/send-verification-code`;
      const data = { email: emailAddress };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result !== 'OK';
    } catch (err: any) {
      if (
        err.error.statusCode === 302 &&
        err.error.message === 'User Already Exists'
      ) {
        return true;
      }
      console.error(`Cannot send verification code to the server`);
      throw err;
    }
  }

  public async isVerificationCodeCorrect(
    emailAddress: string,
    verificationCode: string
  ) {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/is-verification-code-correct`;
      const data = { email: emailAddress, verificationCode: verificationCode };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: boolean;
      };

      return userObj.result;
    } catch (err: any) {
      console.error(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async registerUser(
    emailAddress: string,
    verificationCode: string,
    passwordHash: string
  ) {
    try {
      const url = `${environment.CROWDWALLET_BASEURL}/register`;
      const data = {
        email: emailAddress,
        verificationCode: verificationCode,
        passwordHash: passwordHash
      };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      console.log(`Cannot send verification code to the server`);
      return false;
    }
  }
}
