<div
  class="cross-chain-main-page container d-flex flex-column align-items-center position-relative px-3 px-lt-24px pt-4"
>
  <div *ngIf="false" class="w-full d-flex mb-3 justify-content-center">
    <img
      src="assets/media/zero-fee/zero-fee-banner.jpg"
      class="border-radius-6px"
    />
  </div>

  <ng-container>
    <div
      [hidden]="!isAllTransactionsMinimized(state.pendingTradeList)"
      class="swap-box w-full p-md-20px border-radius-6px z-0 bg-fff dark:border-1E1E1E h-max-content"
      [ngClass]="{
        'dark:bg-121212 dark:border-2': isDarkMode && !setting.isMobile,
        'dark:bg-070707 dark:border-1': isDarkMode && setting.isMobile,
        'p-md-20px p-3': !setting.isMobile,
        'py-3': setting.isMobile
      }"
    >
      <div
        dir="rtl"
        class="w-full d-flex align-items-center justify-content-between swap-header"
      >
        <div class="d-flex align-items-center">
          <span class="ms-3 cursor-pointer" (click)="settingDialog()">
            <img
              *ngIf="!isDarkMode"
              alt="setting token"
              src="assets/media/icons/setting-black.svg"
              width="19"
              class="pointer"
            />

            <img
              *ngIf="isDarkMode"
              alt="setting token"
              src="assets/media/icons/setting-white.svg"
              width="19"
              class="pointer"
            />
          </span>

          <span class="ms-3 cursor-pointer">
            <img
              *ngIf="!isDarkMode"
              (click)="refreshSearch()"
              alt="refresh token"
              src="assets/media/icons/refresh-black.svg"
              width="16"
              class="pointer"
              nz-tooltip
              [nzTooltipTitle]="refreshTooltip"
            />

            <img
              *ngIf="isDarkMode"
              (click)="refreshSearch()"
              alt="refresh token"
              src="assets/media/icons/refresh-white.svg"
              width="16"
              class="pointer"
              nz-tooltip
              [nzTooltipTitle]="refreshTooltip"
            />

            <ng-template #refreshTooltip>
              <div class="text-sm text-center text-white bg-black">
                <span>Restart Estimation</span>
              </div>
            </ng-template>
          </span>

          <span class="ms-3 cursor-pointer" [nz-tooltip]="autoSearchTooltip">
            <circle-progress
              *ngIf="state.hasAutoSearchSubscriber"
              (click)="switchRefreshSearch($event)"
              [percent]="setting.countDownTimer"
              [radius]="7"
              [outerStrokeWidth]="2"
              [outerStrokeColor]="'#0B9ED9'"
              [animation]="true"
              [animationDuration]="1"
            ></circle-progress>

            <img
              *ngIf="!state.hasAutoSearchSubscriber && !isDarkMode"
              (click)="switchRefreshSearch($event)"
              alt="refresh token off"
              src="assets/media/icons/refresh-outline-black.svg"
              width="16"
              class="pointer"
            />

            <img
              *ngIf="!state.hasAutoSearchSubscriber && isDarkMode"
              (click)="switchRefreshSearch($event)"
              alt="refresh token off"
              src="assets/media/icons/refresh-outline-white.svg"
              width="16"
              class="pointer"
            />

            <ng-template #autoSearchTooltip>
              <span class="font-14 text-center text-fff">
                {{
                  state.hasAutoSearchSubscriber
                    ? "Auto refresh countdown"
                    : "Auto refresh"
                }}
              </span>
            </ng-template>
          </span>
        </div>

        <div class="d-flex align-items-center justify-content-center h-30px">
          <div class="d-flex align-items-center" dir="ltr">
            <div
              class="d-flex align-items-center p-3px border-radius-3px bg-0066FF1A dark:text-fff font-openSans-bold space-x-2 ml-1"
            >
              <span
                class="exchange-type-btn font-20 border-radius-2px letter-spacing0.1px cursor-pointer"
                [ngClass]="{
                  'text-0066FF dark:text-3385FF':
                    status.exchangeType === ExchangeType.Market
                }"
                (click)="switchExchangeType(ExchangeType.Market)"
              >
                Market
              </span>

              <span
                class="exchange-type-btn font-20 border-radius-2px letter-spacing0.1px cursor-pointer"
                [ngClass]="{
                  'text-0066FF dark:text-3385FF':
                    status.exchangeType === ExchangeType.Limit
                }"
                (click)="switchExchangeType(ExchangeType.Limit)"
              >
                Limit
              </span>
            </div>
          </div>
        </div>
      </div>

      <div #Exchange>
        <div class="w-full mt-20px">
          <app-cross-chain-token-selection
            (onPairSelected)="onPairSelected($event)"
            (stopRefresh)="stopRefresh()"
            [estimation]="state.activeEstimationTrade"
            [parentSearchParam]="state.searchParam"
            [_exchangeType]="status.exchangeType"
          ></app-cross-chain-token-selection>
        </div>

        <!-- Limit Renge Invalid and cannot be processed -->
        <div
          class="mt-2 rounded-md w-full bg-c13d5440 p-2 d-flex align-items-start"
          *ngIf="
            isSwapParamCrossChain() &&
            !state.activeEstimationTrade?.loading &&
            (state.activeEstimationTrade?.limitRengeInvalid ||
              state.activeEstimationTrade?.AmountOutEstimationUnknown)
          "
        >
          <div class="unusual-confirmed-switch-section w-full d-flex">
            <div class="w-full d-flex dark:text-fff text-000 align-items-start">
              <span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.975 2.75H14.025C14.3 2.75 14.4833 2.84167 14.6667 3.025L18.975 7.33333C19.1583 7.51667 19.25 7.7 19.25 7.975V14.025C19.25 14.3 19.1583 14.4833 18.975 14.6667L14.6667 18.975C14.4833 19.1583 14.3 19.25 14.025 19.25H7.975C7.7 19.25 7.51667 19.1583 7.33333 18.975L3.025 14.6667C2.84167 14.4833 2.75 14.3 2.75 14.025V7.975C2.75 7.7 2.84167 7.51667 3.025 7.33333L7.33333 3.025C7.51667 2.84167 7.7 2.75 7.975 2.75Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.0013 7.33203V10.9987"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.0006 14.6654H11.0098"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>

              <p
                class="text-14 font-openSans-regular ml-1"
                *ngIf="state.activeEstimationTrade?.limitRengeInvalid"
              >
                You are selling your
                {{ state.activeEstimationTrade?.fromToken?.symbol }}
                below the market price. Please consider raising the target price
                or amount.
              </p>

              <p
                class="text-14 font-openSans-regular ml-1"
                *ngIf="state.activeEstimationTrade?.AmountOutEstimationUnknown"
              >
                Order cannot be processed. Please align your target values with
                the market rate.
              </p>
            </div>
          </div>
        </div>

        <!-- isUnusual ratio -->
        <div
          class="mt-2 rounded-md w-full bg-FBD127 p-2 d-flex align-items-start"
          *ngIf="
            !state.activeEstimationTrade.loading &&
            isUserWalletConnected &&
            (state.activeEstimationTrade.costAnalyze?.isUnusualAmountOut ||
              state.activeEstimationTrade.costAnalyze?.isUnusualFee)
          "
        >
          <label class="unusual-confirmed-switch-section w-full d-flex">
            <nz-switch
              id="UnusualConfirmed"
              nzSize="small"
              [(ngModel)]="state.activeEstimationTrade.costAnalyze!.confirmed"
              class="mt-1 mr-2"
            ></nz-switch>

            <div class="w-full d-flex flex-column text-000">
              <div class="w-100 d-flex align-items-center font-16">
                <span class="mt-1 cursor-pointer font-14 font-bold">
                  Risk Acknowledgment Confirmation
                </span>
              </div>

              <p
                class="text-14 font-openSans-regular mt-1"
                *ngIf="
                  state.activeEstimationTrade.costAnalyze?.isUnusualAmountOut
                "
              >
                I understand the risk that by completing this exchange I may
                lose

                <nz-skeleton-element
                  class="skeleton-h-22"
                  nzType="button"
                  [nzActive]="true"
                  nzSize="small"
                  *ngIf="state.activeEstimationTrade?.loading; else amount"
                ></nz-skeleton-element>

                <ng-template #amount>
                  {{ state.activeEstimationTrade.costAnalyze!.amount }}
                </ng-template>
              </p>

              <p
                *ngIf="
                  state.activeEstimationTrade.costAnalyze?.isUnusualAmountOut &&
                  state.activeEstimationTrade.costAnalyze?.isUnusualFee
                "
              >
                and
              </p>

              <p
                class="text-14 font-openSans-regular mt-1"
                *ngIf="state.activeEstimationTrade.costAnalyze?.isUnusualFee"
              >
                I understand that transaction fees are high at the moment.
              </p>
            </div>
          </label>
        </div>

        <!-- Fee box-->
        <div
          class="network-change-box to-section w-full p-2 d-flex flex-column mt-2"
          [ngClass]="{
            'border-1E1E1E border-1px border-radius-8px is-dark': isDarkMode,
            'border-9797971a border-2px border-radius-6px bg-FAFAFA':
              !isDarkMode
          }"
          *ngIf="
            [
              CrossChainSwapState.NotFound,
              CrossChainSwapState.AmountInIsTooLow
            ].indexOf(state.activeEstimationTrade.crossChainSwapState) === -1 ||
            state.activeEstimationTrade?.loading
          "
        >
          <!-- fee divider -->
          <div class="w-full d-flex align-items-center justify-content-between">
            <nz-divider
              nzDashed
              [nzText]="FeeText"
              nzOrientation="left"
              class="exchange-divider has-show-more m-0 border-0000001a dark:border-ffffff1a cursor-pointer"
              (click)="showMore()"
            >
              <ng-template #FeeText>
                <span class="text-7D7D7D dark:text-FFFFFFBF font-12">
                  {{ this.status.showMoreFee ? "You Pay" : "Your Transaction" }}
                </span>
              </ng-template>
            </nz-divider>

            <span
              class="text-323232 dark:text-FFFFFFBF flex-shrink-0 transition-03-linear cursor-pointer"
              [ngClass]="{ 'rotate-180': this.status.showMoreFee }"
              (click)="showMore()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0593 5.72974L7.9996 8.78277L4.93991 5.72974L4 6.66964L7.9996 10.6692L11.9992 6.66964L11.0593 5.72974Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>

          <!-- Input amount  -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="
              status.showMoreFee && state.activeEstimationTrade?.amountInDisplay
            "
          >
            <span> Input amount </span>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.amountInDisplay
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff d-flex align-items-center"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.amountInDisplay
              "
            >
              <span>
                {{ state.activeEstimationTrade?.amountInDisplay ?? "0" }}
                {{ state.activeEstimationTrade?.fromToken?.symbol }}
              </span>

              <span class="px-1">≃</span>

              <span>
                {{
                  state.activeEstimationTrade?.amountInInUSDTToDisplay ??
                    "$0.00"
                }}
              </span>
            </div>
          </div>

          <!-- Protocol fee  -->
          <div
            *ngIf="isSwapParamCrossChain()"
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
          >
            <div class="d-flex align-items-center">
              <span> Protocol fee </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                *ngIf="!state.activeEstimationTrade?.loading"
                nz-tooltip
                [nzTooltipTitle]="ProtocolFeeTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #ProtocolFeeTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span>
                      Protocol fee covers transaction execution costs on the
                      destination network.
                    </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="state.activeEstimationTrade?.loading"
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff d-flex align-items-center"
              *ngIf="!state.activeEstimationTrade?.loading"
            >
              <ng-container *ngIf="status.showMoreFee">
                <ng-container
                  *ngIf="
                    state.activeEstimationTrade?.operatingExpensesFee &&
                    (state.activeEstimationTrade.operatingExpensesFee
                      ?.amountInUSDT ??
                      0) >
                      0
                  "
                >
                  <span>
                    {{
                      state.activeEstimationTrade.operatingExpensesFee?.amount
                    }}
                    {{
                      state.activeEstimationTrade.operatingExpensesFee?.token
                        ?.symbol
                    }}
                  </span>

                  <span class="px-1">+</span>
                </ng-container>

                <span>
                  {{ state.activeEstimationTrade.protocolFee?.amount ?? "0" }}
                  {{ state.activeEstimationTrade.protocolFee?.token?.symbol }}
                </span>

                <span class="px-1"> ≃ </span>
              </ng-container>

              <span>
                {{ state.activeEstimationTrade.totalProtocolFee }}
              </span>
            </div>
          </div>

          <!-- CrowdSwap  fee  -->
          <div
            *ngIf="isSwapParamCrossChain()"
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
          >
            <div class="d-flex align-items-center">
              <span> CrowdSwap fee </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                *ngIf="!state.activeEstimationTrade?.loading"
                nz-tooltip
                [nzTooltipTitle]="CrowdswapFeeTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #CrowdswapFeeTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span> CrowdSwap revenue from this exchange. </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="state.activeEstimationTrade?.loading"
            ></nz-skeleton-element>

            <div
              class="ml-3 text-fff d-flex align-items-center"
              [ngClass]="
                state.activeEstimationTrade?.crowdswapFeeToDisplay?.amount ===
                  '0.00' &&
                state.activeEstimationTrade?.crowdswapFeeToDisplay
                  ?.amountInUSDT === 0
                  ? 'bg-13b9aa-gradient px-2px border-radius-2px'
                  : 'text-323232 dark:text-fff'
              "
              *ngIf="!state.activeEstimationTrade?.loading"
            >
              <ng-container *ngIf="status.showMoreFee">
                <span>
                  {{
                    state.activeEstimationTrade?.crowdswapFeeToDisplay
                      ?.amount ?? 0
                  }}
                  {{
                    state.activeEstimationTrade?.crowdswapFeeToDisplay?.token
                      ?.symbol
                  }}
                </span>

                <span class="px-1"> ≃ </span>
              </ng-container>

              <span>
                {{
                  state.activeEstimationTrade?.crowdswapFeeToDisplay
                    ?.amountInUSDTToDisplay ?? "$0.00"
                }}
              </span>
            </div>
          </div>

          <!-- Network cost  -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="
              isSwapParamCrossChain() &&
              status.showMoreFee &&
              state.activeEstimationTrade?.networkCost
            "
          >
            <div class="d-flex align-items-center">
              <span> Network cost </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                *ngIf="!state.activeEstimationTrade?.loading"
                nz-tooltip
                [nzTooltipTitle]="NetworkCostTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #NetworkCostTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span>
                      The cost for compensating network validators for exchange.
                    </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.networkCost
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff d-flex align-items-center"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.networkCost
              "
            >
              <span>
                {{ state.activeEstimationTrade?.networkCost?.amount ?? 0 }}
                {{ state.activeEstimationTrade?.networkCost?.token?.symbol }}
              </span>

              <span class="px-1"> ≃ </span>

              <span>
                {{
                  state.activeEstimationTrade?.networkCost
                    ?.amountInUSDTToDisplay ?? "$0.00"
                }}
              </span>
            </div>
          </div>

          <!-- Execution fee  -->
          <!-- <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="
              isSwapParamCrossChain() &&
              status.showMoreFee &&
              state.activeEstimationTrade?.operatingExpensesFee &&
              state.activeEstimationTrade?.operatingExpensesFee?.amount &&
              state.activeEstimationTrade?.operatingExpensesFee?.amount! > '0'
            "
          >
            <div class="d-flex align-items-center">
              <span> Execution fee </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                *ngIf="!state.activeEstimationTrade?.loading"
                nz-tooltip
                [nzTooltipTitle]="CrowdswapFeeTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #CrowdswapFeeTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span> Cost for processing a trade. </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.operatingExpensesFee
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.operatingExpensesFee
              "
            >
              {{ state.activeEstimationTrade?.operatingExpensesFee?.amount }}
              {{
                state.activeEstimationTrade?.operatingExpensesFee?.token?.symbol
              }}
              ({{
                state.activeEstimationTrade?.operatingExpensesFee
                  ?.amountInUSDTToDisplay
              }})
            </div>
          </div> -->

          <!-- Total paid  -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="
              isSwapParamCrossChain() &&
              status.showMoreFee &&
              state.activeEstimationTrade?.totalPaidWithFee
            "
          >
            <span> Total paid </span>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.totalPaidWithFee
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.totalPaidWithFee
              "
            >
              {{ state.activeEstimationTrade?.totalPaidWithFee }}
            </div>
          </div>

          <!-- divider -->
          <nz-divider
            *ngIf="
              status.showMoreFee &&
              (state.activeEstimationTrade?.amountOutDisplay ||
                state.activeEstimationTrade?.minAmountOutToDisplay)
            "
            nzDashed
            [nzText]="YouReceiveText"
            nzOrientation="left"
            class="exchange-divider mb-0 mt-2 border-0000001a dark:border-ffffff1a"
          >
            <ng-template #YouReceiveText>
              <span class="text-7D7D7D dark:text-FFFFFFBF font-12">
                You Receive
              </span>
            </ng-template>
          </nz-divider>

          <!-- Output amount  -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="
              status.showMoreFee &&
              state.activeEstimationTrade?.amountOutDisplay
            "
          >
            <div class="d-flex align-items-center">Output amount</div>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.amountOutDisplay
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff d-flex align-items-center"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.amountOutDisplay
              "
            >
              <ng-container *ngIf="status.showMoreFee">
                <span>
                  {{ state.activeEstimationTrade?.amountOutDisplay ?? "0" }}
                  {{ state.activeEstimationTrade?.toToken?.symbol }}
                </span>

                <span class="px-1"> ≃ </span>
              </ng-container>

              <span>
                {{
                  state.activeEstimationTrade?.amountOutInUSDTToDisplay ??
                    "$0.00"
                }}
              </span>
            </div>
          </div>

          <!-- Minimum received -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-000 dark:text-fff"
            *ngIf="state.activeEstimationTrade?.minAmountOutToDisplay"
          >
            <span> Minimum received </span>

            <nz-skeleton-element
              class="skeleton-h-16"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.minAmountOutToDisplay
              "
            ></nz-skeleton-element>

            <div
              class="pl-3 text-323232 dark:text-fff d-flex align-items-center"
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                state.activeEstimationTrade?.minAmountOutToDisplay
              "
            >
              <span>
                {{ state.activeEstimationTrade?.minAmountOutToDisplay ?? "0" }}
                {{ state.activeEstimationTrade?.toToken?.symbol }}
              </span>

              <span class="px-1"> ≃ </span>

              <span>
                {{
                  state.activeEstimationTrade?.minAmountOutInUSDTToDisplay ??
                    "$0.00"
                }}
              </span>
            </div>
          </div>

          <!-- divider -->
          <nz-divider
            nzDashed
            [nzText]="RouteText"
            nzOrientation="left"
            class="exchange-divider mb-0 mt-2 border-0000001a dark:border-ffffff1a"
          >
            <ng-template #RouteText>
              <span class="text-7D7D7D dark:text-FFFFFFBF font-12">
                Route
              </span>
            </ng-template>
          </nz-divider>

          <!-- Route -->
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-FFFFFFBF"
          >
            <nz-skeleton-element
              class="skeleton-h-24"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="
                state.activeEstimationTrade?.loading ||
                !state.activeEstimationTrade?.routes
              "
            ></nz-skeleton-element>

            <div
              *ngIf="
                !state.activeEstimationTrade?.loading &&
                  state.activeEstimationTrade?.routes &&
                  state.activeEstimationTrade.routes?.length &&
                  state.activeEstimationTrade.routes.length >= 2;
                else notFoundRoute
              "
              class="d-flex flex-wrap align-items-center"
            >
              <div
                class="d-flex align-items-center justify-content-center ml-1"
                *ngFor="
                  let item of state.activeEstimationTrade!.routeList;
                  index as index
                "
              >
                <span *ngIf="index > 0"> {{ Constants.ARROW_UNICODE }} </span>

                <span
                  class="px-6px py-2px d-flex align-items-center justify-content-center border-1px border-9797971A dark:border-595962 rounded-full ml-1"
                  [ngClass]="
                    item?.isMiddleToken
                      ? 'bg-13B9AA text-white'
                      : 'bg-fff dark:bg-1E1E1E text-323232 dark:text-fff'
                  "
                >
                  <span class="token-icon position-relative">
                    <img
                      class="rounded-full w-5 h-5"
                      [src]="
                        baseLogoIconsUrl + item?.symbol?.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />

                    <div
                      class="position-absolute right-0 bottom-0 bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
                    >
                      <img
                        class="rounded-full w-3 h-3"
                        [src]="
                          baseLogoIconsUrl +
                          Networks[item.chainId]?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>
                  </span>

                  <span
                    class="ml-6px font-openSans-regular font-12 font-openSans-bold"
                  >
                    {{ item?.symbol }}
                  </span>

                  <span
                    *ngIf="item?.isMiddleToken"
                    class="mx-1"
                    nz-tooltip
                    [nzTooltipTitle]="RouteTooltipText"
                    [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
                  >
                    <img
                      src="assets/media/icons/info-white.svg"
                      width="16"
                      alt="info"
                      class="max-w-unset"
                    />
                  </span>

                  <ng-template #RouteTooltipText>
                    <div class="w-full d-flex flex-column font-12">
                      <span>
                        Discover how the CROWD plays a key role in this
                        transaction.<a
                          href="https://crowdswap.org/token-usecases/#cross_chain"
                          target="_blank"
                          class="font-openSans text-0066FF dark:text-3385FF border-radius-4px"
                        >
                          click here to learn more.
                        </a></span
                      >
                    </div>
                  </ng-template>
                </span>
              </div>
            </div>

            <ng-template #notFoundRoute>
              <span *ngIf="state.activeEstimationTrade?.routes?.length === 0"
                >Route not found
              </span>
            </ng-template>
          </div>

          <div class="w-full font-12 text-right w-full mt-1">
            <!-- Token Price divider -->
            <div class="w-full">
              <nz-skeleton-element
                *ngIf="state.activeEstimationTrade!.loading"
                class="skeleton-h-18 w-full mt-1"
                nzType="button"
                [nzActive]="true"
                nzSize="small"
              ></nz-skeleton-element>

              <nz-divider
                *ngIf="!state.activeEstimationTrade!.loading"
                nzDashed
                [nzText]="TokenPriceText"
                nzOrientation="left"
                class="exchange-divider mb-0 mt-2 border-0000001a dark:border-ffffff1a"
              >
                <ng-template #TokenPriceText>
                  <span class="text-000 dark:text-fff font-12">
                    1
                    {{ state.activeEstimationTrade?.toToken?.symbol }} =
                    {{ state.activeEstimationTrade?.amountOutPerAmountInRatio }}
                    {{ state.activeEstimationTrade?.fromToken?.symbol }}
                    <span *ngIf="state.activeEstimationTrade?.toToken?.price">
                      (~{{ state.searchParam?.toTokenPriceToDisplay }})
                    </span>
                  </span>
                </ng-template>
              </nz-divider>
            </div>
          </div>
        </div>

        <!-- Estimation not found box -->
        <div
          *ngIf="
            !state.activeEstimationTrade?.loading &&
            state.activeEstimationTrade.crossChainSwapState ===
              CrossChainSwapState.NotFound &&
            !state.activeEstimationTrade?.limitRengeInvalid &&
            !state.activeEstimationTrade?.AmountOutEstimationUnknown
          "
          class="network-change-box to-section w-full font-12 dark:text-white p-2 d-flex flex-column mt-2 border-2px"
          [ngClass]="{
            'border-595962 border-radius-8px is-dark': isDarkMode,
            'border-9797971a border-radius-6px bg-FAFAFA': !isDarkMode
          }"
        >
          <div class="flex space-x-2">
            <div>
              <img
                [hidden]="isDarkMode"
                src="assets/media/icons/info.svg"
                width="16"
                alt="info"
                class="max-w-unset"
              />

              <img
                [hidden]="!isDarkMode"
                src="assets/media/icons/info-white.svg"
                width="16"
                alt="info"
                class="max-w-unset"
              />
            </div>

            <div class="font-bold">Estimation not found!</div>
          </div>

          <div class="font-12 ms-2">
            Estimated conversion not found for the selected tokens. Please
            choose different tokens to proceed with{{
              isSwapParamCrossChain() ? " Cross-Chain " : " Swap "
            }}functionality
          </div>
        </div>

        <!-- Receiver wallet address switch -->
        <label
          for="walletAddress"
          class="w-full d-flex flex-column"
          *ngIf="
            isUserWalletConnected &&
            !isSwapParamCrossChain() &&
            [
              CrossChainSwapState.InsufficientTakerBalance,
              CrossChainSwapState.NotFound,
              CrossChainSwapState.AmountInIsTooLow,
              CrossChainSwapState.Expired
            ].indexOf(state.activeEstimationTrade.crossChainSwapState) === -1
          "
        >
          <label
            [ngClass]="{ 'dark-switch': isDarkMode }"
            class="receiver-wallet-switch-section w-full d-flex align-items-center font-16 text-000 dark:text-fff mt-12px mb-0 ms-2"
          >
            <nz-switch
              nzSize="small"
              [(ngModel)]="setting.isReceiverWalletSelected"
              class="mr-2"
              (ngModelChange)="walletAddressInputFocus()"
            ></nz-switch>

            <span class="mt-1 cursor-pointer font-md-16 font-14">
              Send the swapped token to another address
            </span>
          </label>

          <!-- Receiver wallet address -->
          <div *ngIf="setting.isReceiverWalletSelected">
            <div
              class="rounded-md w-full bg-FBD127 mt-2 p-2 d-flex align-items-start"
            >
              <img
                src="assets/media/icons/info.svg"
                width="16"
                alt="info"
                class="max-w-unset"
              />

              <div class="font-12 ms-2 text-000">
                Please ensure that the address you've entered is on the same
                network as selected on the "To" section (<span
                  class="font-bold"
                  >{{
                    web3Service.networkSpec[
                      (this.state.searchParam?.fromToken)!.chainId
                    ].title
                  }}</span
                >). Any mismatch between the address and network will result in
                a loss of funds.
              </div>
            </div>

            <div
              class="mt-2 font-12"
              [ngClass]="
                setting.isReceiverWalletSelected
                  ? state.receiverAddress === web3Service.getWalletAddress() ||
                    !status.isAddressValid
                    ? 'text-ef4444'
                    : 'text-3b82f6'
                  : 'text-d1d5db'
              "
            >
              <label for="walletAddress">Wallet address</label>

              <input
                #walletAddressInput
                id="walletAddress"
                [(ngModel)]="state.receiverAddress"
                (ngModelChange)="isAddress()"
                [readOnly]="!setting.isReceiverWalletSelected"
                [ngClass]="
                  !setting.isReceiverWalletSelected
                    ? 'border-d1d5db'
                    : state.receiverAddress ===
                        web3Service.getWalletAddress() || !status.isAddressValid
                    ? 'border-ef4444 text-ef4444'
                    : 'border-3b82f6'
                "
                class="font-bold w-full text-12 p-2 border-2px border-radius-2px outline-none bg-transparent"
              />
            </div>

            <div
              *ngIf="
                !status.isAddressValid &&
                setting.isReceiverWalletSelected &&
                state.receiverAddress !== ''
              "
              class="text-12 mt-2 text-ef4444"
            >
              The address is not valid!
            </div>

            <div
              *ngIf="
                setting.isReceiverWalletSelected && state.receiverAddress === ''
              "
              class="text-12 mt-2 text-ef4444"
            >
              Wallet address is empty!
            </div>

            <div
              *ngIf="
                setting.isReceiverWalletSelected &&
                state.receiverAddress === web3Service.getWalletAddress()
              "
              class="font-12 mt-2 text-ef4444"
            >
              Wallet address already connected to the app. Please enter a
              different address to send the swapped token
            </div>

            <a
              *ngIf="state.receiverAddress && status.isAddressValid"
              target="_blank"
              href="{{ web3Service.getScanAddressUrl(state.receiverAddress) }}"
              class="text-12 text-3b82f6 d-flex justify-content-end align-items-center mt-2"
            >
              <div class="blue-color mr-1">Check address</div>

              <img
                src="assets/media/icons/check-address.svg"
                alt="check-address"
              />
            </a>
          </div>
        </label>

        <div class="w-full d-flex flex-column" #buttonSection>
          <!-- Approve, Insufficient, Connect wallet and Swap buttons -->
          <div
            *ngIf="
              [
                CrossChainSwapState.NotFound,
                CrossChainSwapState.AmountInIsTooLow,
                CrossChainSwapState.InsufficientTakerBalance,
                CrossChainSwapState.Expired
              ].indexOf(state.activeEstimationTrade.crossChainSwapState) ===
                -1 && !state.activeEstimationTrade.loading
            "
          >
            <!--Approve button-->
            <div *ngIf="approveButtonVisible" class="flex flex-col mt-9">
              <button
                (click)="approve()"
                class="blue-button hover:bg-blue-700 h-48px w-full border-radius-4px font-openSans-semiBold font-13 letter-spacing-normal text-fff d-flex align-items-center justify-content-center"
              >
                <span class="mr-1">
                  Allow to use your
                  {{ state.activeEstimationTrade?.fromToken?.symbol }}
                </span>

                <svg
                  class="bi bi-exclamation-circle text-white"
                  fill="currentColor"
                  height="20"
                  viewBox="0 0 16 16"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                  />
                </svg>
              </button>
            </div>

            <!-- Swap button -->
            <div
              *ngIf="swapButtonVisible"
              class="flex flex-col mt-20px"
              [ngClass]="{
                'bg-323232 p-4px border-radius-8px':
                  state.activeEstimationTrade.crossChainSwapState ===
                  CrossChainSwapState.ApprovalConfirmed
              }"
            >
              <!-- Now you can trade-->
              <div
                *ngIf="
                  state.activeEstimationTrade.crossChainSwapState ===
                  CrossChainSwapState.ApprovalConfirmed
                "
                class="w-full mb-1 d-flex align-items-center justify-content-center"
              >
                <div class="font-14 text-fff me-2 flex items-center">
                  Now you can swap
                  {{ state.activeEstimationTrade?.fromToken?.symbol }}
                  for
                  {{ state.activeEstimationTrade?.toToken?.symbol }}
                </div>

                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3337 10L14.242 8.09167L10.1753 4.025L6.84199 7.35833L0.666992 1.175L1.84199 0L6.84199 5L10.1753 1.66667L15.4253 6.90833L17.3337 5V10H12.3337Z"
                    fill="white"
                  />
                </svg>
              </div>

              <button
                nz-button
                nzType="default"
                (click)="swap()"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
                [ngClass]="{
                  'opacity-4': swapButtonDisable || approveButtonVisible,
                  'justify-content-center': transactionType === 'Cheap Route',
                  'justify-content-between': transactionType === 'Fast Route'
                }"
                [disabled]="
                  swapButtonDisable ||
                  approveButtonVisible ||
                  status.exchangeType === ExchangeType.Limit
                "
              >
                <span class="px-1" *ngIf="transactionType === 'Cheap Route'">
                  {{
                    status.exchangeType === ExchangeType.Limit
                      ? "Temporarily unavailable"
                      : "SWAP"
                  }}
                </span>

                <ng-container *ngIf="transactionType === 'Fast Route'">
                  <span class="px-1 me-3"> Swap Fast </span>

                  <img
                    src="assets/media/icons/SwapFast.svg"
                    width="17"
                    alt="Swap Fast"
                /></ng-container>
              </button>
            </div>

            <!--Insufficient coin balance-->
            <div
              *ngIf="
                state.activeEstimationTrade.crossChainSwapState ===
                  CrossChainSwapState.InsufficientCoinBalance &&
                isUserWalletConnected
              "
              class="flex flex-col mt-20px"
            >
              <button
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
                [ngClass]="{ 'opacity-4': true }"
                [disabled]="true"
              >
                <span class="px-1">
                  Insufficient
                  {{
                    web3Service.networkSpec[
                      (state.activeEstimationTrade?.fromToken)!.chainId
                    ].coin
                  }}
                  balance
                </span>
              </button>
            </div>

            <!--Insufficient balance-->
            <div
              *ngIf="
                state.activeEstimationTrade.crossChainSwapState ===
                  CrossChainSwapState.InsufficientSourceBalance &&
                isUserWalletConnected
              "
              class="flex flex-col mt-20px"
              [ngClass]="{
                'bg-c13d5440 p-4px border-radius-8px': state.searchParam?.isMax
              }"
            >
              <div
                *ngIf="state.searchParam?.isMax"
                class="w-full font-14 text-000 dark:text-fff d-flex items-center p-2"
              >
                <svg
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 19H22L11 0L0 19ZM3.47 17L11 3.99L18.53 17H3.47ZM10 14H12V16H10V14ZM10 8H12V12H10V8Z"
                    fill="currentColor"
                  />
                </svg>

                <span class="ms-2">
                  The total paid amount for this transaction exceeds your wallet
                  balance.
                </span>
              </div>

              <button
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px"
                [ngClass]="{
                  'opacity-4': true,
                  'mt-3': !state.searchParam?.isMax
                }"
                [disabled]="true"
              >
                <span class="px-1">
                  Insufficient {{ state.searchParam!.fromToken.symbol }} balance
                </span>
              </button>
            </div>

            <!--Connect wallet-->
            <div *ngIf="!isUserWalletConnected" class="flex flex-col mt-20px">
              <button
                nz-button
                nzType="default"
                (click)="connectWallet()"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
              >
                <span class="px-1"> Connect wallet </span>
              </button>
            </div>
          </div>

          <!--Set To Market button-->
          <div
            *ngIf="
              (state.activeEstimationTrade?.limitRengeInvalid ||
                state.activeEstimationTrade?.AmountOutEstimationUnknown) &&
              !state.activeEstimationTrade.loading
            "
            class="d-flex mt-9"
          >
            <button
              nz-button
              nzType="default"
              (click)="setToMarket()"
              [nzSize]="'large'"
              class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            >
              <span class="px-1"> Set To Market </span>
            </button>
          </div>

          <!--Insufficient taker balance-->
          <div
            class="flex flex-col mt-2"
            *ngIf="
              state.activeEstimationTrade.crossChainSwapState ===
                CrossChainSwapState.InsufficientTakerBalance &&
              !state.activeEstimationTrade.loading
            "
          >
            <div
              class="w-full py-2 px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3 pointer opacity-4 text-center"
            >
              <span class="px-1"> {{ state.activeEstimationTrade?.msg }} </span>
            </div>
          </div>

          <!--estimation not found -->
          <div
            *ngIf="
              state.activeEstimationTrade.crossChainSwapState ===
                CrossChainSwapState.NotFound &&
              !state.activeEstimationTrade?.limitRengeInvalid &&
              !state.activeEstimationTrade?.AmountOutEstimationUnknown &&
              !state.activeEstimationTrade.loading
            "
          >
            <div class="flex flex-col mt-20px">
              <button
                nz-button
                nzType="default"
                [nzSize]="'large'"
                class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
                [ngClass]="{ 'opacity-4': true }"
                [disabled]="true"
              >
                <span class="px-1"> Estimation not found </span>
              </button>
            </div>
          </div>

          <!--Input amount is too low-->
          <div
            *ngIf="
              state.activeEstimationTrade.crossChainSwapState ===
                CrossChainSwapState.AmountInIsTooLow &&
              !state.activeEstimationTrade.loading
            "
            class="flex flex-col mt-20px"
          >
            <button
              nz-button
              nzType="default"
              [nzSize]="'large'"
              class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
              [ngClass]="{ 'opacity-4': true }"
              [disabled]="true"
            >
              <span class="px-1"> Input amount is too low </span>
            </button>
          </div>

          <!--Estimation is expired-->
          <div
            *ngIf="
              state.activeEstimationTrade.crossChainSwapState ===
                CrossChainSwapState.Expired &&
              !state.activeEstimationTrade.loading
            "
            class="flex flex-col mt-20px"
          >
            <button
              nz-button
              nzType="default"
              (click)="refreshSearch()"
              [disabled]="state.activeEstimationTrade.loading"
              [nzSize]="'large'"
              class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            >
              <span class="px-1"> Restart Estimation </span>
            </button>
          </div>

          <!--Estimation is loading -->
          <div
            *ngIf="state.activeEstimationTrade.loading"
            class="flex flex-col mt-20px"
          >
            <button
              nz-button
              nzType="default"
              [disabled]="true"
              [nzSize]="'large'"
              class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            >
              <span class="px-1">
                <nz-skeleton-element
                  class="skeleton-h-28"
                  nzType="button"
                  [nzActive]="true"
                  nzSize="small"
                ></nz-skeleton-element>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--- transaction status -->
    <div *ngIf="state.pendingTradeList && state.pendingTradeList.length > 0">
      <ng-container
        *ngFor="let transaction of state.pendingTradeList; index as i"
      >
        <ng-container
          *ngTemplateOutlet="
            progress;
            context: { transaction: transaction, index: i }
          "
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<div *ngIf="false" class="d-flex justify-content-center mt-2">
  <ng-container>
    <div class="d-flex justify-content-center mt-2 mb-3">
      <span class="text-13B9AA text-sm font-bold pt-1">{{
        successTXCount
      }}</span>
      <span class="text-13B9AA text-sm font-bold pt-1 px-2"
        >Successful Transactions on CrowdSwap</span
      >
      <img
        src="assets/media/icons/heart.svg"
        alt="alert"
        width="24"
        height="24"
        class="h-unset"
      />
    </div>
  </ng-container>
</div>

<ng-template #progress let-transaction="transaction" let-index="index">
  <div
    class="cross-chain-progress swap-box border-radius-6px bg-fff dark:bg-121212"
    [ngClass]="{
      'z-10': index === 0,
      'minimize position-fixed show-maximize-btn': transaction.minimize
    }"
  >
    <!-- Minimized in progress transaction-->
    <button
      *ngIf="
        transaction.minimize &&
        [
          CrossChainSwapState.FailedPreCCStep,
          CrossChainSwapState.FailedCCStep,
          CrossChainSwapState.PassedPostCCStep,
          CrossChainSwapState.FailedPostCCStep,
          CrossChainSwapState.WarningPostCCStep,
          CrossChainSwapState.ClaimedCancelPostCCStep
        ].indexOf(transaction.crossChainSwapState) === -1
      "
      (click)="maximizeProgress(transaction)"
      nz-button
      nzType="default"
      class="d-flex align-items-center bg-fff dark:bg-121212 font-16 font-openSans-bold text-0066FF dark:text-3385FF border-0 border-radius-4px py-10px px-20px h-48px"
    >
      <span class="mr-13px">
        {{
          isEstimationCrossChain(transaction)
            ? "Cross-Chain swap is in progress..."
            : "Swap is in progress..."
        }}
      </span>

      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="maximizeProgress(transaction)"
      >
        <path
          d="M18 8V0H10L13.29 3.29L3.29 13.29L0 10V18H8L4.71 14.71L14.71 4.71L18 8Z"
          fill="currentColor"
        />
      </svg>
    </button>

    <!-- Minimized, success or failed transaction-->
    <div
      class="progress-done-all d-flex flex-column align-items-center text-center p-20px"
      *ngIf="
        [
          CrossChainSwapState.FailedPreCCStep,
          CrossChainSwapState.FailedCCStep,
          CrossChainSwapState.PassedPostCCStep,
          CrossChainSwapState.FailedPostCCStep,
          CrossChainSwapState.WarningPostCCStep,
          CrossChainSwapState.ClaimedCancelPostCCStep
        ].indexOf(transaction.crossChainSwapState) !== -1 &&
        transaction.minimize
      "
    >
      <div dir="rtl" class="w-full text-323232 dark:text-fff">
        <!-- ==> close progress after clicking on this article -->
        <span
          class="cursor-pointer"
          (click)="removePendingTrade(transaction, index)"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>

      <div
        class="done-all-icon-section d-flex align-items-center justify-content-center rounded-full mt-1 cursor-pointer"
        [ngClass]="
          transaction.crossChainSwapState ===
          CrossChainSwapState.PassedPostCCStep
            ? 'bg-1BD37F'
            : transaction.crossChainSwapState ===
              CrossChainSwapState.WarningPostCCStep
            ? 'bg-FBD127'
            : transaction.crossChainSwapState ===
              CrossChainSwapState.ClaimedCancelPostCCStep
            ? 'bg-0B9ED9'
            : 'bg-FF0000'
        "
        (click)="maximizeProgress(transaction)"
      >
        <ng-container [ngSwitch]="transaction.crossChainSwapState">
          <img
            *ngSwitchCase="CrossChainSwapState.PassedPostCCStep"
            src="../../../../assets/media/icons/done-all.svg"
            alt="done-all"
            width="58"
          />

          <img
            *ngSwitchCase="
              CrossChainSwapState.FailedPreCCStep ||
              CrossChainSwapState.FailedCCStep ||
              CrossChainSwapState.FailedPostCCStep
            "
            src="../../../../assets/media/icons/failed-all.svg"
            alt="done-all"
            width="58"
          />

          <img
            *ngSwitchCase="CrossChainSwapState.WarningPostCCStep"
            src="../../../../assets/media/icons/warning-all.svg"
            alt="done-all"
            width="58"
          />

          <img
            *ngSwitchCase="CrossChainSwapState.ClaimedCancelPostCCStep"
            src="../../../../assets/media/icons/canceled-all.svg"
            alt="done-all"
            width="58"
          />
        </ng-container>
      </div>

      <span class="font-20 font-openSans-bold text-000 dark:text-fff mt-3">
        {{
          transaction.crossChainSwapState ===
          CrossChainSwapState.PassedPostCCStep
            ? "Transaction Completed Successfully!"
            : transaction.crossChainSwapState ===
              CrossChainSwapState.WarningPostCCStep
            ? "Transaction Warning!"
            : transaction.crossChainSwapState ===
              CrossChainSwapState.ClaimedCancelPostCCStep
            ? "Cross-Chain Canceled!"
            : "Transaction Failed!"
        }}
      </span>

      <span
        class="font-12 text-000 dark:text-fff mt-3 text-center w-full border-dashed-0000001a dark:border-dashed-white p-2"
      >
        Swapping
        {{ transaction.amountInDisplay }}
        {{ transaction.fromToken.symbol }}
        on
        {{
          transaction.fromToken &&
            web3Service.networkSpec[transaction.fromToken.chainId].title
        }}
        to
        {{ transaction.amountOutDisplay }}
        {{ transaction.toToken.symbol }}
        on
        {{
          transaction.toToken &&
            web3Service.networkSpec[transaction.toToken.chainId].title
        }}
      </span>

      <a
        *ngIf="
          transaction.crossChainSwapState !==
          CrossChainSwapState.ClaimedCancelPostCCStep
        "
        [href]="transaction.scanTransactionUrl"
        target="_blank"
        nz-button
        nzType="default"
        class="view-details-btn d-flex align-items-center bg-0066FF font-16 font-openSans-bold text-fff border-0 border-radius-4px mt-20px h-unset"
      >
        <span class="mr-10px">
          {{
            transaction.crossChainSwapState ===
            CrossChainSwapState.ClaimedCancelPostCCStep
              ? "OK, Thank you"
              : "View Details"
          }}
        </span>

        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 7H6.5C5.39543 7 4.5 7.89543 4.5 9V18C4.5 19.1046 5.39543 20 6.5 20H15.5C16.6046 20 17.5 19.1046 17.5 18V13"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 14L20.5 4"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.5 4H20.5V9"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>

      <a
        *ngIf="
          transaction.crossChainSwapState ===
          CrossChainSwapState.ClaimedCancelPostCCStep
        "
        (click)="removePendingTrade(transaction, index)"
        nz-button
        nzType="default"
        class="view-details-btn d-flex align-items-center bg-0066ff1a font-16 font-openSans-bold text-0066FF dark:text-3385FF border-0 border-radius-4px mt-20px h-unset"
      >
        Close
      </a>
    </div>

    <!-- Maximized transaction-->
    <div
      class="d-flex flex-column pt-10px p-3 px-md-25px pb-md-25px"
      [ngClass]="{ 'd-none': transaction.minimize }"
    >
      <div class="w-full d-flex align-items-center justify-content-end">
        <span
          *ngIf="
            (state.pendingTradeList | checkPendongListCount) &&
            [
              CrossChainSwapState.FailedPreCCStep,
              CrossChainSwapState.FailedCCStep,
              CrossChainSwapState.PassedPostCCStep,
              CrossChainSwapState.FailedPostCCStep,
              CrossChainSwapState.WarningPostCCStep,
              CrossChainSwapState.ClaimedCancelPostCCStep
            ].indexOf(transaction.crossChainSwapState) === -1
          "
          class="text-000 dark:text-fff cursor-pointer me-2 minimize-progress-btn d-flex align-items-center"
          (click)="minimizeProgress(transaction)"
        >
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.5 0.25H9.5V1.75H0.5V0.25Z" fill="currentColor" />
          </svg>
        </span>

        <span
          class="text-000 dark:text-fff cursor-pointer"
          (click)="removePendingTrade(transaction, index)"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>

      <div
        class="w-full d-flex align-items-center p-10px bg-0B9ED9 border-radius-6px mt-25px"
      >
        <img
          src="assets/media/icons/info-icon.svg"
          alt="info"
          width="20"
          class="max-w-unset"
        />

        <span
          class="font-openSans-regular ml-6px text-fff font-14 font-openSans-bold"
        >
          Swapping
          {{ transaction.amountInDisplay }}
          {{ transaction.fromToken.symbol }}
          on
          {{
            transaction.fromToken &&
              web3Service.networkSpec[transaction.fromToken.chainId].title
          }}
          to
          {{
            transaction.patchState?.approvedPatchTx === true
              ? transaction.patchState.newAmountOut
              : transaction.amountOutDisplay
          }}
          {{ transaction.toToken.symbol }}
          on
          {{
            transaction.toToken &&
              web3Service.networkSpec[transaction.toToken.chainId].title
          }}
        </span>
      </div>

      <div class="cross-chain-progress-content w-full d-flex flex-column">
        <ng-container
          *ngIf="
            transaction.crossChainSwapState !==
            CrossChainSwapState.ClaimedCancelPostCCStep
          "
        >
          <label
            class="d-flex align-items-center w-full border-radius-6px font-20 font-openSans-bold mt-25px"
          >
            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.StartPreCCStep
              "
              src="assets/media/icons/loading.svg"
              alt="loading"
              class="spiner h-unset"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.FailedPreCCStep
              "
              src="assets/media/icons/cross-icon.png"
              class="rounded-full h-unset"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState >=
                CrossChainSwapState.PassedPreCCStep
              "
              class="rounded-full"
              src="assets/media/icons/tick-icon.svg"
              width="30"
              height="30"
            />

            <span class="dark:text-fff text-000 ml-10px">
              Executing swap on

              {{
                web3Service!.networkSpec[transaction!.fromToken.chainId].title
              }}
            </span>
          </label>

          <label
            *ngIf="
              isEstimationCrossChain(transaction) &&
              transaction.crossChainSwapState >= CrossChainSwapState.StartCCStep
            "
            class="d-flex align-items-center w-full border-radius-6px font-20 font-openSans-bold mt-25px"
          >
            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.StartCCStep
              "
              src="assets/media/icons/loading.svg"
              alt="loading"
              class="spiner h-unset"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.FailedCCStep
              "
              src="assets/media/icons/cross-icon.png"
              class="rounded-full h-unset"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState >=
                CrossChainSwapState.PassedCCStep
              "
              class="rounded-full"
              src="assets/media/icons/tick-icon.svg"
              width="30"
              height="30"
            />

            <span
              class="dark:text-fff text-0066FF ml-10px"
              *ngIf="
                transaction.crossChainSwapState ===
                  CrossChainSwapState.StartCCStep &&
                  transaction.patchState.newAmountOut > 0 &&
                  transaction.patchState.approvedPatchTx === false;
                else showCCLP
              "
              >CCLP (New estimate)
            </span>

            <ng-template #showCCLP>
              <span class="dark:text-fff text-000 ml-10px"> CCLP </span>
            </ng-template>
          </label>

          <div
            class="bg-gray-100 border-radius-6px ml-20px dark:bg-121212 dark:text-fff"
            *ngIf="
              transaction.fromToken.chainId !== 324 &&
              transaction.toToken.chainId !== 324 &&
              isEstimationCrossChain(transaction) &&
              transaction.crossChainSwapState ===
                CrossChainSwapState.StartCCStep
            "
          >
            <div
              *ngIf="
                transaction.patchState.newAmountOut > 0 &&
                  transaction.patchState.approvedPatchTx === false;
                else progressTran
              "
              class="d-flex align-items-center ml-6px mt-2px"
            >
              <p>
                <span class="font-14 font-openSans-regular">
                  We've got special offer! There's an option with a-few less
                  {{ transaction.toToken.symbol }} than our initial estimate.
                  Looking for a fast and efficient transaction?
                </span>

                <span class="font-openSans-bold textDecorateLine">
                  How it works?
                </span>
              </p>
            </div>

            <ng-template #progressTran>
              <div class="d-flex align-items-center ml-6px mt-2px">
                <p>
                  <span class="font-14 font-openSans-regular">
                    Your order is in progress. Minimize this window to start new
                    swap or manage transactions in history tab.
                  </span>
                </p>
              </div>
            </ng-template>

            <div
              *ngIf="
                transaction.patchState.newAmountOut > 0 &&
                transaction.patchState.approvedPatchTx === false
              "
            >
              <div class="d-flex align-items-center newAmountBox ml-6px">
                <span class="ml-6px font-12 font-openSans-regular">
                  you receive </span
                ><span class="font-14 font-openSans-bold line-h-31"
                  >{{ transaction.patchState.newAmountOut }}
                  {{ transaction.toToken.symbol }}({{
                    transaction.patchState.newAmountOutUsdt
                  }})</span
                >
              </div>
              <div
                class="d-flex align-items-center"
                *ngIf="
                  transaction.crossChainName &&
                  (transaction.crossChainName === Constants.CrosschainsMap[1] ||
                    transaction.crossChainName === Constants.CrosschainsMap[2])
                "
              >
                <div class="d-flex align-items-center"></div>
                <button
                  (click)="approvePatchNewAmount(transaction)"
                  nz-button
                  nzType="default"
                  nzSize="small"
                  class="approvebtn ml-6px inline-flex justify-center items-center w-full rounded border-none button-height shadow-sm p-1 text-white blue-button font-medium hover:bg-blue-700"
                >
                  Approve Updated Quote
                </button>
                <button
                  (click)="cancelTransaction(transaction)"
                  nz-button
                  nzType="default"
                  class="bg-transparent hover:bg-0066ff1a text-0066FF dark:text-3385FF dark:hover:text-0066FF font-14 font-md-16 font-openSans-bold border-radius-4px border-none d-flex align-items-center justify-content-center h-30px"
                >
                  Cancel Transaction
                </button>
              </div>
              <div class="d-flex align-items-center">
                <span class="ml-6px font-11 font-openSans-bold">
                  To proceed with the initial estimate, ignore this
                  message.</span
                >
              </div>
            </div>
          </div>

          <label
            class="d-flex align-items-start w-full border-radius-6px font-20 font-openSans-bold mt-25px"
            *ngIf="
              isEstimationCrossChain(transaction) &&
              transaction.crossChainSwapState >=
                CrossChainSwapState.StartPostCCStep
            "
          >
            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.StartPostCCStep
              "
              src="assets/media/icons/loading.svg"
              alt="loading"
              class="spiner h-unset mt-2"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.PassedPostCCStep
              "
              class="rounded-full mt-2"
              src="assets/media/icons/tick-icon.svg"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.FailedPostCCStep
              "
              src="assets/media/icons/failed-icon.png"
              class="rounded-full h-unset mt-2"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.WarningPostCCStep
              "
              src="assets/media/icons/warning-icon.png"
              class="rounded-full h-unset mt-2"
              width="30"
              height="30"
            />

            <img
              *ngIf="
                transaction.crossChainSwapState ===
                CrossChainSwapState.ClaimedCancelPostCCStep
              "
              src="assets/media/icons/canceled-icon.png"
              class="rounded-full h-unset mt-2"
              width="30"
              height="30"
            />

            <span class="dark:text-fff text-000 ml-10px">
              Executing swap on

              {{ web3Service!.networkSpec[transaction!.toToken.chainId].title }}
            </span>
          </label>
        </ng-container>

        <label
          class="d-flex flex-col align-items-center w-full border-radius-6px font-12 font-openSans-bold text-orangered mt-25px pl-40px"
          *ngIf="
            isEstimationCrossChain(transaction) &&
            transaction.crossChainSwapState ===
              CrossChainSwapState.WarningPostCCStep
          "
        >
          <div
            class="w-100 d-flex flex-column border-radius-4px p-2 mt-2 bg-F5F5F5 dark:bg-152b53"
          >
            <span class="font-14 text-323232 dark:text-fff">
              The exchange order is in progress.
              {{
                transaction.crossChainName &&
                (transaction.crossChainName === Constants.CrosschainsMap[1] ||
                  transaction.crossChainName === Constants.CrosschainsMap[2])
                  ? " You can go to history to follow up the transaction or you can start a new one."
                  : ""
              }}
            </span>

            <div
              class="w-full d-flex align-items-center flex-wrap mt-20px"
              *ngIf="
                transaction.crossChainName &&
                (transaction.crossChainName === Constants.CrosschainsMap[1] ||
                  transaction.crossChainName === Constants.CrosschainsMap[2])
              "
            >
              <button
                (click)="cancelTransaction(transaction)"
                nz-button
                nzType="default"
                class="bg-transparent hover:bg-0066ff1a text-0066FF dark:text-fff dark:hover:text-0066FF font-14 font-md-16 font-openSans-bold border-radius-4px border-none d-flex align-items-center justify-content-center h-30px"
              >
                Cancel Transaction
              </button>
            </div>
          </div>
        </label>

        <div
          class="d-flex align-items-center w-full border-radius-6px font-12 mt-25px"
          *ngIf="
            transaction.crossChainSwapState >=
              CrossChainSwapState.PassedPreCCStep &&
            transaction.crossChainName === Constants.CrosschainsMap[7] &&
            transaction.swapTx &&
            transaction.swapTx.hash
          "
        >
          <button
            (click)="openScanner(transaction)"
            nz-button
            nzType="default"
            class="px-20px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px shadow-none"
          >
            View in Scanner
          </button>
        </div>

        <ng-container
          *ngIf="
            isEstimationCrossChain(transaction) &&
            transaction.crossChainSwapState ===
              CrossChainSwapState.ClaimedCancelPostCCStep
          "
        >
          <label
            class="d-flex align-items-start w-full border-radius-6px font-20 font-openSans-bold mt-25px"
          >
            <span class="dark:text-fff text-000"> Cross-Chain Canceled! </span>
          </label>

          <label
            class="d-flex flex-col align-items-center w-full border-radius-6px font-12 font-openSans-bold text-323232 dark:text-FFFFFFBF mt-2"
          >
            <span
              class="d-flex align-items-center w-full border-radius-6px font-12 font-openSans-bold"
            >
              Your asset is returned to wallet.
            </span>
          </label>

          <div class="w-full d-flex align-items-center justify-content-start">
            <button
              (click)="removePendingTrade(transaction, index)"
              nz-button
              nzType="default"
              class="btn-swap-token border-radius-8px p-10px button-0066FF text-fff font-16 font-openSans-bold mt-20px h-unset"
            >
              Close
            </button>
          </div>
        </ng-container>

        <div
          class="w-full d-flex align-items-center mt-25px"
          [ngClass]="
            [
              CrossChainSwapState.FailedPreCCStep,
              CrossChainSwapState.FailedCCStep,
              CrossChainSwapState.PassedPostCCStep,
              CrossChainSwapState.FailedPostCCStep,
              CrossChainSwapState.WarningPostCCStep,
              CrossChainSwapState.ClaimedCancelPostCCStep
            ].indexOf(transaction.crossChainSwapState) === -1
              ? 'justify-content-between'
              : 'justify-content-end'
          "
        >
          <div
            class="d-flex align-items-start font-12 text-0B9ED9"
            *ngIf="
              [
                CrossChainSwapState.FailedPreCCStep,
                CrossChainSwapState.FailedCCStep,
                CrossChainSwapState.PassedPostCCStep,
                CrossChainSwapState.FailedPostCCStep,
                CrossChainSwapState.WarningPostCCStep,
                CrossChainSwapState.ClaimedCancelPostCCStep
              ].indexOf(transaction.crossChainSwapState) === -1
            "
          >
            <img
              src="assets/media/icons/timer.svg"
              alt="timer"
              width="16"
              height="16"
              class="h-unset me-2"
            />

            <span>
              Estimated time:
              {{ isEstimationCrossChain(transaction) ? 10 : 3 }} mins
            </span>
          </div>

          <span
            class="ms-3 cursor-pointer d-flex align-items-center"
            (click)="refresh()"
            nz-tooltip
            [nzTooltipTitle]="refreshTooltip"
          >
            <img
              *ngIf="!isDarkMode"
              alt="Refresh Transaction Status"
              src="assets/media/icons/refresh-black.svg"
              width="16"
            />

            <img
              *ngIf="isDarkMode"
              alt="Refresh Transaction Status"
              src="assets/media/icons/refresh-white.svg"
              width="16"
            />

            <span class="text-000 dark:text-fff ms-2"> Refresh </span>

            <ng-template #refreshTooltip>
              <div class="text-sm text-center text-white bg-black">
                <span>Refresh Transaction Status</span>
              </div>
            </ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<nz-affix
  *ngIf="buttonSection.offsetTop + 20 > contentSection.clientHeight"
  [nzOffsetBottom]="10"
  [nzTarget]="contentSection"
  (nzChange)="affixChange($event)"
  class="goto-button-section w-max-content"
>
  <button
    nz-button
    *ngIf="affixed"
    class="bg-fff dark:bg-2D2D2D border-none rounded-full d-flex align-items-center justify-content-center w-max-content p-0"
    (click)="scrollToBtn()"
  >
    <img
      *ngIf="isDarkMode"
      src="assets/media/icons/arrow-down-opacity-dark.svg"
    />

    <img
      *ngIf="!isDarkMode"
      src="../../../../assets/media/icons/arrow-down-opacity.svg"
    />
  </button>
</nz-affix>
